// General
import React from "react";
import { useI18n } from "compass-commons";
// Styles
import "../IncidentType.module.css";
// Components
import { AccordionDetails, Box, CircularProgress } from "@mui/material";
// Models
import IncidentTypeBehavior from "./IncidentTypeBehavior";
import { IncidentTypeRulesPageDto } from "../../../../../../models/incidents/IncidentTypeRule";
// Utils
import { INCIDENT_TYPES_RULES_PAGE_SIZE } from "../../../../../../utils/Constants";

interface IncidentTypeDetailsProps {
  incidentTypeRulesPage: IncidentTypeRulesPageDto;
  loadingIncidentTypeRules: boolean;
  pageIndex: number;
  setPageIndex: (index: number) => void;
}

const IncidentTypeBehaviors = (
  props: IncidentTypeDetailsProps
): JSX.Element => {
  const {
    loadingIncidentTypeRules,
    incidentTypeRulesPage,
    pageIndex,
    setPageIndex,
  } = props;
  const { t: translate } = useI18n();
  const showMore =
    pageIndex + 1 <
    incidentTypeRulesPage?.totalResults / INCIDENT_TYPES_RULES_PAGE_SIZE;
  return (
    <AccordionDetails className="config-incident-type__behaviors">
      {incidentTypeRulesPage?.incidentTypeRules?.length > 0 &&
        !(pageIndex === 0 && loadingIncidentTypeRules) &&
        incidentTypeRulesPage.incidentTypeRules
          .slice(0, (pageIndex + 1) * INCIDENT_TYPES_RULES_PAGE_SIZE)
          .map((incidentTypeRule) => (
            <IncidentTypeBehavior
              key={incidentTypeRule.ruleId}
              incidentTypeRule={incidentTypeRule}
            />
          ))}

      {!loadingIncidentTypeRules && showMore && (
        <Box
          className="config-incident-type__behavior__showMore"
          onClick={() => {
            setPageIndex(pageIndex + 1);
          }}
        >
          {translate("showMore", { ns: "Shared" })}
        </Box>
      )}

      {!loadingIncidentTypeRules && !incidentTypeRulesPage?.totalResults && (
        <span
          className="config-incident-type__behavior"
          data-cr="config-incident-type__no__behavior"
        >
          {translate("incidentTypes.noBehaviors")}
        </span>
      )}

      {loadingIncidentTypeRules && (
        <div className="config-loading">
          <CircularProgress color="inherit" />
        </div>
      )}
    </AccordionDetails>
  );
};

export default IncidentTypeBehaviors;
