// General
import React from "react";
import { Control, Controller } from "react-hook-form";
import * as z from "zod";
import { useI18n } from "compass-commons";
// Styles
import "./connectionPorpertyBoolean.module.css";
// Components
import Checkbox from "@mui/material/Checkbox";
// Model
import { ConnectionPropertyTemplateDto } from "../../../../../../../../models/subsystems/ConnectionPropertyTemplateDto";
import { FormErrors } from "../../../../models/SubsystemConnectionDataTypes";
import { ConnectionPropertyType } from "../../../../../../../../models/subsystems/ConnectionPropertyType";
import { ConnectionPropertiesData } from "../../../../../../../../models/subsystems/SubsystemDto";
// Utils
import { compassOptional } from "../../../../../../../../utils/Validations";

interface ConnectionPropertyBooleanProps {
  dataCr: string;
  connectionPropertyBoolean: ConnectionPropertyTemplateDto;
  readOnlyMode: boolean;
  control: Control<ConnectionPropertiesData, any>;
  errors?: FormErrors<ConnectionPropertiesData>;
}

const ConnectionPropertyBoolean = ({
  dataCr,
  connectionPropertyBoolean,
  readOnlyMode,
  control,
  errors,
}: ConnectionPropertyBooleanProps): JSX.Element => {
  const { t } = useI18n();

  const baseSchema = z.boolean();

  const requiredSchema = baseSchema.refine((val) => val, {
    message: t("subsystems.propertiesFieldsValidation.boolean"),
  });

  const composedSchema = z.object({
    connectionPropertyBoolean: compassOptional(
      baseSchema,
      requiredSchema,
      !connectionPropertyBoolean.required
    ),
  });

  const buildComplexValue = (value: boolean) => ({
    id: connectionPropertyBoolean.id,
    propertyType: ConnectionPropertyType.BOOLEAN,
    connectionPropertyBoolean: value,
  });

  return (
    <div className="config-connection-property-boolean__wrapper">
      <div className="config-connection-property-boolean__controller">
        <Controller
          name={connectionPropertyBoolean.label}
          rules={{
            validate: (value) =>
              composedSchema
                .parseAsync(value)
                .then((result) => !!result)
                .catch((e: z.ZodError) => e.issues[0].message),
          }}
          control={control}
          defaultValue={buildComplexValue(
            connectionPropertyBoolean.connectionPropertyData.data as boolean
          )}
          render={({ field: { onChange, value, ...field } }) => {
            return (
              <Checkbox
                {...field}
                id={`property-id-${connectionPropertyBoolean.id}`}
                data-cr="checkbox-label"
                key={`property-id-${connectionPropertyBoolean.id}`}
                disabled={readOnlyMode}
                checked={value.connectionPropertyBoolean}
                onChange={(_, checked) => {
                  onChange(buildComplexValue(checked));
                }}
              />
            );
          }}
        />
        <span
          data-cr={`${dataCr}-label`}
          className="config-subsystem-connection-data__field-wrapper--bold"
        >
          {connectionPropertyBoolean.label}
          <span className="config-subsystem-connection-data__asterisk">
            {connectionPropertyBoolean.required && "*"}
          </span>
        </span>
      </div>
      {errors?.[connectionPropertyBoolean.label] && (
        <span className="error-message">
          {t("subsystems.propertiesFieldsValidation.required", {
            label:
              errors?.[connectionPropertyBoolean.label]?.message.toString(),
          })}
        </span>
      )}
    </div>
  );
};

ConnectionPropertyBoolean.defaultProps = {
  errors: undefined,
};

export default ConnectionPropertyBoolean;
