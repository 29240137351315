import React, { useCallback } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { OgTaskTypeDto } from "../../../../../models/ogTaskTypes/OgTaskTypeDto";
import "./genericTaskNode.module.css";
import { OgBlockTypeDto } from "../../../../../models/ogTaskTypes/OgBlockTypeDto";
import {
  mapBlockType,
  getIconForTask,
} from "../../../../../helpers/ogConfiguratorHelper";
import StateService from "../../../../../services/StateService";
import { useStateContext } from "../../../../../contexts/StateContext";

export interface GenericTaskNodeProps {
  data?: OgTaskTypeDto;
  onNodeRemove?: (dataId: string) => void;
  onMultiOptionRemove?: (blockId: string, optionId: string) => void;
}

const GenericTaskNode = (props: GenericTaskNodeProps): JSX.Element => {
  const { data, onNodeRemove, onMultiOptionRemove } = props;

  const stateService: StateService = useStateContext();
  const { currentOgTemplate, alertSubject } = stateService;

  const errorCallback = useCallback((msg: string) => {
    if (msg) {
      alertSubject.next({ title: msg });
    }
  }, []);

  const getBlockTypes = () => {
    const icon = getIconForTask(data);
    const blocks = data?.typeBlocks?.map((blockType: OgBlockTypeDto) =>
      mapBlockType(
        blockType,
        onMultiOptionRemove,
        true,
        icon,
        currentOgTemplate,
        errorCallback
      )
    );
    return blocks;
  };

  const onDelete = (event) => {
    event.stopPropagation();
    onNodeRemove?.(data ? data.id : "");
  };

  return (
    <div className="generic-task-node-main">
      <button
        className="generic-node-delete"
        type="button"
        onClick={(event) => {
          onDelete(event);
        }}
      >
        <CloseRoundedIcon fontSize="small" />
      </button>
      {getBlockTypes()}
    </div>
  );
};

GenericTaskNode.defaultProps = {
  data: null,
  onNodeRemove: undefined,
  onMultiOptionRemove: undefined,
};

export default GenericTaskNode;
