// General
import { createSlice } from "@reduxjs/toolkit";
// Reducers
import operatorGuideExtraReducers from "./extraReducers";
// Actions
import operatorGuideExtraActions from "./extraActions";

export type OperatorGuideState = {
  templateStub: string[];
  editingTaskText: boolean;
};

const SLICE_NAME = "operatorGuide";

const initialState: OperatorGuideState = {
  templateStub: new Array<string>(),
  editingTaskText: false,
};

/**
 * Slice to manipulate all operations related to sites
 */
export const operatorGuideSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: operatorGuideExtraReducers,
});

// Action creators are generated for each case reducer function
export const operatorGuideActions = {
  ...operatorGuideSlice.actions,
  ...operatorGuideExtraActions,
};

export default operatorGuideSlice.reducer;
