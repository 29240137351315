// General
import React from "react";
import { useI18n } from "compass-commons";
// Styles
import "./subsystemDialogActions.module.css";
// Components
import { Button } from "dms-lib";

/* eslint-disable @typescript-eslint/no-explicit-any */

interface SubsystemDialogActionsProps {
  onCancel: (event?: any) => void;
  onSuccess: (event?: any) => void;
}

const SubsystemDialogActions = ({
  onCancel,
  onSuccess,
}: SubsystemDialogActionsProps): JSX.Element => {
  const { t } = useI18n();
  return (
    <div className="subsystem-dialog-actions__wrapper">
      <Button
        dataCr="cancel-subsystem-delete"
        color="primary"
        variant="contained"
        onClick={onCancel}
      >
        {t("cancel", { ns: "Shared" })}
      </Button>
      <Button
        dataCr="confirm-subsystem-delete"
        color="secondary"
        variant="outlined"
        onClick={onSuccess}
      >
        {t("confirmDelete", { ns: "Shared" })}
      </Button>
    </div>
  );
};

export default SubsystemDialogActions;
