// General
import React from "react";
import { useI18n } from "compass-commons";
// Styles
import "../../subsystemConnectionDataPanel.module.css";
// Model
import { ConnectionPropertyTemplateDto } from "../../../../../../../../models/subsystems/ConnectionPropertyTemplateDto";
// Components
import ConnectionPropertyHeader from "../connectionPropertyHeader";

interface ConnectionPropertyUnknownProps {
  dataCr: string;
  connectionPropertyUnknown: ConnectionPropertyTemplateDto;
}

const ConnectionPropertyUnknown = ({
  dataCr,
  connectionPropertyUnknown,
}: ConnectionPropertyUnknownProps): JSX.Element => {
  const { t } = useI18n();
  return (
    <div className="config-subsystem-connection-data__field-wrapper--width">
      <ConnectionPropertyHeader
        dataCr={dataCr}
        label={connectionPropertyUnknown.label}
        required={connectionPropertyUnknown.required}
      />
      <div>
        <span>{t("subsystems.connectionData.unknown")}</span>
      </div>
    </div>
  );
};
export default ConnectionPropertyUnknown;
