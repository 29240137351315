// General
import React, { useEffect } from "react";
import { useI18n } from "compass-commons";
import { useSelector } from "react-redux";
// Components
import { CircularProgress } from "@mui/material";
import { NoElementsFound } from "dms-lib";
import Integration from "./Integration";
// Store
import { edgeDevicesActions } from "../../../../store/edgeDevices";
import { selectEdgeDevices, useStoreDispatch } from "../../../../store";
import useFetchEdgeDeviceIntegrations from "../../../../hooks/useFetchEdgeDeviceIntegrations";

interface SelectedEdgeDeviceDetailsBodyProps {
  edgeDeviceId: string;
}

const SelectedEdgeDeviceIntegrationsBody = ({
  edgeDeviceId,
}: SelectedEdgeDeviceDetailsBodyProps): JSX.Element => {
  const { t: translate } = useI18n();
  const dispatch = useStoreDispatch();
  const { loadingEdgeDeviceIntegrations, edgeDeviceIntegrationsList } =
    useSelector(selectEdgeDevices);
  const { integrations } = useFetchEdgeDeviceIntegrations(
    edgeDeviceId,
    edgeDeviceIntegrationsList
  );

  useEffect(() => {
    dispatch(edgeDevicesActions.getEdgeDeviceIntegrations(edgeDeviceId));
  }, [edgeDeviceId]);

  useEffect(() => {
    if (integrations?.length > 0) {
      dispatch(
        edgeDevicesActions.updateEdgeDeviceIntegrationsList(integrations)
      );
    }
  }, [integrations]);

  return (
    <div
      className={`selected-edge-device-integrations-panel-body${
        loadingEdgeDeviceIntegrations ? " loading" : ""
      }`}
    >
      {loadingEdgeDeviceIntegrations ? (
        <CircularProgress className="selected-edge-device-integrations-panel-circular-progress" />
      ) : (
        <>
          {edgeDeviceIntegrationsList.length > 0 ? (
            <div className="selected-edge-device-integrations-panel-body-list">
              {edgeDeviceIntegrationsList.map((integration) => (
                <Integration key={integration.id} integration={integration} />
              ))}
            </div>
          ) : (
            <NoElementsFound
              message={translate(
                "edgeDevices.selectedEdgeDevice.integrationsNotFound"
              )}
              className="selected-edge-device-integrations-panel-body-no-elements-found"
            />
          )}
        </>
      )}
    </div>
  );
};

export default SelectedEdgeDeviceIntegrationsBody;
