enum MenuTabRoute {
  Generic = "",
  FloorPlans = "floor-plans",
  OpGuides = "op-guides",
  Subsystems = "subsystems",
  Resources = "resources",
  Incidents = "incidents",
  EdgeDevices = "edge-devices",
}

// eslint-disable-next-line import/prefer-default-export
export { MenuTabRoute };
