// OG TEMPLATES

enum BlockTypes {
  TextField = "SINGLE_TEXT_FIELD",
  Confirmation = "CONFIRMATION",
  MultiOption = "MULTI_OPTION",
  Button = "BUTTON",
  Dropdown = "DROPDOWN",
  ContextTag = "CONTEXT_TAG",
}

// eslint-disable-next-line import/prefer-default-export
export { BlockTypes };

// SUBSYSTEMS
export const NEW_SUBSYSTEM_ID_PLACEHOLDER = "newSubsystemId";
export const NEW_SUBSYSTEM_NAME_PLACEHOLDER =
  "subsystems.placeholders.newSubsystem";
export const NEW_BRAND_ID_PLACEHOLDER = "brand_id";
export const NEW_MODEL_ID_PLACEHOLDER = "model_id";

const { FEATURE_FLAG_MANAGER_PATH, SERVER_URL } = appConfig;
export const URL_PATHS = {
  GET_FEATURE_STATE: `${SERVER_URL}${FEATURE_FLAG_MANAGER_PATH}/features/`,
};

export const SELF_INTEGRATIONS_PORTAL_FEATURE_FLAG =
  "d_self-integrations-portal";

export const INCIDENT_TYPES_CONFIGURATION_FEATURE_FLAG =
  "d_incidentTypesConfiguration";

export const SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG =
  "b_showGenericErrorMessages";

export const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
export const INCIDENT_TYPES_RULES_PAGE_SIZE = 20;

export const ON_DEMAND_INCIDENT_FEATURE_FLAG = "d_ondemand";

export const GROUPING_BY_ACTIVATION_FEATURE_FLAG =
  "d_incidentqueue_groupingByActivation";
