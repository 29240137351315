// General
import React from "react";
import { useI18n } from "compass-commons";
// Material UI
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";

const SiteDialogContent = (): JSX.Element => {
  const { t } = useI18n();
  return (
    <>
      <div className="delete-warning-modal">
        <ReportProblemRoundedIcon color="error" />
        <span>{t("site.deleteModal.description")}</span>
      </div>
    </>
  );
};

export default SiteDialogContent;
