import React from "react";
import { OgBlockTypeDto } from "../../../../../models/ogTaskTypes/OgBlockTypeDto";

interface TextFieldBlockTypeProps {
  block: OgBlockTypeDto;
}

const TextFieldBlockType = (props: TextFieldBlockTypeProps): JSX.Element => {
  const { block } = props;
  return (
    <div
      className="compass-rounded-border task-input-text-default"
      id="text-field-block-type"
      data-cr="text-field-block-type"
    >
      {block?.payload || "Text"}
    </div>
  );
};

export default TextFieldBlockType;
