// General
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { LocalizationNS, useI18n } from "compass-commons";
import { useNavigate } from "react-router-dom";
// Components
import { ListTable } from "dms-lib";
import SelectedEdgeDevicePanel from "../selectedEdgeDevicePanel/SelectedEdgeDevicePanel";
// Styles
import "./edgeDeviceList.module.css";
// Models
import {
  EdgeDevice,
  EdgeDeviceDTO,
} from "../../../models/edgeDevices/EdgeDeviceDTO";
// Store
import { selectEdgeDevices, useStoreDispatch } from "../../../store";
import { edgeDevicesActions } from "../../../store/edgeDevices";
import { SelectedEdgeDeviceMenuTabRoute } from "../../../router/SelectedEdgeDevicePanelRoutes";

interface EdgeDeviceListProps {
  edgeDevices: EdgeDevice[];
}

const EdgeDeviceList = (props: EdgeDeviceListProps): JSX.Element => {
  const { t: translate } = useI18n();
  const { edgeDevices } = props;
  const dispatch = useStoreDispatch();
  const { edgeDevicesList, selectedEdgeDevice, selectedEdgeDeviceSubTab } =
    useSelector(selectEdgeDevices);
  const [selectedEdgeDeviceDTO, setSelectedEdgeDeviceDTO] =
    useState<EdgeDeviceDTO>(null);
  const dmsNavigate = useNavigate();

  const onEdgeDeviceClick = (edgeDevice: EdgeDevice) => {
    dispatch(
      edgeDevicesActions.setSelectedEdgeDeviceSubTab(
        SelectedEdgeDeviceMenuTabRoute.Details
      )
    );
    dispatch(edgeDevicesActions.selectEdgeDevice(edgeDevice.id));
  };

  const unselectEdgeDevice = () => {
    setSelectedEdgeDeviceDTO(null);
    dispatch(edgeDevicesActions.setEdgeDeviceToken(null));
    dispatch(edgeDevicesActions.setSelectedEdgeDeviceSubTab(null));
  };

  useEffect(() => {
    dmsNavigate("");
    if (edgeDevicesList.length === 0) {
      return;
    }
    if (selectedEdgeDeviceDTO && !selectedEdgeDevice) {
      unselectEdgeDevice();
      return;
    }
    const foundEdgeDevice = edgeDevicesList.find(
      (edgeDevice) => edgeDevice.id === selectedEdgeDevice
    );
    if (!foundEdgeDevice) {
      unselectEdgeDevice();
    } else {
      setSelectedEdgeDeviceDTO(foundEdgeDevice);
      dmsNavigate(
        `${selectedEdgeDevice}/${
          selectedEdgeDeviceSubTab ?? SelectedEdgeDeviceMenuTabRoute.Default
        }`
      );
    }
  }, [selectedEdgeDevice, edgeDevicesList]);

  const generateColumnsData = useMemo(
    () => ({
      id: {
        fieldName: "ID",
        size: 0,
        headerName: "ID",
        accessor: (element: EdgeDevice) => element.id,
        hiddenColumn: true,
      },
      status: {
        fieldName: "status",
        size: 8,
        headerName: translate("edgeDevices.status"),
        accessor: (element: EdgeDevice) => element.status,
      },
      name: {
        fieldName: "name",
        size: 20,
        headerName: translate("name", { ns: LocalizationNS.SHARED }),
        accessor: (element: EdgeDevice) => element.name,
      },
      createDate: {
        fieldName: "createDate",
        size: 16,
        headerName: translate("createDate", { ns: LocalizationNS.SHARED }),
        accessor: (element: EdgeDevice) => element.createdDate,
      },
      createBy: {
        fieldName: "createdBy",
        size: 20,
        headerName: translate("createdBy", { ns: LocalizationNS.SHARED }),
        accessor: (element: EdgeDevice) => element.createdBy,
      },
      updateDate: {
        fieldName: "updateDate",
        size: 16,
        headerName: translate("updateDate", { ns: LocalizationNS.SHARED }),
        accessor: (element: EdgeDevice) => element.lastModifiedDate,
      },
      updatedBy: {
        fieldName: "updatedBy",
        size: 20,
        headerName: translate("updatedBy", { ns: LocalizationNS.SHARED }),
        accessor: (element: EdgeDevice) => element.lastModifiedBy,
      },
    }),
    []
  );

  return (
    <div className="edge-devices-list">
      <ListTable<EdgeDevice>
        dataCr="edge-devices-list"
        className={selectedEdgeDeviceDTO ? "grid-collapse" : ""}
        columns={generateColumnsData}
        rowsData={edgeDevices}
        currentSelectedElement={edgeDevices.find(
          (edgeDevice) => edgeDevice.id === selectedEdgeDevice
        )}
        onRowClick={onEdgeDeviceClick}
        tableCollapse
        isRowInteractable
        noDataLabel={translate("edgeDevices.notFound")}
      >
        {selectedEdgeDeviceDTO && (
          <SelectedEdgeDevicePanel edgeDevice={selectedEdgeDeviceDTO} />
        )}
      </ListTable>
    </div>
  );
};

export default EdgeDeviceList;
