// General
import React, { useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { useI18n } from "compass-commons";
// Styles
import "../AddEditIncidentType.module.css";
// Components
import { FormLabel } from "@mui/material";
import { IconSelector } from "dms-lib";
import CircularProgress from "@mui/material/CircularProgress";
// Models
import { FormIncidentTypeDto } from "../../../../../models/incidents/form/FormIncidentTypeDto";

interface AddEditIncidentTypeIconProps {
  control: Control<FormIncidentTypeDto>;
}

const AddEditIncidentTypeIcon = ({
  control,
}: AddEditIncidentTypeIconProps): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { t: translate } = useI18n();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div
      className="config-add-edit-incident-type__panel__icon"
      data-cr="add-edit-incident-type-panel-icon"
    >
      <FormLabel>
        {translate("incidentTypes.upsert.selectIncidentTypeIcon")}
      </FormLabel>
      <Controller
        name="iconPath"
        control={control}
        render={({ field: { onChange, value } }) =>
          isLoaded ? (
            <IconSelector
              selection={value}
              onSelect={onChange}
              placeholder={translate(
                "incidentTypes.upsert.selectIncidentTypeIconPlaceholder"
              )}
              emptySearchMessage={translate(
                "incidentTypes.upsert.selectIncidentTypeIconSearchEmpty"
              )}
              translate={translate}
              size="large"
            />
          ) : (
            <div className="config-loading">
              <CircularProgress color="inherit" />
            </div>
          )
        }
      />
    </div>
  );
};

export default AddEditIncidentTypeIcon;
