import React, { useCallback, useEffect, useState } from "react";
import { CommonIdentifier, useI18n } from "compass-commons";
import { Button } from "dms-lib";
import CollapsiblePanel from "../commons/collapsiblePanel";

interface FloorPlanListPanelProps {
  floors?: CommonIdentifier[];
  selectFloorPlanCallback?: (floorPlanId: number | string) => void;
  selectedFloorPlanId?: number | string;
}

const FloorPlanListPanel = (props: FloorPlanListPanelProps): JSX.Element => {
  const { t } = useI18n();
  const { floors, selectFloorPlanCallback, selectedFloorPlanId } = props;
  const [floorPlanList, setFloorPlanList] = useState(floors);

  const searchCallback = useCallback(
    (filter: string) => {
      if (filter.length > 0) {
        setFloorPlanList(
          floors?.filter((s) => {
            return s?.name?.toLowerCase().includes(filter.toLowerCase());
          })
        );
      } else {
        setFloorPlanList(floors);
      }
    },
    [floors]
  );

  useEffect(() => {
    searchCallback("");
  }, [floors]);

  return (
    <CollapsiblePanel
      dataCr="floor-plan-list"
      searchBar={{
        dataCr: "floor-plan-search-bar",
        searchCallback,
        placeholderLabel: t("floorPlans.searchFloor"),
      }}
    >
      <div className="config-list-in" data-cr="floor-plan-list-items">
        {floorPlanList?.length > 0 ? (
          floorPlanList?.map((floorPlan) => (
            <Button
              color="primary"
              variant={
                floorPlan.id === selectedFloorPlanId ? "contained" : "text"
              }
              data-cr="floor-plan-list-item"
              className="floor-plan-list-el"
              key={floorPlan.id}
              onClick={() => selectFloorPlanCallback(floorPlan?.id)}
              title={floorPlan.name}
            >
              {floorPlan.name}
            </Button>
          ))
        ) : (
          <div className="config-list-empty">
            {t("floorPlans.noFloorPlans")}
          </div>
        )}
      </div>
    </CollapsiblePanel>
  );
};

FloorPlanListPanel.defaultProps = {
  floors: null,
  selectFloorPlanCallback: () => null,
  selectedFloorPlanId: null,
};

export default FloorPlanListPanel;
