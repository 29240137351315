// General
import React from "react";
import { useI18n } from "compass-commons";
// Components
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// Styles
import "./edgeDeviceStatus.module.css";

const EdgeDeviceStatusDisconnectedIcon = (): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <CloseRoundedIcon
      className="edge-device-status-disconnected"
      titleAccess={translate(`edgeDevices.statusList.disconnected`)}
    />
  );
};

export default EdgeDeviceStatusDisconnectedIcon;
