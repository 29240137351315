import { createAsyncThunk } from "@reduxjs/toolkit";
// Actions
import { sitesActions } from "..";
import { rootActions } from "../../root";
// Services
import SitesService from "../../../services/SitesService";
// Models
import { CreateSiteDto } from "../../../models/sites/CreateSiteDto";
import { UpdateSiteDto } from "../../../models/sites/UpdateSiteDto";

function createExtraActions() {
  const getAllSites = () => {
    return createAsyncThunk(
      `sites/getAllSites`,
      // eslint-disable-next-line
      async () => await SitesService.getAllSites()
    );
  };

  const createSite = () => {
    return createAsyncThunk(
      "sites/createSite",
      async (params: CreateSiteDto, { dispatch }) => {
        const result = await SitesService.createSite(params);
        dispatch(rootActions.activateReadOnlyMode(true));
        dispatch(sitesActions.getAll());
        return result;
      }
    );
  };

  const updateSite = () => {
    return createAsyncThunk(
      "sites/updateSite",
      async (
        { siteId, body }: { siteId: string; body: UpdateSiteDto },
        { dispatch }
      ) => {
        return SitesService.updateSite(siteId, body).then(() => {
          dispatch(rootActions.activateReadOnlyMode(true));
          dispatch(sitesActions.getAll());
        });
      }
    );
  };

  const deleteSite = () => {
    return createAsyncThunk(
      "sites/deleteSite",
      async (siteId: string, { dispatch }) => {
        return SitesService.deleteSite(siteId).then(() => {
          dispatch(rootActions.activateReadOnlyMode(true));
          dispatch(sitesActions.getAll());
        });
      }
    );
  };

  return {
    getAll: getAllSites(),
    createSite: createSite(),
    updateSite: updateSite(),
    deleteSite: deleteSite(),
  };
}

const sitesExtraActions = createExtraActions();

export default sitesExtraActions;
