// General
import React from "react";
import { useI18n } from "compass-commons";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
// Components
import { AutoComplete, Button, OptionType, ResourceIcon } from "dms-lib";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Typography from "@mui/material/Typography";
import IncidentType from "../../incidentTypesList/incidentType/IncidentType";
// Model
import { IncidentTypeDto } from "../../../../../models/incidents/IncidentTypeDto";
import IncidentTypeDrawerState from "../../../../../models/incidents/IncidentTypeDrawerState";
import { FormForwardRulesDto } from "../../../../../models/incidents/form/FormForwardRulesDto";

export interface DeleteIncidentTypeForwardProps {
  onForward: (
    incidentType: IncidentTypeDto,
    incidentTypeToForward: IncidentTypeDto
  ) => void;
  incidentTypes: IncidentTypeDto[];
  selectedIncidentType: IncidentTypeDto;
  configDrawerState: (s: IncidentTypeDrawerState, i?: IncidentTypeDto) => void;
}

interface IncidentTypeOption extends OptionType {
  name: string;
  iconPath: string;
}

const DeleteIncidentTypeForward = (
  props: DeleteIncidentTypeForwardProps
): JSX.Element => {
  const { onForward, incidentTypes, selectedIncidentType, configDrawerState } =
    props;
  const [selectedIncidentTypeToForward, setSelectedIncidentTypeToForward] =
    React.useState<IncidentTypeDto>(null);
  const { t: translate, Trans } = useI18n();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormForwardRulesDto>({
    mode: "all",
    resolver: zodResolver(FormForwardRulesDto),
  });

  const onSubmit = handleSubmit(() => {
    onForward(selectedIncidentType, selectedIncidentTypeToForward);
  });

  return (
    <>
      <div className="config-delete-incident-type__panel__header">
        <Button
          className="config-delete-incident-type__icons"
          color="error"
          variant="contained"
          size="large"
          icon
        >
          <WarningRoundedIcon className="config-delete-incident-type__icons" />
        </Button>
        <Typography className="config-delete-incident-type__panel__header__title">
          <Trans
            i18nKey="incidentTypes.delete.alertMessage"
            components={{ bold: <strong /> }}
          />
          <br />
          {translate("incidentTypes.delete.alertLinkBehaviors")}
        </Typography>
      </div>
      <div className="config-delete-incident-type__panel__body">
        <IncidentType
          incidentType={selectedIncidentType}
          readOnly
          initialExpand
        />
        <div
          className="config-delete-incident-type__panel__body-bottom"
          data-cr="delete-incident-type-panel-body-bottom"
        >
          <Controller
            name="incidentTypeToForward"
            control={control}
            defaultValue={null}
            render={({ field: { onChange, ...field } }) => {
              return (
                <AutoComplete<IncidentTypeOption>
                  {...field}
                  value={field.value as IncidentTypeOption}
                  options={incidentTypes}
                  getOptionLabel={(option) => option?.name}
                  id={`delete-incident-type-${selectedIncidentType?.id}`}
                  placeholder={translate("incidentTypes.incidentType")}
                  onChangeCallback={(data: IncidentTypeDto, _e) => {
                    onChange(data);
                    setSelectedIncidentTypeToForward(data);
                  }}
                  error={!!errors.incidentTypeToForward}
                  errorMessage={
                    errors.incidentTypeToForward
                      ? translate("propertiesFieldsValidation.required", {
                          label: translate("incidentTypes.incidentType"),
                          ns: "Shared",
                        })
                      : " "
                  }
                  IconComponent={({ value, className, dataCr: dtCr }) =>
                    value?.iconPath ? (
                      <ResourceIcon
                        data-cr={dtCr}
                        className={className}
                        src={value?.iconPath}
                      />
                    ) : undefined
                  }
                />
              );
            }}
          />
          <div
            className="config-delete-incident-type__panel__body-bottom__actions"
            data-cr="delete-incident-type-panel-body-bottom-actions"
          >
            <Button
              size="medium"
              color="primary"
              variant="contained"
              onClick={() => configDrawerState(IncidentTypeDrawerState.List)}
            >
              <span>{translate("cancel", { ns: "Shared" })}</span>
            </Button>
            <Button
              size="medium"
              color="primary"
              variant="text"
              type="submit"
              disabled={!isValid}
              onClick={onSubmit}
            >
              <span>{translate("confirm", { ns: "Shared" })}</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteIncidentTypeForward;
