import { object, z } from "zod";
import RuleStringConstraint from "../RuleStringConstraint";
import RuleStringOptional from "../RuleStringOptional";

export const FormBehaviourIncidentTypeDto = object(
  {
    id: RuleStringConstraint,
    name: RuleStringConstraint,
    iconPath: RuleStringOptional,
  },
  {
    required_error: "behaviors.propertiesFieldsValidation.required",
    invalid_type_error: "behaviors.propertiesFieldsValidation.required",
  }
);

export type FormBehaviourIncidentTypeDto = z.infer<
  typeof FormBehaviourIncidentTypeDto
>;
