// General
import React from "react";
// Components
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { Button, Dialog } from "dms-lib";
import { useI18n } from "compass-commons";
// Styles
import "./integrationWasAddedModal.module.css";

interface DeleteEdgeDeviceModalProps {
  open: boolean;
  onConfirmCallback: () => void;
}

const IntegrationWasAddedModal = ({
  open,
  onConfirmCallback,
}: DeleteEdgeDeviceModalProps): JSX.Element => {
  const { t: translate } = useI18n();

  return (
    <Dialog
      open={open}
      size="xs"
      dialogContent={
        <div className="integration-was-added-modal-content-header">
          <EmailRoundedIcon className="integration-was-added-modal-content-header-icon" />
          <span>
            {translate(
              "edgeDevices.selectedEdgeDevice.addEdgeDeviceIntegrationModal.confirmed"
            )}
          </span>
        </div>
      }
      dialogActions={
        <Button color="primary" variant="contained" onClick={onConfirmCallback}>
          {translate("ok", { ns: "Shared" })}
        </Button>
      }
    />
  );
};

export default IntegrationWasAddedModal;
