import {
  AggregateMethodEnum,
  IncidentPriorityEnum,
} from "../../../models/incidents/form/FormBehaviourDto";
import { FormRuleDto } from "../../../models/incidents/form/FormRuleDto";
import { getDefaultDays } from "../../../utils/Util";
import { RuleDto } from "../../../models/incidents/RuleDto";

const getDaysOfWeek = (rule, locale) => {
  return rule?.trigger?.daysOfWeek
    ? [...rule?.trigger?.daysOfWeek]
    : [...getDefaultDays(locale)];
};

const getFormDefaultValue = (
  selectedRule: RuleDto,
  selectedSiteId: string,
  translate: (text) => string,
  locale = "en"
): FormRuleDto => {
  return {
    siteId: selectedRule?.siteId || selectedSiteId,
    ruleId: selectedRule?.ruleId,
    displayName: selectedRule?.displayName || null,
    behaviour: {
      incidentPriority:
        selectedRule?.behaviour?.incidentPriority ||
        ("WARNING" as IncidentPriorityEnum),
      description:
        selectedRule?.behaviour?.description ||
        translate("behaviors.placeholders.newBehavior"),
      incidentTypeIconPath: selectedRule?.behaviour?.incidentTypeIconPath || "",
      incidentTypeId: selectedRule?.behaviour?.incidentTypeId || "",
      incidentTypeName: selectedRule?.behaviour?.incidentTypeName || "",
      ogTemplateId: selectedRule?.behaviour?.ogTemplateId || null,
      ogTemplateName: selectedRule?.behaviour?.ogTemplateName || "",
      aggregationMethod:
        selectedRule?.behaviour?.aggregationMethod ||
        AggregateMethodEnum.enum.SITE_AND_RULE,

      // Set null objects if it doesn't have information, to allow for default selection
      ...(selectedRule?.behaviour?.ogTemplateId
        ? {
            ogTemplate: {
              id: selectedRule?.behaviour?.ogTemplateId || "",
              name: selectedRule?.behaviour?.ogTemplateName || "",
            },
          }
        : { ogTemplate: null }),
      ...(selectedRule?.behaviour?.incidentTypeId
        ? {
            incidentType: {
              id: selectedRule?.behaviour?.incidentTypeId || "",
              name: selectedRule?.behaviour?.incidentTypeName || "",
              iconPath: selectedRule?.behaviour?.incidentTypeIconPath || "",
            },
          }
        : { incidentType: null }),
    },
    trigger: {
      disabled: selectedRule?.trigger?.disabled || false,
      commentForTriggerBeingDisabled:
        selectedRule?.trigger?.commentForTriggerBeingDisabled || null,
      eventTypeId: selectedRule?.trigger?.eventTypeId || null,
      eventTypeSubsystemName:
        selectedRule?.trigger?.eventTypeSubsystemName || null,
      eventTypeSubsystemId: selectedRule?.trigger?.eventTypeSubsystemId || null,
      ...(selectedRule?.trigger?.eventTypeSubsystemName
        ? {
            eventTypeSubsystem: {
              id: selectedRule?.trigger?.eventTypeSubsystemId || null,
              name: selectedRule?.trigger?.eventTypeSubsystemName || null,
            },
          }
        : { eventTypeSubsystem: null }),
      triggerResourceMappingDTOs:
        selectedRule?.trigger?.triggerResourceMappingDTOs || [],
      daysOfWeek: getDaysOfWeek(selectedRule, locale),
      availableForOnDemand:
        selectedRule?.trigger?.availableForOnDemand || false,
    },
  };
};

// eslint-disable-next-line import/prefer-default-export
export { getFormDefaultValue };
