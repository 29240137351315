import React, { useState } from "react";
import { Handle, Position } from "reactflow";
import { BehaviorSubject } from "rxjs";
import { useSelector } from "react-redux";
import { OgBlockTypeDto } from "../../../../../../models/ogTaskTypes/OgBlockTypeDto";
import CompassTextField from "../../../../../commons/compassTextField/CompassTextField";
import { OgTemplateDto } from "../../../../../../models/ogTemplate/OgTemplateDto";
import { selectOperatorGuide, useStoreDispatch } from "../../../../../../store";
import { operatorGuideActions } from "../../../../../../store/operatorGuide";

interface TextFieldNodeBlockProps {
  block?: OgBlockTypeDto;
  icon?: any;
  ogTemplateSubject?: BehaviorSubject<OgTemplateDto>;
}

const TextFieldNodeBlock = (props: TextFieldNodeBlockProps): JSX.Element => {
  const { block, icon, ogTemplateSubject } = props;
  const [editMode, setEditMode] = useState(false);
  const { templateStub } = useSelector(selectOperatorGuide);
  const dispatch = useStoreDispatch();

  const updateCallbackHandler = (txtResponse) => {
    if (txtResponse?.trim().length > 0) {
      block.payload = txtResponse;
      if (block?.id) {
        dispatch(operatorGuideActions.removeTemplateStub(block.id));
      }
      const ogTemplate = ogTemplateSubject.value;
      ogTemplate.modified = true;
      ogTemplateSubject.next(ogTemplate);
    }
    dispatch(operatorGuideActions.setIsEditingTaskText(false));
    setEditMode(false);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className="task-node-block" data-cr="text-field-node-block">
      {icon && (
        <Handle
          id="target"
          type="target"
          position={Position.Top}
          className="node-block-handles"
          isConnectable
          data-cr="node-block-handles-txt"
        />
      )}
      {icon}
      {editMode ? (
        <div
          onMouseLeave={() => {
            dispatch(operatorGuideActions.setIsEditingTaskText(false));
          }}
          onMouseEnter={() => {
            dispatch(operatorGuideActions.setIsEditingTaskText(true));
          }}
        >
          <CompassTextField
            updateCallback={(e) => updateCallbackHandler(e)}
            showSuccess={false}
            showFailure={false}
            editButtonHidden
            textFieldId={block.id}
            fieldIdInEdition={editMode ? block.id : null}
            maxTextLength={255}
            backButton={false}
            defaultValue={
              templateStub.indexOf(block.id) >= 0 ? null : block.payload
            }
            safeString={false}
          />
        </div>
      ) : (
        <>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="text-span-wrapper"
            onDoubleClick={() => {
              if (!editMode) {
                dispatch(operatorGuideActions.setIsEditingTaskText(true));
                setEditMode(true);
              }
            }}
          >
            <span className="wrap-text">{block.payload}</span>
          </div>
        </>
      )}
    </div>
  );
};

TextFieldNodeBlock.defaultProps = {
  block: null,
  icon: null,
  ogTemplateSubject: null,
};

export default TextFieldNodeBlock;
