// General
import React from "react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { useI18n } from "compass-commons";
// Styles
import "../behaviourProperties.module.css";
// Models
import { TextField } from "dms-lib";
import { RuleDto } from "../../../../../models/incidents/RuleDto";
import { FormErrors } from "../../../../subsystemConfigurator/components/connectionPropertiesPanel/models/SubsystemConnectionDataTypes";

interface BehaviourPropertiesTitleProps {
  ruleId: string;
  dataCr?: string;
  readOnlyMode: boolean;
  control: Control<RuleDto, any>;
  errors?: FormErrors<RuleDto>;
  setFieldValue: UseFormSetValue<RuleDto>;
}

const BehaviourPropertiesTitle = (
  props: BehaviourPropertiesTitleProps
): JSX.Element => {
  const { t: translate } = useI18n();
  const { ruleId, readOnlyMode, control, errors, dataCr, setFieldValue } =
    props;
  return (
    <div className="config-incidents__properties__title">
      <span>{translate("behaviors.propertyFieldsDescription.title")}:</span>
      <div
        className="config-incidents__properties__input-controller title-width"
        id="input-wrapper"
      >
        <Controller
          name="behaviour.description"
          control={control}
          defaultValue={translate("behaviors.placeholders.newBehavior")}
          render={({ field: { onChange, value, ...field } }) => {
            setFieldValue("displayName", value);
            return (
              <TextField
                {...field}
                data-cr={dataCr}
                id={`behaviour-property-title-${ruleId}`}
                className="title-width"
                disabled={readOnlyMode}
                size="small"
                aria-label="behaviour-property-title"
                placeholder={translate(
                  "behaviors.propertyFieldsDescription.titlePlaceholder"
                )}
                value={value}
                onChange={(data) => {
                  onChange(data || null);
                }}
                error={!!errors.behaviour?.description}
                helperText={translate(
                  errors.behaviour?.description?.message ||
                    errors.behaviour?.message,
                  { label: translate("behaviors.fields.title") }
                )}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

BehaviourPropertiesTitle.defaultProps = {
  dataCr: "behaviour-properties-title",
  errors: undefined,
};

export default React.memo(BehaviourPropertiesTitle);
