import React from "react";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import FlakyIcon from "@mui/icons-material/FlakyRounded";
import BallotIcon from "@mui/icons-material/BallotRounded";
import TouchAppIcon from "@mui/icons-material/TouchAppRounded";
import SearchIcon from "@mui/icons-material/SearchRounded";
import { BehaviorSubject } from "rxjs";
import { getNewId } from "compass-commons";
import ConfirmationBlockType from "../components/ogConfigurator/ogTaskTypesPanel/taskTypeWidget/blockTypesWidget/ConfirmationBlockType";
import MultiOptionsBlockType from "../components/ogConfigurator/ogTaskTypesPanel/taskTypeWidget/blockTypesWidget/MultiOptionBlockType";
import TextFieldBlockType from "../components/ogConfigurator/ogTaskTypesPanel/taskTypeWidget/blockTypesWidget/TextFieldBlockType";
import { OgBlockTypeDto } from "../models/ogTaskTypes/OgBlockTypeDto";
import { BlockTypes } from "../utils/Constants";
import TextFieldNodeBlock from "../components/ogConfigurator/ogEditPanel/components/nodes/blocks/TextFieldNodeBlock";
import ConfirmationNodeBlock from "../components/ogConfigurator/ogEditPanel/components/nodes/blocks/ConfirmationNodeBlock";
import MultiOptionsNodeBlock from "../components/ogConfigurator/ogEditPanel/components/nodes/blocks/MultiOptionsNodeBlock";
import { OgTaskTypeDto } from "../models/ogTaskTypes/OgTaskTypeDto";
import { OgTemplateDto } from "../models/ogTemplate/OgTemplateDto";
import ContextTagBlockType from "../components/ogConfigurator/ogTaskTypesPanel/taskTypeWidget/blockTypesWidget/ContextTagBlockType";
import ContextTagNodeBlock from "../components/ogConfigurator/ogEditPanel/components/nodes/blocks/ContextTagNodeBlock";
import ButtonNodeBlock from "../components/ogConfigurator/ogEditPanel/components/nodes/blocks/ButtonNodeBlock";
import DropdownNodeBlock from "../components/ogConfigurator/ogEditPanel/components/nodes/blocks/DropdownNodeBlock";
/* eslint-disable no-return-assign, no-param-reassign */

const mapBlockType = (
  blockType: OgBlockTypeDto,
  onMultiOptionRemove?: (blockId: string, optionId: string) => void,
  isNode = false,
  // eslint-disable-next-line
  icon: any = null,
  ogTemplateSubject: BehaviorSubject<OgTemplateDto> = null,
  errorCallback?: (msg: string) => void
): JSX.Element => {
  if (!blockType) {
    return null;
  }

  // eslint-disable-next-line default-case
  switch (blockType.type) {
    case BlockTypes.TextField: {
      return isNode ? (
        <TextFieldNodeBlock
          key={blockType.id}
          ogTemplateSubject={ogTemplateSubject}
          block={blockType}
          icon={icon}
        />
      ) : (
        <TextFieldBlockType key={blockType.id} block={blockType} />
      );
    }

    case BlockTypes.Confirmation: {
      return isNode ? (
        <ConfirmationNodeBlock key={blockType.id} block={blockType} />
      ) : (
        <ConfirmationBlockType key={blockType.id} block={blockType} />
      );
    }

    case BlockTypes.MultiOption: {
      return isNode ? (
        <MultiOptionsNodeBlock
          onRemove={onMultiOptionRemove}
          key={blockType.id}
          block={blockType}
          ogTemplateSubject={ogTemplateSubject}
          errorCallback={errorCallback}
        />
      ) : (
        <MultiOptionsBlockType key={blockType.id} block={blockType} />
      );
    }

    case BlockTypes.ContextTag: {
      return isNode ? (
        <ContextTagNodeBlock
          key={blockType.id}
          block={blockType}
          ogTemplateSubject={ogTemplateSubject}
          errorCallback={errorCallback}
        />
      ) : (
        <ContextTagBlockType block={blockType} key={blockType.id} />
      );
    }

    case BlockTypes.Button: {
      if (isNode) {
        return <ButtonNodeBlock block={blockType} key={blockType.id} />;
      }
      break;
    }

    case BlockTypes.Dropdown: {
      if (isNode) {
        return <DropdownNodeBlock key={blockType.id} />;
      }
      break;
    }
  }
  return <React.Fragment key={blockType.id || blockType.name || getNewId()} />;
};

const getIconForTask = (taskType: OgTaskTypeDto) => {
  // This function was moved to this file as it can be a generic helper gunction to ogConfigurator.
  // Using blocks.at(1) is a fragile selector and should be replaced in the future.
  const blocks: OgBlockTypeDto[] = taskType?.typeBlocks;
  if (blocks && blocks.length >= 2) {
    switch (blocks.at(1).type) {
      case BlockTypes.MultiOption:
        return <BallotIcon />;
      case BlockTypes.Confirmation:
        return <FlakyIcon />;
      case BlockTypes.ContextTag:
        if (taskType.title === "Resource Interaction Task") {
          return <TouchAppIcon />;
        }
        if (taskType.title === "Search Task") {
          return <SearchIcon />;
        }
        break;
      default:
        return <CheckBoxRoundedIcon />;
    }
  }
  return <></>;
};

function fillTaskTypeIds(type: OgTaskTypeDto): string {
  const taskId = getNewId();
  type.id = taskId;
  type.typeBlocks.forEach((block) => {
    block.id = getNewId();
    block.options?.forEach((obj) => {
      obj.id = getNewId();
    });
    if (block.button) block.button.id = getNewId();
    if (block.positiveAnswer) block.positiveAnswer.id = getNewId();
    if (block.negativeAnswer) block.negativeAnswer.id = getNewId();
  });
  return taskId;
}

export { mapBlockType, fillTaskTypeIds, getIconForTask };
