// General
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// Material UI
import Breadcrumbs from "@mui/material/Breadcrumbs";
// Styles
import "./siteBreadcrumb.module.css";
// Components
import { Typography } from "@mui/material";
import CustomLink from "../commons/link/CustomLink";
// Hooks
import { generateFullSitePath } from "../../hooks/useNavigateToSite";
// Store
import { selectedSiteInfo } from "../../store/sites";

/**
 * Component that represents the sites breadcrumb
 * @returns JSX.Element
 */
const SiteBreadcrumb = (): JSX.Element => {
  const { ancestors: siteAncestors, metadata: leafSite } =
    useSelector(selectedSiteInfo) || {};
  const location = useLocation();

  return (
    <Breadcrumbs>
      {siteAncestors?.length &&
        siteAncestors.map(({ id, name }) => (
          <CustomLink
            key={`custom-link-${id}`}
            to={generateFullSitePath(location.pathname, leafSite.id, id)}
            className="config-breadcrumb__item"
            divWrapper
          >
            {name}
          </CustomLink>
        ))}
      {leafSite && (
        <Typography className="config-breadcrumb__item config-breadcrumb__leaf">
          {leafSite.name}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default SiteBreadcrumb;
