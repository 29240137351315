// General
import React from "react";
import { Control, Controller } from "react-hook-form";
// Components
import { Checkbox } from "dms-lib";
// Styles
import "../behaviourTriggerPanel.module.css";
// Models
import { useI18n } from "compass-commons";
import { FormRuleDto } from "../../../../../models/incidents/form/FormRuleDto";

interface BehaviourTriggerOnDemandProps {
  ruleId: string;
  readOnlyMode: boolean;
  control: Control<FormRuleDto, any>;
  handleEnableOnClick: (select: boolean) => void;
}

const BehaviourTriggerOnDemand = (
  props: BehaviourTriggerOnDemandProps
): JSX.Element => {
  const { ruleId, readOnlyMode, control, handleEnableOnClick } = props;

  const { t: translate } = useI18n();
  return (
    <div className="config-incidents__trigger-input__section_ondemand">
      <Controller
        name="trigger.availableForOnDemand"
        control={control}
        render={({ field: { onChange, value: checked } }) => {
          return (
            <div id={`ondemand-${ruleId}`}>
              <Checkbox
                checked={checked}
                disabled={readOnlyMode}
                FormControlClassName="config-incidents__trigger-input__ondemand_checkbox"
                onChange={(e, checkChange) => {
                  onChange(!e);
                  handleEnableOnClick(checkChange);
                }}
                dataCr="incident-trigger-ondemand"
                label={translate("behaviors.triggerFieldsDescription.ondemand")}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default BehaviourTriggerOnDemand;
