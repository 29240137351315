import { z, string, array } from "zod";
import { ResourceMappingContition } from "./ResourceMappingState";

export const ResourceMappingHasStateFunctionalityData = z.object({
  id: string(),
  states: array(ResourceMappingContition),
});

export type ResourceMappingHasStateFunctionalityData = z.infer<
  typeof ResourceMappingHasStateFunctionalityData
>;
