import { AutoComplete, Button, IntegrationIcon, OptionType } from "dms-lib";
import { TextField } from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import React from "react";
import { useI18n } from "compass-commons";
import { MAX_INTEGRATION_NAME_LENGTH } from "../../../models/edgeDevices/NewEdgeDeviceIntegrationDTO";
import { DriverDataDTO } from "../../../models/edgeDevices/DriverDataDTO";

interface AddEdgeDeviceIntegrationModalContentProps {
  integrationName: string;
  onIntegrationNameChange: (newIntegrationName: string) => void;
  integrationsList: OptionType[];
  selectedIntegration: OptionType;
  onSelectedIntegrationChange: (newSelectedIntegration: OptionType) => void;
  loadingDriversList: boolean;
  versionsList: DriverDataDTO[];
  selectedVersion: DriverDataDTO;
  onSelectedVersionChange: (newSelectedVersion: DriverDataDTO) => void;
}

const AddEdgeDeviceIntegrationModalContent = ({
  integrationName,
  onIntegrationNameChange,
  integrationsList,
  selectedIntegration,
  onSelectedIntegrationChange,
  loadingDriversList,
  versionsList,
  selectedVersion,
  onSelectedVersionChange,
}: AddEdgeDeviceIntegrationModalContentProps): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <>
      <div className="add-edge-device-integration-modal-content-header">
        <IntegrationIcon width="46px" height="46px" />
        <span>
          {translate(
            "edgeDevices.selectedEdgeDevice.addEdgeDeviceIntegrationModal.title"
          )}
        </span>
      </div>
      <div className="add-edge-device-integration-modal-content-body">
        <div className="add-edge-device-integration-modal-content-body-div">
          <span className="add-edge-device-integration-modal-content-body-span">
            {translate(
              "edgeDevices.selectedEdgeDevice.addEdgeDeviceIntegrationModal.inputIntegrationName"
            )}
          </span>
          <TextField
            value={integrationName}
            onChange={(event) => onIntegrationNameChange(event.target.value)}
            className="add-edge-device-integration-modal-content-input"
            size="small"
            inputProps={{
              maxLength: MAX_INTEGRATION_NAME_LENGTH + 1,
            }}
          />
        </div>
        <div className="add-edge-device-integration-modal-content-body-div">
          <span className="add-edge-device-integration-modal-content-body-span">
            {translate(
              "edgeDevices.selectedEdgeDevice.addEdgeDeviceIntegrationModal.selectIntegration"
            )}
          </span>
          <AutoComplete
            options={integrationsList}
            value={selectedIntegration}
            onChangeCallback={onSelectedIntegrationChange}
            loading={loadingDriversList}
          />
        </div>
        <div className="add-edge-device-integration-modal-content-body-div">
          <span className="add-edge-device-integration-modal-content-body-span">
            {translate(
              "edgeDevices.selectedEdgeDevice.addEdgeDeviceIntegrationModal.selectVersion"
            )}
          </span>
          <div className="add-edge-device-integration-modal-content-body-version-select">
            <AutoComplete
              options={versionsList}
              value={selectedVersion}
              getOptionLabel={(option: DriverDataDTO) => option.version}
              onChangeCallback={onSelectedVersionChange}
              disabled={!selectedIntegration}
            />
            <Button
              dataCr="integration-documentation"
              color="primary"
              variant="contained"
              value={selectedVersion?.documentationUrl}
              disabled={!selectedVersion}
              onClick={() =>
                window.open(
                  selectedVersion?.documentationUrl,
                  "_blank",
                  "noreferrer"
                )
              }
              icon
              size="small"
            >
              <InfoRoundedIcon />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEdgeDeviceIntegrationModalContent;
