// General
import React from "react";
// Components
import { Box } from "@mui/material";
import { CircleRounded } from "@mui/icons-material";
// Utils
import { getBaseColor } from "compass-commons";
// Models
import { IncidentTypeRule } from "../../../../../../models/incidents/IncidentTypeRule";

interface IncidentTypeBehaviorProps {
  incidentTypeRule: IncidentTypeRule;
}

const IncidentTypeBehavior = (
  props: IncidentTypeBehaviorProps
): JSX.Element => {
  const { incidentTypeRule } = props;
  return (
    <Box
      className="config-incident-type__behavior"
      data-cr="config-incident-type__behavior"
    >
      <CircleRounded
        fontSize="small"
        style={{
          color: getBaseColor(incidentTypeRule.incidentPriority),
        }}
      />
      <div className="config-incident-type__behavior__name">
        {incidentTypeRule.displayName}
      </div>
      <div className="config-incident-type__behavior__site">
        {incidentTypeRule.siteName}
      </div>
    </Box>
  );
};

export default IncidentTypeBehavior;
