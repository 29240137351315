import { object, string, z } from "zod";

const IncidentTypeStringConstraint = string()
  .min(1, {
    message: "propertiesFieldsValidation.required",
  })
  .max(60, {
    message: "propertiesFieldsValidation.maxSize",
  });

const IconPathConstraint = z.union([
  string().min(1, {
    message: "propertiesFieldsValidation.required",
  }),
  z.null(),
]);

export const FormIncidentTypeDto = object({
  name: IncidentTypeStringConstraint,
  iconPath: IconPathConstraint,
});

export type FormIncidentTypeDto = z.infer<typeof FormIncidentTypeDto>;
