import { object, z } from "zod";
import { FormBehaviourDto } from "./FormBehaviourDto";
import RuleStringOptional from "../RuleStringOptional";
import RuleStringConstraint from "../RuleStringConstraint";
import { FormTriggerDtoSchema } from "./FormTriggerDto";

export const FormRuleDtoSchema = object({
  ruleId: RuleStringOptional,
  displayName: RuleStringConstraint,
  siteId: RuleStringOptional,
  behaviour: FormBehaviourDto,
  trigger: FormTriggerDtoSchema,
});

export type FormRuleDto = z.infer<typeof FormRuleDtoSchema>;
