import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FloorPlanDevice } from "compass-commons";
// Reducers
import resourcesExtraReducers from "./extraReducers";
// Actions
import resourcesExtraActions from "./extraActions";

type ResourceErrorType = { label: string; message: string };

export type ResourceState = {
  selectedResource?: FloorPlanDevice;
  resourcesList: FloorPlanDevice[];
  resourceListPageIndex: number;
  resourceListFilter: string;
  isResourceListLastPage?: boolean;
  loadingResourcesList?: boolean;
  loadingNextResourcesList?: boolean;
  resourceCrudLoading?: boolean;
  resourcesError?: ResourceErrorType;
  loadingSelectedResource?: boolean;
  geoReferencedResources?: FloorPlanDevice[];
};

const SLICE_NAME = "resources";

export const PROP_SEARCH_TERM = "name";

export const initialState: ResourceState = {
  selectedResource: undefined,
  resourcesList: [],
  loadingResourcesList: false,
  loadingNextResourcesList: false,
  resourceCrudLoading: false,
  resourcesError: undefined,
  resourceListPageIndex: 0,
  resourceListFilter: undefined,
  isResourceListLastPage: false,
  loadingSelectedResource: false,
  geoReferencedResources: [],
};

/**
 * Slice to manipulate all operations related to subsystems
 */
export const resourcesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    selectResource: (
      state: ResourceState,
      action: PayloadAction<FloorPlanDevice>
    ) => {
      state.selectedResource = { ...action.payload };
    },
    resetState: (state: ResourceState) => {
      state.selectedResource = null;
      state.resourceListPageIndex = 0;
      state.resourceListFilter = undefined;
      state.isResourceListLastPage = false;
      state.resourcesList = [];
    },
    setResourcesList: (
      state: ResourceState,
      action: PayloadAction<FloorPlanDevice[]>
    ) => {
      state.resourcesList = [...action.payload];
    },
    nextResourcesListPage: (
      state: ResourceState,
      action: PayloadAction<number>
    ) => {
      if (action?.payload) {
        state.resourceListPageIndex = action.payload;
      } else {
        state.resourceListPageIndex += 1;
      }
    },
    setResourcesListFilter: (
      state: ResourceState,
      action: PayloadAction<string>
    ) => {
      state.resourceListFilter = action.payload;
      state.resourceListPageIndex = 0;
      // need to restart the page index to obtain elements from the beginning
    },
  },
  extraReducers: resourcesExtraReducers,
});

// Action creators are generated for each case reducer function
export const resourcesActions = {
  ...resourcesSlice.actions,
  ...resourcesExtraActions,
};

export default resourcesSlice.reducer;
