import { getNewId } from "compass-commons";
import { Connection, Edge, MarkerType } from "reactflow";
import { OgBlockTypeLinkDto } from "../../../../models/ogTaskTypes/OgBlockTypeLinkDto";

export const INITIAL_ID = "initial-edge";
export const INITIAL_SOURCE = "initial";
const INITIAL_SOURCE_HANDLER = "initial-edge";
const INITIAL_TARGET_HANDLER = "target";
const EDGE_TYPE = "removable";
export const SOURCE_HANDLE_RIGHT_SUFFIX = "--r";

/* eslint-disable import/prefer-default-export */
export function newEdge(taskId: string, blockLink: OgBlockTypeLinkDto): Edge {
  return {
    id: getNewId(),
    source: taskId,
    sourceHandle: blockLink.id,
    target: blockLink.linkNextTask,
    targetHandle: INITIAL_TARGET_HANDLER,
    type: EDGE_TYPE,
    markerEnd: { type: MarkerType.Arrow },
  };
}

export function newEdgeWithConnection(connection: Connection): Edge {
  return {
    ...connection,
    id: getNewId(),
    type: EDGE_TYPE,
    markerEnd: { type: MarkerType.Arrow },
  };
}

export const initialEdge = (entryPointId: string): Edge => ({
  id: INITIAL_ID,
  source: INITIAL_SOURCE,
  sourceHandle: INITIAL_SOURCE_HANDLER,
  target: entryPointId,
  targetHandle: INITIAL_TARGET_HANDLER,
  type: EDGE_TYPE,
  markerEnd: {
    type: MarkerType.ArrowClosed,
  },
});

export const findAssociatedEdges = (
  nodeId: string,
  edges: Edge[]
): string[] => {
  return edges
    .filter((eds) => eds.source === nodeId || eds.target === nodeId)
    .map((eds) => eds.id);
};
