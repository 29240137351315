import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Reducers
import incidentsExtraReducers from "./extraReducers";
// Actions
import incidentsExtraActions from "./extraActions";
// Models
import { RuleDto } from "../../models/incidents/RuleDto";
import { RuleLightDto } from "../../models/incidents/RuleLightDto";
import { IncidentTypeDto } from "../../models/incidents/IncidentTypeDto";
import { ResourceMappingLiteDto } from "../../models/resourceMapping/ResourceMappingDto";
import { EventTypesDto } from "../../models/subsystems/EventTypesDto";
import { OgTemplateDto } from "../../models/ogTemplate/OgTemplateDto";
import { RootState } from "../index";
// Constants
import { NEW_RULE_ID_PLACEHOLDER } from "../../components/incidentsConfiguration/utils/Settings";
// Utils
import { filterByProp } from "../../utils/Util";
import { IncidentTypeRulesPageDto } from "../../models/incidents/IncidentTypeRule";

/* eslint-disable no-param-reassign */

type IncidentErrorType = {
  label: string;
  message: string;
  parameters?: { [key: string]: string };
};

export type IncidentState = {
  selectedRule?: RuleDto;
  rulesList: RuleLightDto[];
  searchTerm: string;
  filteredRulesList: RuleLightDto[];
  loadingRulesList?: boolean;
  loadingSelectedRule?: boolean;
  ruleCrudLoading?: boolean;
  incidentsError?: IncidentErrorType;
  eventTypes: EventTypesDto;
  loadingEventTypes?: boolean;
  incidentTypes: IncidentTypeDto[];
  incidentTypesPageRules: { [key: string]: IncidentTypeRulesPageDto };
  loadingIncidentTypesRuleCount?: boolean;
  loadingIncidentTypes?: boolean;
  loadingIncidentTypesRules: { [key: string]: boolean };
  incidentTypeCrudLoading?: boolean;
  incidentTypeForwardError?: boolean;
  operatorGuideTemplates: OgTemplateDto[];
  loadingOperatorGuideTemplates: boolean;
  testingRule: boolean;
  resourceMappings: ResourceMappingLiteDto[];
  loadingResourceMappings: boolean;
  searchTermsResourceMappings: {
    hasMore: boolean;
    searchText: string;
  };
  daysOfWeek: string[];
};

const SLICE_NAME = "incidents";

export const PROP_SEARCH_TERM = "displayName";
export const RESOURCE_MAPPING_PAGE_LENGTH = 100;

export const initialState: IncidentState = {
  selectedRule: undefined,
  rulesList: [],
  searchTerm: "",
  filteredRulesList: [],
  loadingRulesList: false,
  loadingSelectedRule: false,
  ruleCrudLoading: false,
  incidentsError: undefined,
  eventTypes: undefined,
  loadingEventTypes: false,
  incidentTypes: [],
  incidentTypesPageRules: {},
  loadingIncidentTypesRuleCount: false,
  loadingIncidentTypes: false,
  incidentTypeForwardError: false,
  loadingIncidentTypesRules: {},
  operatorGuideTemplates: [],
  loadingOperatorGuideTemplates: false,
  testingRule: false,
  resourceMappings: [],
  loadingResourceMappings: false,
  searchTermsResourceMappings: {
    hasMore: false,
    searchText: "",
  },
  daysOfWeek: [],
};

/**
 * Slice to manipulate all operations related to subsystems
 */
export const incidentsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    ruleChanged: (state: IncidentState) => {
      state.rulesList = [];
    },
    filterTextChanged: (
      state: IncidentState,
      action: PayloadAction<string>
    ) => {
      state.searchTerm = action.payload;
      state.filteredRulesList = filterByProp(
        state.rulesList,
        PROP_SEARCH_TERM,
        action.payload
      );
    },
    ruleSelected: (state: IncidentState, action: PayloadAction<RuleDto>) => {
      state.selectedRule = action.payload;
      state.operatorGuideTemplates = [];
      state.incidentTypes = [];
      state.eventTypes = undefined;
      state.daysOfWeek = [];
    },
    // SOURCE RESOURCE COMBO - INFINITY SCROLL ACTIONS
    resourceMappingTextChanged: (
      state: IncidentState,
      action: PayloadAction<string>
    ) => {
      state.searchTermsResourceMappings = {
        ...state.searchTermsResourceMappings,
        searchText: action.payload,
      };
    },
    resetResourceMappings: (state: IncidentState) => {
      state.resourceMappings = [];
      state.searchTermsResourceMappings = {
        hasMore: false,
        searchText: "",
      };
    },
    resetEventTypes: (state: IncidentState) => {
      state.eventTypes = undefined;
      state.loadingEventTypes = false;
    },
  },
  extraReducers: incidentsExtraReducers,
});

// Action creators are generated for each case reducer function
export const incidentsActions = {
  ...incidentsSlice.actions,
  ...incidentsExtraActions,
};

export const selectIsPlaceholderRule = ({ incidents }: RootState): boolean =>
  incidents.selectedRule?.ruleId === NEW_RULE_ID_PLACEHOLDER;

export default incidentsSlice.reducer;
