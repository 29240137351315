// General
import { createAsyncThunk } from "@reduxjs/toolkit";
// Services
import SandboxIntegrationManagerService from "../../../services/SandboxIntegrationManagerService";
// Models
import { NewEdgeDeviceIntegrationDTO } from "../../../models/edgeDevices/NewEdgeDeviceIntegrationDTO";

/* eslint-disable no-return-await */
function createExtraActions() {
  const getAllEdgeDevices = () => {
    return createAsyncThunk(
      `edgeDevices/getAllEdgeDevices`,
      async ({ page, siteId }: { page: number; siteId: string }) =>
        await SandboxIntegrationManagerService.getAllEdgeDevices(page, siteId)
    );
  };

  const createEdgeDevice = () => {
    return createAsyncThunk(
      `edgeDevices/createEdgeDevice`,
      async ({
        edgeDeviceName,
        siteId,
      }: {
        edgeDeviceName: string;
        siteId: string;
      }) =>
        await SandboxIntegrationManagerService.createEdgeDevice(
          edgeDeviceName,
          siteId
        )
    );
  };

  const deleteEdgeDevice = () => {
    return createAsyncThunk(
      `edgeDevices/deleteEdgeDevice`,
      async (edgeDeviceId: string) =>
        await SandboxIntegrationManagerService.deleteEdgeDevice(edgeDeviceId)
    );
  };

  const getEdgeDeviceIntegrations = () => {
    return createAsyncThunk(
      `edgeDevices/getEdgeDeviceIntegrations`,
      async (edgeDeviceId: string) =>
        await SandboxIntegrationManagerService.getEdgeDeviceIntegrations(
          edgeDeviceId
        )
    );
  };

  const getAllDrivers = () => {
    return createAsyncThunk(
      `edgeDevices/getAllDrivers`,
      async () => await SandboxIntegrationManagerService.getAllDrivers()
    );
  };

  const getEdgeDeviceToken = () => {
    return createAsyncThunk(
      `edgeDevices/getToken`,
      async (edgeDeviceId: string) =>
        await SandboxIntegrationManagerService.getEdgeDeviceToken(edgeDeviceId)
    );
  };

  const addEdgeDeviceIntegration = () => {
    return createAsyncThunk(
      `edgeDevices/addEdgeDeviceIntegration`,
      async ({
        edgeDeviceId,
        integrationDTO,
      }: {
        edgeDeviceId: string;
        integrationDTO: NewEdgeDeviceIntegrationDTO;
      }) =>
        await SandboxIntegrationManagerService.addEdgeDeviceIntegration(
          edgeDeviceId,
          integrationDTO
        )
    );
  };

  return {
    getAllEdgeDevices: getAllEdgeDevices(),
    createEdgeDevice: createEdgeDevice(),
    deleteEdgeDevice: deleteEdgeDevice(),
    getEdgeDeviceIntegrations: getEdgeDeviceIntegrations(),
    getAllDrivers: getAllDrivers(),
    getEdgeDeviceToken: getEdgeDeviceToken(),
    addEdgeDeviceIntegration: addEdgeDeviceIntegration(),
  };
}

const edgeDevicesExtraActions = createExtraActions();

export default edgeDevicesExtraActions;
