import React from "react";
import { EdgeDeviceIntegrationDTO } from "../../../../models/edgeDevices/EdgeDeviceIntegrationDTO";
import "./integration.module.css";
import IntegrationStatusIcon from "../../status/IntegrationStatusIcon";
import IntegrationStatus from "../../../../models/edgeDevices/IntegrationStatus";

interface IntegrationProps {
  integration: EdgeDeviceIntegrationDTO;
}

const Integration = ({ integration }: IntegrationProps): JSX.Element => {
  return (
    <div className="integration-div">
      <IntegrationStatusIcon
        status={integration?.status ?? IntegrationStatus.UNKNOWN}
      />
      <span>{integration.name}</span>
    </div>
  );
};

export default Integration;
