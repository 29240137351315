import React from "react";
import { CircularProgress } from "@mui/material";
import { FloorPlanDevice, SiteFloorPlan } from "compass-commons";
import { BehaviorSubject } from "rxjs";
import MapComponent from "./mapComponent/MapComponent";

interface ResourceContentMapProps {
  isLoading: boolean;
  floorPlan: SiteFloorPlan;
  isGeographical: boolean;
  siteResources: FloorPlanDevice[];
  resource: FloorPlanDevice;
  showLabels: boolean;
  updateDeviceLocation;
  handleZoom;
  currentMapZoom;
  currentMapCenter;
  currentPlaceableResource: BehaviorSubject<FloorPlanDevice>;
  translation?: (text: string) => string;
  imageInfo?: { url: string; width: number; height: number };
}

const ResourceContentMap = (props: ResourceContentMapProps): JSX.Element => {
  const {
    isGeographical,
    floorPlan,
    siteResources,
    isLoading,
    resource,
    translation,
    currentMapCenter,
    currentMapZoom,
    handleZoom,
    updateDeviceLocation,
    showLabels,
    currentPlaceableResource,
    imageInfo,
  } = props;

  function NoLocationSelected(): JSX.Element {
    return (
      <div data-cr="location-not-selected" className="location-not-selected">
        {translation("devices.locNotSelected")?.toUpperCase()}
      </div>
    );
  }

  function NoImageAvailable(): JSX.Element {
    return (
      <div data-cr="floor-plan-no-image" className="floor-plan-no-image">
        {translation("floorPlans.noFloorPlanImage")?.toUpperCase()}
      </div>
    );
  }

  function InnerContent(): JSX.Element {
    if (floorPlan || isGeographical) {
      if (floorPlan?.floorPlanImage || isGeographical) {
        return (
          <div className="device-content-map">
            <MapComponent
              resources={isGeographical ? siteResources : floorPlan?.deviceList}
              imageInfo={imageInfo}
              selectedResource={resource}
              isGeographical={isGeographical}
              showLabels={showLabels}
              updateDeviceCoordinate={updateDeviceLocation}
              handleZoomUpdate={handleZoom}
              overrideInitialZoom={currentMapZoom?.value}
              overrideInitialCenter={currentMapCenter?.value}
              isPlaceable={
                currentPlaceableResource?.value &&
                currentPlaceableResource?.value != null
              }
            />
          </div>
        );
      }
      return <NoImageAvailable />;
    }
    return <NoLocationSelected />;
  }

  return (
    <>
      {isLoading ? (
        <div data-cp="player-spinner" className="config-loading">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <InnerContent />
      )}
    </>
  );
};

ResourceContentMap.defaultProps = {
  translation: (text) => text,
  imageInfo: null,
};

export default ResourceContentMap;
