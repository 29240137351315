// General
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useI18n } from "compass-commons";
// Components
// Styles
import "../behaviourTriggerPanel.module.css";
// Models
import { TextField } from "@mui/material";
import { FormRuleDto } from "../../../../../models/incidents/form/FormRuleDto";

interface BehaviourTriggerDisabledCommentProps {
  ruleId: string;
  readOnlyMode: boolean;
  control: Control<FormRuleDto, any>;
}

const BehaviourTriggerDisabledComment = (
  props: BehaviourTriggerDisabledCommentProps
): JSX.Element => {
  const { ruleId, readOnlyMode, control } = props;
  const { t: translate } = useI18n();

  return (
    <div className="config-incidents__trigger-input__section">
      <div className="config-incidents__trigger-input__controller">
        <Controller
          name="trigger.commentForTriggerBeingDisabled"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <div id={ruleId}>
                <span>
                  {translate(
                    "behaviors.triggerFieldsDescription.disabledCommentLabel"
                  )}
                </span>
                <TextField
                  size="small"
                  placeholder={translate(
                    "behaviors.triggerFieldsDescription.disabledCommentPlaceholder"
                  )}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  disabled={readOnlyMode}
                  data-cr="incident-trigger-disable-comment"
                />
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default BehaviourTriggerDisabledComment;
