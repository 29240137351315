import React from "react";

import { Options } from "react-select/dist/declarations/src/types";
import { useSelector } from "react-redux";
import CompassDropdown from "../../../commons/dropdown/CompassDropdown";
import "./resouceContentHeader.module.css";
import { readOnlyMode } from "../../../../store/root";
import ResourceContentHeaderActions from "./ResourceContentHeaderActions";

interface ResourceContentHeaderProps {
  handleSelect: (opt) => void;
  handlePlace: () => void;
  translation?: (text: string) => string;
  dropdownOptions: Options<any>;
  isLoading;
  selectedLocationOption?: any;
  isSelectPositionEnabled?: boolean;
  isDeleteResourceEnabled?: boolean;
  handleDelete: () => void;
  actualResourceLocation?: string;
}

const ResourceContentHeader = (
  props: ResourceContentHeaderProps
): JSX.Element => {
  const {
    handlePlace,
    handleSelect,
    dropdownOptions,
    isLoading,
    translation,
    selectedLocationOption,
    isSelectPositionEnabled,
    isDeleteResourceEnabled,
    handleDelete,
    actualResourceLocation,
  } = props;

  const isReadOnlyMode = useSelector(readOnlyMode);

  const handleSelectOption = (option) => {
    handleSelect(option);
  };

  return (
    <div className="device-content-header">
      <b>{translation("devices.position")}</b>
      <div className="device-content-header-right">
        <CompassDropdown
          options={dropdownOptions}
          isLoading={isLoading}
          handleOptionSelect={handleSelectOption}
          selectedOption={selectedLocationOption}
          placeholder={translation("devices.chooseLoc")}
          isDisabled={isReadOnlyMode}
          previouslySelectedOption={actualResourceLocation}
        />
        <ResourceContentHeaderActions
          isSelectPositionEnabled={isSelectPositionEnabled}
          isDeleteDeviceEnabled={isDeleteResourceEnabled}
          handlePlace={handlePlace}
          handleDelete={handleDelete}
          translation={translation}
        />
      </div>
    </div>
  );
};

ResourceContentHeader.defaultProps = {
  translation: (text) => text,
  selectedLocationOption: {},
  isDeleteResourceEnabled: true,
  isSelectPositionEnabled: true,
  actualResourceLocation: null,
};

export default ResourceContentHeader;
