// Generic
import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
// Actions
import subsystemsExtraActions from "../extraActions";
import { sitesActions } from "../../sites";
// Store
import { filterSubsystems, initialState, SubsystemsState } from "..";
// Models
import { SubsystemLightDto } from "../../../models/subsystems/SubsystemLightDto";
import { ConnectionPropertyTemplateDto } from "../../../models/subsystems/ConnectionPropertyTemplateDto";
import { ModelDto } from "../../../models/subsystems/ModelDto";
import { BrandDto } from "../../../models/subsystems/BrandDto";
import { convertToSubsystemConnections } from "../../../models/subsystems/mapper/SubsystemMapper";

/* eslint-disable no-param-reassign, @typescript-eslint/no-explicit-any */

const fromSiteReducer = (builder: ActionReducerMapBuilder<SubsystemsState>) => {
  builder.addCase(sitesActions.changeSelectedSiteId, () => ({
    ...initialState,
  }));
};

const subsystemGetForSiteReducer = (
  builder: ActionReducerMapBuilder<SubsystemsState>
) => {
  builder
    .addCase(
      subsystemsExtraActions.getSiteSubsystems.pending,
      (state: SubsystemsState) => {
        state.loadingSubsystemsList = true;
        state.subsystemError = null;
      }
    )
    .addCase(
      subsystemsExtraActions.getSiteSubsystems.fulfilled,
      (state: SubsystemsState, action: PayloadAction<SubsystemLightDto[]>) => {
        // order subsystems by name
        state.subsystemsList = action.payload
          .map((s: SubsystemLightDto) => convertToSubsystemConnections(s))
          .sort((a, b) => a.name.localeCompare(b.name));
        state.filteredSubsystemsList = filterSubsystems(state);
        state.loadingSubsystemsList = false;
        state.subsystemError = null;
      }
    )
    .addCase(
      subsystemsExtraActions.getSiteSubsystems.rejected,
      (state: SubsystemsState, action) => {
        state.loadingSubsystemsList = false;
        state.subsystemError = {
          label: "subsystems.errorListingSubsystems",
          message: action.error.message,
        };
      }
    );
};

const subsystemResourcesReducer = (
  builder: ActionReducerMapBuilder<SubsystemsState>
) => {
  builder
    .addCase(
      subsystemsExtraActions.refreshResources.pending,
      (state: SubsystemsState) => {
        state.disableRefreshingResources = true;
      }
    )
    .addCase(
      subsystemsExtraActions.refreshResources.fulfilled,
      (state: SubsystemsState) => {
        state.disableRefreshingResources = false;
        state.selectedSubsystemResources = {
          ...state.selectedSubsystemResources,
          updatingResources: true,
        };
      }
    )
    .addCase(
      subsystemsExtraActions.refreshResources.rejected,
      (state: SubsystemsState, action) => {
        state.disableRefreshingResources = false;
        state.subsystemError = {
          label: "subsystems.errorRefreshingResources",
          message: action.error.message,
        };
      }
    )
    .addCase(
      subsystemsExtraActions.subsystemResourcesPolling.rejected,
      (state: SubsystemsState, action) => {
        state.selectedSubsystemResources = {
          ...state.selectedSubsystemResources,
          updatingResources: false,
        };
        state.subsystemError = {
          label: "subsystems.errorResourcesPolling",
          message: action.error.message,
        };
      }
    );
};

const subsystemGetConnectionPropertiesReducer = (
  builder: ActionReducerMapBuilder<SubsystemsState>
) => {
  builder
    .addCase(
      subsystemsExtraActions.getConnectionProperties.pending,
      (state: SubsystemsState) => {
        state.subsystemCrudLoading = true;
        state.subsystemError = null;
      }
    )
    .addCase(
      subsystemsExtraActions.getConnectionProperties.fulfilled,
      (
        state: SubsystemsState,
        action: PayloadAction<ConnectionPropertyTemplateDto[]>
      ) => {
        state.connectionPropertyTemplates = action.payload;
        state.subsystemCrudLoading = false;
        state.subsystemError = null;
      }
    )
    .addCase(
      subsystemsExtraActions.getConnectionProperties.rejected,
      (state: SubsystemsState, action) => {
        state.subsystemCrudLoading = false;
        state.subsystemError = {
          label: "subsystems.errorGettingConnectionProperties",
          message: action.error.message,
        };
      }
    );
};

const subsystemGetConnectionPropertiesTemplateReducer = (
  builder: ActionReducerMapBuilder<SubsystemsState>
) => {
  builder
    .addCase(
      subsystemsExtraActions.getConnectionPropertiesTemplate.pending,
      (state: SubsystemsState) => {
        state.connectionPropertiesLoading = true;
        state.subsystemCrudLoading = true;
        state.subsystemError = null;
      }
    )
    .addCase(
      subsystemsExtraActions.getConnectionPropertiesTemplate.fulfilled,
      (
        state: SubsystemsState,
        action: PayloadAction<ConnectionPropertyTemplateDto[]>
      ) => {
        state.connectionPropertyTemplates = action.payload;
        state.connectionPropertiesLoading = false;
        state.subsystemCrudLoading = false;
        state.subsystemError = null;
      }
    )
    .addCase(
      subsystemsExtraActions.getConnectionPropertiesTemplate.rejected,
      (state: SubsystemsState, action) => {
        state.connectionPropertiesLoading = false;
        state.subsystemCrudLoading = false;
        state.subsystemError = {
          label: "subsystems.errorGettingConnectionPropertiesTemplate",
          message: action.error.message,
        };
      }
    );
};

const subsystemGetModels = (
  builder: ActionReducerMapBuilder<SubsystemsState>
) => {
  builder
    .addCase(
      subsystemsExtraActions.getModels.pending,
      (state: SubsystemsState) => {
        state.models = [];
        state.loadingModels = true;
        state.loadingModelsRequests += 1;
        state.subsystemError = null;
      }
    )
    .addCase(
      subsystemsExtraActions.getModels.fulfilled,
      (state: SubsystemsState, action: PayloadAction<ModelDto[]>) => {
        state.loadingModelsRequests -= 1;
        state.models = action.payload;
        state.loadingModels = false;
        state.subsystemError = null;
      }
    )
    .addCase(
      subsystemsExtraActions.getModels.rejected,
      (state: SubsystemsState, action) => {
        // Handle Concurrent Requests
        state.loadingModelsRequests -= 1;
        const isLastRequest = state.loadingModelsRequests === 0;

        if (!isLastRequest) return;
        state.models = [];
        state.loadingModels = false;
        state.subsystemError = {
          label: "subsystems.errorGettingModels",
          message: action.error.message,
        };
      }
    );
};

const subsystemGetBrands = (
  builder: ActionReducerMapBuilder<SubsystemsState>
) => {
  builder
    .addCase(
      subsystemsExtraActions.getBrands.pending,
      (state: SubsystemsState) => {
        state.loadingBrands = true;
        state.subsystemError = null;
      }
    )
    .addCase(
      subsystemsExtraActions.getBrands.fulfilled,
      (state: SubsystemsState, action: PayloadAction<BrandDto[]>) => {
        state.brands = action.payload;
        state.loadingBrands = false;
        state.subsystemError = null;
      }
    )
    .addCase(
      subsystemsExtraActions.getBrands.rejected,
      (state: SubsystemsState, action) => {
        state.loadingBrands = false;
        state.subsystemError = {
          label: "subsystems.errorGettingBrands",
          message: action.error.message,
        };
      }
    );
};

const subsystemCreateReducer = (
  builder: ActionReducerMapBuilder<SubsystemsState>
) => {
  // Actions that helps to create a subsystem before committing to BE
  builder
    .addCase(
      subsystemsExtraActions.createSubsystem.pending,
      (state: SubsystemsState) => {
        state.subsystemError = null;
        state.subsystemCrudLoading = true;
      }
    )
    .addCase(
      subsystemsExtraActions.createSubsystem.fulfilled,
      (state: SubsystemsState) => {
        state.subsystemCrudLoading = false;
      }
    )
    .addCase(
      subsystemsExtraActions.createSubsystem.rejected,
      (state: SubsystemsState, action) => {
        state.subsystemError = {
          label: "subsystems.failToCreateSubsystem",
          message: action.error.message,
        };
        state.subsystemCrudLoading = false;
      }
    );
};

const subsystemEditReducer = (
  builder: ActionReducerMapBuilder<SubsystemsState>
) => {
  // Actions that helps to update a subsystem before committing to BE
  builder
    .addCase(
      subsystemsExtraActions.updateSubsystem.pending,
      (state: SubsystemsState) => {
        state.subsystemError = null;
        state.subsystemCrudLoading = true;
      }
    )
    .addCase(
      subsystemsExtraActions.updateSubsystem.fulfilled,
      (state: SubsystemsState) => {
        state.subsystemCrudLoading = false;
      }
    )
    .addCase(
      subsystemsExtraActions.updateSubsystem.rejected,
      (state: SubsystemsState, action) => {
        state.subsystemError = {
          label: "subsystems.failToUpdateSubsystem",
          message: action.error.message,
        };
        state.subsystemCrudLoading = false;
      }
    );
};

const subsystemDeleteReducer = (
  builder: ActionReducerMapBuilder<SubsystemsState>
) => {
  // Actions that helps to delete a subsystem before committing to BE
  builder
    .addCase(
      subsystemsExtraActions.deleteSubsystem.pending,
      (state: SubsystemsState) => {
        state.subsystemError = null;
        state.subsystemCrudLoading = true;
      }
    )
    .addCase(
      subsystemsExtraActions.deleteSubsystem.fulfilled,
      (state: SubsystemsState) => {
        state.subsystemCrudLoading = false;
      }
    )
    .addCase(
      subsystemsExtraActions.deleteSubsystem.rejected,
      (state: SubsystemsState, action) => {
        state.subsystemError = {
          label: "subsystems.failToDeleteSubsystem",
          message: action.error.message,
        };
        state.subsystemCrudLoading = false;
      }
    );
};

const subsystemsExtraReducers = (
  builder: ActionReducerMapBuilder<SubsystemsState>
): any => {
  fromSiteReducer(builder);
  subsystemGetForSiteReducer(builder);
  subsystemGetConnectionPropertiesReducer(builder);
  subsystemGetConnectionPropertiesTemplateReducer(builder);
  subsystemGetModels(builder);
  subsystemGetBrands(builder);
  subsystemCreateReducer(builder);
  subsystemEditReducer(builder);
  subsystemDeleteReducer(builder);
  subsystemResourcesReducer(builder);
};

export default subsystemsExtraReducers;
