/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */

import moment from "moment-timezone";

const removeStringSuffix = (text, suffix): string =>
  text?.endsWith(suffix) ? text.slice(0, -suffix.length) : text;

const isSafeString = (str: string): RegExpMatchArray => {
  const regEx = /^[0-9a-zA-ZÀ-ž_ ]+$/;
  return str && str.match(regEx);
};

const buildErrorMessage = (error: any): string => {
  return error.data?.errorMessage || error.data?.message || error;
};

const isEmpty = (obj: any): boolean => !Object.keys(obj).length;

const sortByProp = (arrayToSort: any[], property?: string): any[] =>
  property
    ? arrayToSort
        .slice()
        .sort((a, b) => a?.[property]?.localeCompare(b?.[property]))
    : arrayToSort.slice().sort();

const filterByProp = (
  arrayToSort: any[],
  property: string,
  searchTerm: string
): any[] =>
  arrayToSort?.filter((s) => {
    return s[property]?.toLowerCase().includes(searchTerm.trim().toLowerCase());
  });

/**
 * Take a function and return a function that is like the initial function.
 * The returned function is invoked _once_ after repeated calls within the `delay`
 * @example
 * const hello = debounce(() => console.log('Hello World'), 300)
 * hello()
 * hello()
 * hello()
 * // 300ms later -> 'Hello World' (once!)
 */
const debounce = (
  callbackFunc: any,
  timeout = 300
): ((...args: any[]) => void) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callbackFunc.apply(this, args);
    }, timeout);
  };
};

const getTimeZoneOptions = (showTimezoneOffset) => {
  const timeZones = moment.tz.names();
  const offsetTmz = [];
  if (!timeZones) return [];
  timeZones.forEach((tz) => {
    const tzOffset = moment.tz(new Date(), tz).format("Z");
    const timeZoneOption = {
      name: showTimezoneOffset ? `(GMT${tzOffset}) ${tz}` : tz,
      id: tz,
    };

    offsetTmz.push(timeZoneOption);
  });
  return offsetTmz;
};

const getDefaultDays = (locale: string): string[] => {
  moment.locale(locale);
  const weekdays: string[] = [];
  for (let i = 0; i < 7; i++) {
    weekdays.push(moment.weekdays(i).toUpperCase());
  }
  return weekdays;
};

export {
  isSafeString,
  isEmpty,
  debounce,
  sortByProp,
  filterByProp,
  buildErrorMessage,
  getTimeZoneOptions,
  getDefaultDays,
  removeStringSuffix,
};
