// General
import React from "react";
import { useI18n } from "compass-commons";
import { Control, Controller } from "react-hook-form";
// Styles
import "../behaviourProperties.module.css";
// Components
import { Checkbox } from "dms-lib";
// Models
import { FormRuleDto } from "../../../../../models/incidents/form/FormRuleDto";
import { AggregateMethodEnum } from "../../../../../models/incidents/form/FormBehaviourDto";

interface BehaviourPropertiesAggregationMethodProps {
  ruleId: string;
  readOnlyMode: boolean;
  control: Control<FormRuleDto, any>;
}

const BehaviourPropertiesAggregationMethod = (
  props: BehaviourPropertiesAggregationMethodProps
): JSX.Element => {
  const { t: translate } = useI18n();
  const { ruleId, readOnlyMode, control } = props;
  return (
    <div className="config-incidents__properties-input__aggregation-method">
      <Controller
        name="behaviour.aggregationMethod"
        control={control}
        render={({ field: { onChange, value: aggregateMethod } }) => {
          return (
            <div id={`aggregationMethod-${ruleId}`}>
              <Checkbox
                checked={
                  aggregateMethod === AggregateMethodEnum.enum.SITE_AND_RULE
                }
                disabled={readOnlyMode}
                FormControlClassName="config-incidents__properties-input__aggregation-method_checkbox"
                onChange={(e, isChecked) => {
                  onChange(
                    isChecked
                      ? AggregateMethodEnum.enum.SITE_AND_RULE
                      : AggregateMethodEnum.enum.DISABLED
                  );
                }}
                dataCr="properties-aggregationMethod"
                label={translate(
                  "behaviors.propertyFieldsDescription.aggregationMethod"
                )}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default React.memo(BehaviourPropertiesAggregationMethod);
