// General
import { HttpClient } from "compass-shared-services";
import { stringify } from "qs";
// Models
import { ResourceMappingDto } from "../models/resourceMapping/ResourceMappingDto";
import { ResourceMappingFilters } from "../models/resourceMapping/ResourceMappingFilters";

const { RESOURCE_MAPPING_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  GET_RESOURCE_MAPPINGS: `${RESOURCE_MAPPING_MANAGER_PATH}/resource-mapping`,
};

/**
 * Floor plan Service where backend service is called
 *
 */
export default class ResourceMappingService {
  static abortController: AbortController;

  static async getResourceMappings(
    resourceMappingFilters?: ResourceMappingFilters
  ): Promise<ResourceMappingDto[]> {
    this.abortController?.abort();
    this.abortController = new AbortController();

    const urlPath = `${URL_PATHS.GET_RESOURCE_MAPPINGS}`;
    return httpClient
      .get<ResourceMappingDto[]>({
        url: urlPath,
        config: {
          params: {
            ...resourceMappingFilters,
          },
          paramsSerializer: {
            serialize: (params) => stringify(params, { arrayFormat: "comma" }),
          },
          signal: this.abortController.signal,
        },
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }
}
