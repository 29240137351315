// General
import React from "react";
// Components
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Button, Dialog } from "dms-lib";
import { useI18n } from "compass-commons";
// Styles
import "./deleteEdgeDeviceModal.module.css";

interface DeleteEdgeDeviceModalProps {
  edgeDeviceName: string;
  open: boolean;
  onCancelCallback: () => void;
  onDeleteCallback: () => void;
  saving: boolean;
}

const DeleteEdgeDeviceModal = ({
  edgeDeviceName,
  open,
  onCancelCallback,
  onDeleteCallback,
  saving,
}: DeleteEdgeDeviceModalProps): JSX.Element => {
  const { t: translate } = useI18n();

  return (
    <Dialog
      id="delete-edge-device-modal"
      open={open}
      size="xs"
      dialogContent={
        <div className="delete-edge-device-modal-content-header">
          <WarningRoundedIcon
            color="error"
            className="delete-edge-device-modal-content-header-icon"
          />
          <span>
            {translate("edgeDevices.deleteEdgeDeviceModal.description", {
              label: edgeDeviceName,
            })}
          </span>
        </div>
      }
      dialogActions={
        <>
          <Button
            dataCr="cancel-delete-edge-device"
            color="primary"
            variant="contained"
            onClick={onCancelCallback}
            disabled={saving}
          >
            {translate("noConfirmDelete", { ns: "Shared" })}
          </Button>
          <Button
            dataCr="confirm-delete-edge-device"
            color="secondary"
            variant="outlined"
            onClick={onDeleteCallback}
            disabled={saving}
          >
            {translate("confirmDelete", { ns: "Shared" })}
          </Button>
        </>
      }
    />
  );
};

export default DeleteEdgeDeviceModal;
