// General
import React from "react";
// Components
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Button, Dialog } from "dms-lib";
import { useI18n } from "compass-commons";
// Styles
import "./edgeDeviceWasDeletedModal.module.css";

interface DeleteEdgeDeviceModalProps {
  edgeDeviceName: string;
  open: boolean;
  onConfirmCallback: () => void;
}

const EdgeDeviceWasDeletedModal = ({
  edgeDeviceName,
  open,
  onConfirmCallback,
}: DeleteEdgeDeviceModalProps): JSX.Element => {
  const { t: translate } = useI18n();

  return (
    <Dialog
      id="edge-device-was-deleted-modal"
      open={open}
      size="xs"
      dialogContent={
        <div className="edge-device-was-deleted-modal-content-header">
          <DeleteRoundedIcon
            width="50px"
            height="50px"
            className="edge-device-was-deleted-modal-content-header-icon"
          />
          <span className="edge-device-was-deleted-modal-content-header-span">
            {translate("edgeDevices.deleteEdgeDeviceModal.confirmed", {
              label: edgeDeviceName,
            })}
          </span>
        </div>
      }
      dialogActions={
        <Button color="primary" variant="contained" onClick={onConfirmCallback}>
          {translate("ok", { ns: "Shared" })}
        </Button>
      }
    />
  );
};

export default EdgeDeviceWasDeletedModal;
