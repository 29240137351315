// Generic
import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
// Actions
import sitesExtraActions from "../extraActions";
// Store
import { SitesState, SITE_PLACEHOLDER } from "..";
import { rootActions } from "../../root";
// Models
import { SiteTreeNodeDto } from "../../../models/sites/SiteTreeNodeDto";
import { MenuTabRoute } from "../../../models/configMenu/MenuTabRoute";

/* eslint-disable no-param-reassign, @typescript-eslint/no-explicit-any */

const fromRootReducer = (builder: ActionReducerMapBuilder<SitesState>) => {
  // Receives a boolean : true if it has to update lastSnapshot, false otherwise
  builder.addCase(
    rootActions.changeTab,
    (state: SitesState, action: PayloadAction<MenuTabRoute>) => {
      state.siteTabActive = action.payload === MenuTabRoute.Generic;
    }
  );

  // Actions that helps creating a site before committing to BE
  // It adds the new temporary site as child of the selected node
  builder.addCase(rootActions.activateCreationMode, (state: SitesState) => {
    const nodeToAdd = state.siteHierarchy?.[state.selectedSiteId];
    if (!nodeToAdd || !state.siteTabActive) return;
    state.siteLastSnap = nodeToAdd;
    let newSite = SITE_PLACEHOLDER;
    if (nodeToAdd.metadata.timeZone) {
      newSite = {
        ...newSite,
        timeZone: nodeToAdd.metadata.timeZone,
      };
    }
    state.siteCurrentSnap = {
      ...nodeToAdd,
      metadata: {
        ...nodeToAdd.metadata,
        timeZone: nodeToAdd.metadata.timeZone,
        isTimeZoneInherited: nodeToAdd.metadata.isTimeZoneInherited,
        childSites: [...nodeToAdd.metadata.childSites, newSite],
      },
    };
  });
  builder.addCase(rootActions.activateEditionMode, (state: SitesState) => {
    const nodeToAdd = state.siteHierarchy?.[state.selectedSiteId];
    if (!nodeToAdd || !state.siteTabActive) return;
    state.siteLastSnap = nodeToAdd;
    state.siteCurrentSnap = nodeToAdd;
  });
  // Receives a boolean : true if it has to update lastSnapshot, false otherwise
  builder.addCase(
    rootActions.activateReadOnlyMode,
    (state: SitesState, action: PayloadAction<boolean>) => {
      if (action.payload) state.siteLastSnap = state.siteCurrentSnap;
      else state.siteCurrentSnap = state.siteLastSnap;
    }
  );
};

const siteGetAllReducer = (builder: ActionReducerMapBuilder<SitesState>) => {
  builder
    .addCase(sitesExtraActions.getAll.pending, (state: SitesState) => {
      state.siteRoot = {
        ...state.siteRoot,
        loading: true,
      };
      state.siteError = null;
    })
    .addCase(
      sitesExtraActions.getAll.fulfilled,
      (state: SitesState, action: PayloadAction<SiteTreeNodeDto>) => {
        state.siteRoot = {
          loading: false,
          data: action.payload,
        };
      }
    )
    .addCase(sitesExtraActions.getAll.rejected, (state: SitesState, action) => {
      state.siteRoot = {
        loading: false,
        data: null,
      };
      state.siteError = {
        label: "site.sitesNotFound",
        message: action.error.message,
      };
    });
};

const siteCreateReducer = (builder: ActionReducerMapBuilder<SitesState>) => {
  // Actions that helps creating a site before committing to BE
  // It adds the new temporary site as child of the selected node
  builder
    .addCase(sitesExtraActions.createSite.pending, (state: SitesState) => {
      state.siteError = null;
      state.siteCrudLoading = true;
    })
    .addCase(sitesExtraActions.createSite.fulfilled, (state: SitesState) => {
      state.siteCrudLoading = false;
    })
    .addCase(
      sitesExtraActions.createSite.rejected,
      (state: SitesState, action) => {
        state.siteError = {
          label: "site.failToCreateSite",
          message: action.error.message,
        };
        state.siteCrudLoading = false;
      }
    );
};

const siteUpdateReducer = (builder: ActionReducerMapBuilder<SitesState>) => {
  // Actions that helps creating a site before committing to BE
  // It adds the new temporary site as child of the selected node
  builder
    .addCase(sitesExtraActions.updateSite.pending, (state: SitesState) => {
      state.siteError = null;
      state.siteCrudLoading = true;
    })
    .addCase(sitesExtraActions.updateSite.fulfilled, (state: SitesState) => {
      state.siteCrudLoading = false;
    })
    .addCase(
      sitesExtraActions.updateSite.rejected,
      (state: SitesState, action) => {
        state.siteCrudLoading = false;
        state.siteError = {
          label: "site.failToUpdateSite",
          message: action.error.message,
        };
      }
    );
};

const siteDeleteReducer = (builder: ActionReducerMapBuilder<SitesState>) => {
  // Actions that helps creating a site before committing to BE
  // It adds the new temporary site as child of the selected node
  builder
    .addCase(sitesExtraActions.deleteSite.pending, (state: SitesState) => {
      state.siteCrudLoading = true;
      state.siteError = null;
    })
    .addCase(sitesExtraActions.deleteSite.fulfilled, (state: SitesState) => {
      state.siteCrudLoading = false;
    })
    .addCase(
      sitesExtraActions.deleteSite.rejected,
      (state: SitesState, action) => {
        state.siteCrudLoading = false;
        state.siteError = {
          label: "site.failToDeleteSite",
          message: action.error.message,
        };
      }
    );
};

const sitesExtraReducers = (
  builder: ActionReducerMapBuilder<SitesState>
): any => {
  fromRootReducer(builder);
  siteGetAllReducer(builder);
  siteCreateReducer(builder);
  siteUpdateReducer(builder);
  siteDeleteReducer(builder);
};

export default sitesExtraReducers;
