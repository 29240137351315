// General
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "compass-commons";
// Styles
import "./DeleteIncidentType.module.css";
// Components
import CircularProgress from "@mui/material/CircularProgress";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import DeleteIncidentTypeForward from "./components/DeleteIncidentTypeForward";
import DeleteIncidentTypePanel from "./components/DeleteIncidentTypePanel";
// Models
import IncidentTypeDrawerState from "../../../../models/incidents/IncidentTypeDrawerState";
import { IncidentTypeDto } from "../../../../models/incidents/IncidentTypeDto";
// Store
import { selectIncidents, useStoreDispatch } from "../../../../store";
import { incidentsActions } from "../../../../store/incidents";

export interface DeleteIncidentTypeProps {
  configDrawerState: (s: IncidentTypeDrawerState, i?: IncidentTypeDto) => void;
  incidentTypes: IncidentTypeDto[];
  selectedIncidentType: IncidentTypeDto;
}

const LoadingIndicator = (
  <div className="config-loading">
    <CircularProgress color="inherit" />
  </div>
);

const DeleteIncidentType = (props: DeleteIncidentTypeProps): JSX.Element => {
  const { configDrawerState, selectedIncidentType, incidentTypes } = props;
  const dispatch = useStoreDispatch();
  const { incidentTypeCrudLoading, incidentTypeForwardError } =
    useSelector(selectIncidents);
  const [selectedIncidentTypeForward, setSelectedIncidentTypeForward] =
    useState<IncidentTypeDto>();
  const { t: translate, Trans } = useI18n();

  const handleDelete = useCallback(
    (incidentTypeId) => {
      dispatch(
        incidentsActions.deleteIncidentType({
          incidentTypeId,
        })
      );
      configDrawerState(IncidentTypeDrawerState.List);
    },
    [dispatch, selectedIncidentType]
  );

  const handleForward = (
    incidentType: IncidentTypeDto,
    incidentTypeToForward: IncidentTypeDto
  ) => {
    setSelectedIncidentTypeForward(incidentTypeToForward);
    dispatch(
      incidentsActions.forwardIncidentTypeRules({
        incidentTypeId: incidentType.id,
        incidentTypeIdToForward: incidentTypeToForward.id,
      })
    );
  };

  useEffect(() => {
    if (selectedIncidentTypeForward && incidentTypeForwardError) {
      configDrawerState(IncidentTypeDrawerState.List);
    }
  }, [incidentTypeForwardError, selectedIncidentTypeForward]);

  return (
    <>
      {incidentTypeCrudLoading ? (
        LoadingIndicator
      ) : (
        <>
          {selectedIncidentType?.ruleCount === 0 && (
            <DeleteIncidentTypePanel
              configDrawerState={configDrawerState}
              onDelete={handleDelete}
              selectedIncidentType={selectedIncidentType}
              Icon={
                <WarningRoundedIcon className="config-delete-incident-type__icons" />
              }
              message={
                <Trans
                  i18nKey="incidentTypes.delete.deleteMessage"
                  values={{ incidentType: selectedIncidentType?.name }}
                  components={{ bold: <strong /> }}
                />
              }
              colorIcon="error"
            />
          )}
          {selectedIncidentType?.ruleCount !== 0 &&
            !selectedIncidentTypeForward && (
              <DeleteIncidentTypeForward
                configDrawerState={configDrawerState}
                onForward={handleForward}
                selectedIncidentType={selectedIncidentType}
                incidentTypes={incidentTypes}
              />
            )}
          {!!selectedIncidentTypeForward && (
            <DeleteIncidentTypePanel
              configDrawerState={configDrawerState}
              onDelete={handleDelete}
              selectedIncidentType={selectedIncidentType}
              Icon={
                <InfoRoundedIcon className="config-delete-incident-type__icons" />
              }
              message={
                <Trans
                  i18nKey="incidentTypes.delete.warnMessage"
                  values={{
                    count: selectedIncidentType?.ruleCount,
                    incidentType: selectedIncidentTypeForward?.name,
                  }}
                  components={{ bold: <strong /> }}
                />
              }
              subMessage={
                <Trans
                  i18nKey="incidentTypes.delete.deleteMessage"
                  values={{ incidentType: selectedIncidentType?.name }}
                  components={{ bold: <strong /> }}
                />
              }
              colorIcon="warning"
            />
          )}
        </>
      )}
    </>
  );
};

export default DeleteIncidentType;
