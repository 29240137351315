// General
import React, { PropsWithChildren } from "react";
// Material UI
import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import { Button } from "dms-lib";

interface SidebarProps {
  sideBarAction: () => void;
}

/**
 * Wrapper for the sidebar menu
 * @param sideBarAction - function that enables to interact with the sidebar
 * @param children
 * @returns JSX.Element
 */
const Sidebar = ({
  sideBarAction,
  children,
}: PropsWithChildren<SidebarProps>): JSX.Element => {
  return (
    <>
      <div data-cr="sidebar-wrapper" className="config-layout__subnav">
        <div className="config-sidebar__item">
          <Button
            id="panelCollapsible"
            name="panelCollapsible"
            color="primary"
            variant="contained"
            onClick={() => sideBarAction()}
            icon
          >
            <KeyboardDoubleArrowLeftRoundedIcon />
          </Button>
        </div>
      </div>
      {children}
    </>
  );
};

export default Sidebar;
