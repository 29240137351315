import {
  ConnectionData,
  ConnectionPropertiesData,
  SubsystemDto,
} from "../SubsystemDto";
import {
  SubsystemLightConnections,
  SubsystemLightDto,
  SubsystemLightResources,
} from "../SubsystemLightDto";
import { UpdateSubsystemDto } from "../UpdateSubsystemDto";

export const convertToSubsystemConnections = (
  subsystemFullDto: SubsystemLightDto
): SubsystemLightConnections | undefined =>
  subsystemFullDto?.id && {
    id: subsystemFullDto.id,
    name: subsystemFullDto.name,
    brandId: subsystemFullDto.brandId,
    brandName: subsystemFullDto.brandName,
    modelId: subsystemFullDto.modelId,
  };

export const convertToSubsystemResources = (
  subsystemFullDto: SubsystemLightDto
): SubsystemLightResources =>
  subsystemFullDto?.id && {
    id: subsystemFullDto.id,
    updatingResources: subsystemFullDto.updatingResources,
  };

export const convertToSubsystemDto = (
  siteId: string,
  connectionData: ConnectionData,
  connectionPropertiesData: ConnectionPropertiesData
): SubsystemDto => ({
  name: connectionData.name,
  brandId: connectionData.brand.id,
  modelId: connectionData.model.id,
  siteId,
  connectionPropertiesDTO:
    Object.values(connectionPropertiesData)?.map((el) => {
      // eslint-disable-next-line
      const { value, ...connectionProperty } = el;
      return connectionProperty;
    }) || [],
});

export const convertToUpdateSubsystemDto = (
  connectionData: ConnectionData,
  connectionPropertiesData: ConnectionPropertiesData
): UpdateSubsystemDto => ({
  name: connectionData.name,
  connectionPropertiesDTO:
    Object.values(connectionPropertiesData)?.map((el) => {
      // eslint-disable-next-line
      const { value, ...connectionProperty } = el;
      return connectionProperty;
    }) || [],
});
