// General
import React from "react";
import { useI18n } from "compass-commons";
// Styles
import "../IncidentType.module.css";
// Components
import { Button, ResourceIcon } from "dms-lib";
import {
  IconButton,
  Box,
  Typography,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import {
  DeleteRounded,
  EditRounded,
  ExpandMoreRounded,
} from "@mui/icons-material";
// Models
import { IncidentTypeDto } from "../../../../../../models/incidents/IncidentTypeDto";
import IncidentTypeDrawerState from "../../../../../../models/incidents/IncidentTypeDrawerState";

export interface IncidentTypeSummaryProps {
  incidentType: IncidentTypeDto;
  toggleAccordion: () => void;
  expandToggle: boolean;
  configDrawerState: (s: IncidentTypeDrawerState, i?: IncidentTypeDto) => void;
  readOnly: boolean;
  deleteDisabled: boolean;
}

const IncidentTypeSummary = (props: IncidentTypeSummaryProps): JSX.Element => {
  const {
    incidentType,
    toggleAccordion,
    expandToggle,
    configDrawerState,
    readOnly,
    deleteDisabled,
  } = props;

  const { t: translate } = useI18n();
  const deleteButton = (
    <Button
      color="primary"
      variant="contained"
      size="small"
      disabled={deleteDisabled}
      onClick={() =>
        configDrawerState(IncidentTypeDrawerState.Delete, incidentType)
      }
      data-cr="incident-type-summary-delete-icon"
      icon
    >
      <DeleteRounded />
    </Button>
  );

  return (
    <AccordionSummary
      className={`config-incident-type__summary${expandToggle ? "__exp" : ""}`}
      data-cr="incident-type-summary"
      expandIcon={
        <Button
          className={`config-incident-type__summary__toggle-icon${
            expandToggle ? "__exp" : ""
          }`}
          data-cr="incident-type-summary-toggle-icon"
          variant="contained"
          color="primary"
          size="small"
          onClick={toggleAccordion}
          icon
        >
          <ExpandMoreRounded />
        </Button>
      }
    >
      <IconButton
        aria-label="icon"
        className="config-incident-type__summary__icon"
        data-cr="incident-type-summary-icon"
      >
        <ResourceIcon src={incidentType.iconPath} />
      </IconButton>
      <Typography
        className="config-incident-type__summary__title"
        data-cr="incident-type-summary-title"
      >
        {`${incidentType.name} (${
          incidentType.ruleCount ? incidentType.ruleCount : 0
        })`}
      </Typography>
      <Box
        className={`config-incident-type__summary__edit-delete${
          expandToggle ? "__exp" : ""
        }`}
        data-cr="incident-type-summary-edit-delete"
      >
        {!readOnly && (
          <>
            <Button
              data-cr="incident-type-summary-edit-icon"
              color="primary"
              variant="contained"
              size="small"
              icon
              onClick={() =>
                configDrawerState(IncidentTypeDrawerState.Edit, incidentType)
              }
            >
              <EditRounded />
            </Button>
            {deleteDisabled ? (
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "6.5rem",
                    },
                  },
                }}
                arrow
                title={translate("incidentTypes.delete.impossibleDelete")}
              >
                <span>{deleteButton}</span>
              </Tooltip>
            ) : (
              deleteButton
            )}
          </>
        )}
      </Box>
    </AccordionSummary>
  );
};

export default IncidentTypeSummary;
