import { string } from "zod";

const RuleStringConstraint = string()
  .min(1, {
    message: "behaviors.propertiesFieldsValidation.required",
  })
  .max(256, {
    message: "behaviors.propertiesFieldsValidation.maxSize",
  });

export default RuleStringConstraint;
