import { z, string } from "zod";

import { BehaviourDto } from "./BehaviourDto";
import { TriggerDtoSchema } from "./TriggerDto";

export const RuleDto = z.object({
  ruleId: string().optional(),
  displayName: string().optional(),
  siteId: string().optional(),
  behaviour: BehaviourDto,
  trigger: TriggerDtoSchema,
});

export type RuleDto = z.infer<typeof RuleDto>;
