// General
import React from "react";
import { useI18n } from "compass-commons";
import { useSelector } from "react-redux";
import { Button } from "dms-lib";
// Styles
import "./behaviourTestButton.module.css";
// Store
import { readOnlyMode } from "../../../../store/root";
import {
  selectIncidents,
  selectSites,
  useStoreDispatch,
} from "../../../../store";
import { incidentsActions } from "../../../../store/incidents";
// Components

interface BehaviourTestButtonProps {
  isValidData: boolean;
}

const BehaviourTestButton = ({
  isValidData,
}: BehaviourTestButtonProps): JSX.Element => {
  const { t: translate } = useI18n();
  const { testingRule, selectedRule } = useSelector(selectIncidents);
  const { selectedSiteId } = useSelector(selectSites);

  const isReadOnlyMode = useSelector(readOnlyMode);

  const dispatch = useStoreDispatch();

  const onClickHandler = () => {
    dispatch(
      selectedRule?.trigger.availableForOnDemand
        ? incidentsActions.testOnDemandRule({
            ruleId: selectedRule?.ruleId,
            siteId: selectedSiteId,
          })
        : incidentsActions.testRule({
            ruleId: selectedRule?.ruleId,
            siteId: selectedSiteId,
          })
    );
  };

  return (
    <div
      className="config-incidents__test-button compass-rounded-corner"
      data-cr="config-incidents-test-button"
    >
      <Button
        disabled={testingRule || !isReadOnlyMode || !isValidData}
        onClick={onClickHandler}
        color="primary"
        variant="contained"
      >
        {translate("behaviors.testBehavior")}
      </Button>
    </div>
  );
};

BehaviourTestButton.defaultProps = {};

export default BehaviourTestButton;
