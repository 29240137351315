import {
  DevicesResponse,
  FloorPlanDevice,
  FloorPlanImage,
  getUrl,
  SiteFloorPlan,
} from "compass-commons";
import { HttpClient } from "compass-shared-services";
// Models
import { FloorPlansDto } from "../models/floorPlans/FloorPlanDto";
// eslint-disable-next-line import/named

const { FLOOR_PLAN_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  GET_SITE_DEVICES: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/devices`,
  GET_FLOOR_PLAN: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/floor-plans/(floorPlanId)`,
  CREATE_OR_GET_FLOOR_PLAN: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/floor-plans`,
  UPDATE_FLOOR_PLAN: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/floor-plans/(floorPlanId)`,
  FLOOR_PLAN_IMAGE: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/floor-plans/(floorPlanId)/image`,
  GEO_DEVICE: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/georeferenced-map/devices`,
  GET_DEVICE_BY_RESOURCE_MAPPING_ID: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/devices/(resourceMappingId)`,
};

/**
 * Floor plan Service where backend service is called
 *
 */
export default class FloorPlanService {
  static abortController: AbortController;

  static async getFloorPlansBySiteId(
    siteId: string | number
  ): Promise<FloorPlansDto> {
    this.abortController?.abort();
    this.abortController = new AbortController();

    const uri = {
      siteId,
    };
    const urlPath = getUrl(URL_PATHS.CREATE_OR_GET_FLOOR_PLAN, uri);
    return httpClient
      .get<FloorPlansDto>({
        url: urlPath,
        config: {
          signal: this.abortController.signal,
        },
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getFloorPlanWithMedia(
    siteId: string,
    floorPlanId: string | number
  ): Promise<SiteFloorPlan> {
    const floorPlan: SiteFloorPlan = await this.getFloorPlanById(
      siteId,
      floorPlanId
    );

    if (floorPlan) {
      if (!floorPlan.floorPlanImage) {
        floorPlan.floorPlanImage = {} as FloorPlanImage;
      }
      floorPlan.floorPlanImage.content = await this.getFloorPlanImage(
        siteId,
        floorPlanId
      );
    }
    return floorPlan;
  }

  static async getFloorPlanById(
    siteId: string,
    floorPlanId: string | number
  ): Promise<SiteFloorPlan> {
    const uri = {
      siteId,
      floorPlanId,
    };
    const urlPath = getUrl(URL_PATHS.GET_FLOOR_PLAN, uri);
    return httpClient
      .get<SiteFloorPlan>({
        url: urlPath,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getFloorPlanImage(
    siteId: string,
    floorPlanId: string | number
  ): Promise<Blob> {
    const uri = {
      siteId,
      floorPlanId,
    };
    const urlPath = getUrl(URL_PATHS.FLOOR_PLAN_IMAGE, uri);
    return httpClient
      .get<Blob>({
        url: urlPath,
        config: {
          responseType: "blob",
        },
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        if (error) {
          throw Error(error);
        }
        return null;
      });
  }

  static async createFloorPlan(
    siteId: string,
    floorPlan: SiteFloorPlan
  ): Promise<SiteFloorPlan> {
    const uri = {
      siteId,
    };
    const urlPath = getUrl(URL_PATHS.CREATE_OR_GET_FLOOR_PLAN, uri);
    return httpClient
      .post<SiteFloorPlan>({
        url: urlPath,
        payload: floorPlan,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async updateFloorPlan(
    siteId: string,
    floorPlanId: string | number,
    floorPlan: SiteFloorPlan
  ): Promise<SiteFloorPlan> {
    const uri = {
      siteId,
      floorPlanId,
    };
    const urlPath = getUrl(URL_PATHS.UPDATE_FLOOR_PLAN, uri);
    return httpClient
      .put<SiteFloorPlan>({
        url: urlPath,
        payload: floorPlan,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async deleteFloorPlan(
    siteId: string,
    floorPlanId: string | number
  ): Promise<void> {
    const uri = {
      siteId,
      floorPlanId,
    };
    const urlPath = getUrl(URL_PATHS.UPDATE_FLOOR_PLAN, uri);
    return httpClient
      .delete<void>({
        url: urlPath,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async uploadFloorPlanImage(
    siteId: string,
    floorPlanId: string | number,
    floorPlanImage: FloorPlanImage
  ): Promise<void> {
    if (floorPlanImage?.content) {
      const uri = {
        siteId,
        floorPlanId,
      };
      const urlPath = getUrl(URL_PATHS.FLOOR_PLAN_IMAGE, uri);
      const imageBlob: Blob = floorPlanImage.content;
      const formData: FormData = new FormData();
      formData.append("floorPlanImage", imageBlob, floorPlanImage.name);
      const opts = { headers: { "Content-Type": "multipart/form-data" } };
      return httpClient
        .put<void>({
          url: urlPath,
          payload: formData,
          config: opts,
        })
        .then((response) => {
          return response;
        })
        .catch(async (error) => {
          throw Error(error);
        });
    }
    throw Error("No floor plan image provided.");
  }

  static async getResourcesBySite(
    siteId: string | number,
    pageIndex?: number,
    pageSize?: number,
    nameFilter?: string
  ): Promise<DevicesResponse> {
    const uri = {
      siteId,
    };
    const urlPath = getUrl(URL_PATHS.GET_SITE_DEVICES, uri);
    return httpClient
      .get<DevicesResponse>({
        url: urlPath,
        config: {
          params: {
            pageIndex,
            pageSize,
            queryValue: nameFilter,
          },
        },
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async updateGeoResources(
    siteId: string | number,
    devices: FloorPlanDevice[]
  ): Promise<DevicesResponse> {
    const uri = {
      siteId,
    };
    const urlPath = getUrl(URL_PATHS.GEO_DEVICE, uri);
    return httpClient
      .put<DevicesResponse>({
        url: urlPath,
        payload: { deviceList: devices } as DevicesResponse,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getDeviceByResourceMappingId(
    siteId: string,
    resourceMappingId: string
  ): Promise<FloorPlanDevice> {
    const uri = {
      siteId,
      resourceMappingId,
    };

    const urlPath = getUrl(URL_PATHS.GET_DEVICE_BY_RESOURCE_MAPPING_ID, uri);
    return httpClient
      .get<FloorPlanDevice>({
        url: urlPath,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getGeoReferencedResources(
    siteId: string
  ): Promise<DevicesResponse> {
    const uri = {
      siteId,
    };
    const urlPath = getUrl(URL_PATHS.GEO_DEVICE, uri);
    return httpClient
      .get<DevicesResponse>({
        url: urlPath,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
