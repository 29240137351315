// General
import React, { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "compass-commons";
// Styles
import "./siteTreeStyles.module.css";
// Components
import SiteNode from "./SiteNode";
import StateService from "../../services/StateService";
// Context
import { useStateContext } from "../../contexts/StateContext";
// Hooks
import useNavigateToSite from "../../hooks/useNavigateToSite";
import useSiteActions from "../../hooks/useSiteActions";
// Store
import { selectSites, useStoreDispatch } from "../../store";
import { sitesActions } from "../../store/sites";
import { readOnlyMode } from "../../store/root";

interface SiteTreeProps {
  siteId?: string;
}

/**
 * Component that represents the site tree.
 * A site tree is the component that will start the process of creating all the tree,
 * @param siteId - the site id. It can be null. If that's the case then the first site selected will be the root.
 * @returns JSX.Element
 */
const SiteTree = ({ siteId }: SiteTreeProps): JSX.Element => {
  const { t } = useI18n();

  // Rxjs
  const stateService: StateService = useStateContext();
  const { alertSubject } = stateService;
  // Redux State Management
  const isReadOnlyMode = useSelector(readOnlyMode);
  const { siteRoot, siteError } = useSelector(selectSites);
  const dispatch = useStoreDispatch();

  const navigateToSite = useNavigateToSite();

  // Tab Menu Actions Context
  const { cancelAction } = useSiteActions();

  const errorCallback = useCallback((msg: string) => {
    if (msg) {
      alertSubject.next({ title: msg });
    }
  }, []);

  //
  // LifeCycles
  //

  // Fetch Sites
  useEffect(() => {
    dispatch(sitesActions.getAll());
  }, []);

  useEffect(() => {
    if (!siteError) return;
    errorCallback(`${t(siteError.label)}`);
    if (!isReadOnlyMode) cancelAction();
  }, [siteError]);

  useEffect(() => {
    if (!siteRoot.data) return;
    navigateToSite(siteId, { disableNavigation: !!siteId });
  }, [siteRoot.data]);

  return (
    <div className="config-site__tree">
      {(siteRoot.data && (
        <SiteNode siteData={siteRoot.data} depth={0} ancestors={[]} />
      )) ||
        (siteRoot.loading && <div>{t("loadingContent")}</div>)}
    </div>
  );
};

SiteTree.defaultProps = {
  siteId: null,
};
export default SiteTree;
