// General
import React from "react";
// Components
import { TextField } from "@mui/material";
import { Button, Dialog, IntegrationIcon } from "dms-lib";
import { useI18n } from "compass-commons";
// Styles
import "./createEdgeDeviceModal.module.css";
import { Control, Controller } from "react-hook-form";
import {
  MAX_EDGE_DEVICE_NAME_LENGTH,
  NewEdgeDeviceDTO,
} from "../../../models/edgeDevices/NewEdgeDeviceDTO";
import { FormErrors } from "../../../models/edgeDevices/FormErrors";

interface CreateEdgeDeviceModalProps {
  control: Control<NewEdgeDeviceDTO, any>;
  errors: FormErrors<NewEdgeDeviceDTO>;
  isValid: boolean;
  open: boolean;
  onCancelCallback: () => void;
  onSaveCallback: () => void;
  saving: boolean;
}

const CreateEdgeDeviceModal = ({
  control,
  errors,
  isValid,
  open,
  onCancelCallback,
  onSaveCallback,
  saving,
}: CreateEdgeDeviceModalProps): JSX.Element => {
  const { t: translate } = useI18n();

  return (
    <Dialog
      id="create-edge-device-modal"
      open={open}
      size="xs"
      dialogContent={
        <>
          <div className="create-edge-device-modal-content-header">
            <IntegrationIcon width="46px" height="46px" />
            <span>{translate("edgeDevices.createEdgeDeviceModal.title")}</span>
          </div>
          <div className="create-edge-device-modal-content-body">
            <span className="dms-type-hint create-edge-device-modal-content-body-span">
              {translate("edgeDevices.createEdgeDeviceModal.inputLabel")}
            </span>
            <Controller
              name="name"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    className="create-edge-device-modal-content-body-input"
                    size="small"
                    inputProps={{ maxLength: MAX_EDGE_DEVICE_NAME_LENGTH + 1 }}
                    error={!!errors?.name}
                    helperText={
                      errors?.name
                        ? translate(
                            errors?.name?.message,
                            {
                              label: translate(
                                "edgeDevices.createEdgeDeviceModal.inputLabel"
                              ),
                            },
                            { ns: "Shared" }
                          )
                        : ""
                    }
                  />
                );
              }}
            />
          </div>
        </>
      }
      dialogActions={
        <>
          <Button
            dataCr="cancel-create-edge-device"
            color="primary"
            variant="contained"
            onClick={onCancelCallback}
            disabled={saving}
          >
            {translate("cancel", { ns: "Shared" })}
          </Button>
          <Button
            dataCr="create-edge-device"
            color="secondary"
            variant="outlined"
            onClick={onSaveCallback}
            disabled={saving || !isValid}
          >
            {translate("add", { ns: "Shared" })}
          </Button>
        </>
      }
    />
  );
};

export default CreateEdgeDeviceModal;
