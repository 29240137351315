import React from "react";
import { useI18n } from "compass-commons";

const RegisterInstructionsSteps = (): JSX.Element => {
  const { t: translate } = useI18n();
  const instructions = {
    step1: {
      stepDescription: "edgeDevices.registerInstructions.accessMEP",
      child: null,
    },
    step2: {
      stepDescription: "edgeDevices.registerInstructions.runCommand",
      child: <strong> {"register_device -n <token>"}</strong>,
    },
    step3: {
      stepDescription: "edgeDevices.registerInstructions.runCommand",
      child: <strong> init_configurator</strong>,
    },
    step4: {
      stepDescription: "edgeDevices.registerInstructions.checkConnectionStatus",
      child: <strong> cmep_status</strong>,
    },
  };

  return (
    <>
      {Object.keys(instructions).map((stepKey, index) => (
        <span key={stepKey}>
          {index + 1}. {translate(instructions[stepKey].stepDescription)}
          {instructions[stepKey].child}
        </span>
      ))}
    </>
  );
};

export default RegisterInstructionsSteps;
