// General
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "compass-commons";
// Components
import { Button } from "dms-lib";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AddEdgeDeviceIntegrationModal from "../../addEdgeDeviceIntegrationModal/AddEdgeDeviceIntegrationModal";
import IntegrationWasAddedModal from "../../integrationWasAddedModal/IntegrationWasAddedModal";
// Models
import { NewEdgeDeviceIntegrationDTO } from "../../../../models/edgeDevices/NewEdgeDeviceIntegrationDTO";
// Store
import { selectEdgeDevices, useStoreDispatch } from "../../../../store";
import { edgeDevicesActions } from "../../../../store/edgeDevices";

interface SelectedEdgeDeviceDetailsHeaderProps {
  edgeDeviceId: string;
}

const SelectedEdgeDeviceIntegrationsHeader = ({
  edgeDeviceId,
}: SelectedEdgeDeviceDetailsHeaderProps): JSX.Element => {
  const { t: translate } = useI18n();
  const dispatch = useStoreDispatch();
  const { loadEdgeDeviceIntegrationsCrud, integrationAddedSuccessfully } =
    useSelector(selectEdgeDevices);
  const [showAddIntegrationModal, setShowAddIntegrationModal] = useState(false);
  const [
    showIntegrationAddedSuccessfullyModal,
    setShowIntegrationAddedSuccessfullyModal,
  ] = useState(false);

  const onAddClick = () => {
    setShowAddIntegrationModal(true);
  };

  const handleCancelAddIntegration = () => {
    setShowAddIntegrationModal(false);
  };

  useEffect(() => {
    if (integrationAddedSuccessfully) {
      setShowIntegrationAddedSuccessfullyModal(true);
    }
  }, [integrationAddedSuccessfully]);

  const handleAddIntegration = (integration: NewEdgeDeviceIntegrationDTO) => {
    dispatch(
      edgeDevicesActions.addEdgeDeviceIntegration({
        edgeDeviceId,
        integrationDTO: integration,
      })
    ).then(() => setShowAddIntegrationModal(false));
  };

  const onCloseClick = () => {
    dispatch(edgeDevicesActions.selectEdgeDevice(null));
  };

  const handleIntegrationAddedConfirm = () => {
    setShowIntegrationAddedSuccessfullyModal(false);
    dispatch(edgeDevicesActions.setIntegrationAddedSuccessfully(null));
    dispatch(edgeDevicesActions.getEdgeDeviceIntegrations(edgeDeviceId));
  };

  return (
    <div className="selected-edge-device-details-panel-header">
      <span className="selected-edge-device-details-panel-header-title">
        {translate("edgeDevices.selectedEdgeDevice.integrations")}
      </span>
      <div className="selected-edge-device-details-panel-header-buttons">
        <Button
          dataCr="add-integration"
          color="primary"
          variant="contained"
          onClick={onAddClick}
          title={translate("add", { ns: "Shared" })}
          icon
        >
          <AddRoundedIcon />
        </Button>
        <Button
          dataCr="close-edge-device"
          color="primary"
          variant="contained"
          onClick={onCloseClick}
          title={translate("close", { ns: "Shared" })}
          icon
        >
          <CloseRoundedIcon />
        </Button>
      </div>
      <AddEdgeDeviceIntegrationModal
        open={showAddIntegrationModal}
        onCancelCallback={handleCancelAddIntegration}
        onSaveCallback={handleAddIntegration}
        saving={loadEdgeDeviceIntegrationsCrud}
      />
      <IntegrationWasAddedModal
        open={showIntegrationAddedSuccessfullyModal}
        onConfirmCallback={handleIntegrationAddedConfirm}
      />
    </div>
  );
};

export default SelectedEdgeDeviceIntegrationsHeader;
