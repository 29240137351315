import { Node, XYPosition } from "reactflow";
import { OgTaskTypeDto } from "../../../../models/ogTaskTypes/OgTaskTypeDto";

/* eslint-disable import/prefer-default-export */
const INITIAL = "initial";

function parsePosition(position: string): XYPosition {
  let pos;

  try {
    pos = JSON.parse(position || '{"x":0,"y":0}') as XYPosition;
  } catch (e) {
    pos = { x: 0, y: 0 } as XYPosition;
  }
  return pos;
}

export function newNode(
  data: OgTaskTypeDto,
  id?: string,
  position?: XYPosition
): Node {
  if (data) {
    return {
      id: id || data.id,
      type: "taskType",
      position: position || parsePosition(data.position),
      data,
    };
  }
  return null;
}

export const initialNode = (
  entryPointId: string,
  position: XYPosition
): Node => ({
  id: INITIAL,
  type: INITIAL,
  data: entryPointId,
  draggable: false,
  position,
});
