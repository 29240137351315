// General
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "compass-commons";
// Components
import { Skeleton } from "@mui/material";
import TemplatesListView from "./templatesListView/TemplatesListView";
import CollapsiblePanel from "../../commons/collapsiblePanel";
import OgDialogActions from "../components/ogDialog/ogDialogActions/OgDialogActions";
import CompassDialog from "../../commons/dialog";
import OgDialogContent from "../components/ogDialog/ogDialogContent/OgDialogContent";
// Styles
import "./ogTemplatePanel.module.css";
// Store
import { selectRoot, selectSites } from "../../../store";
import { ConfigEditionModes, ConfigModes } from "../../../store/root";
// Hooks
import useTabActions from "../../../hooks/useTabActions";

// Services
import OgTemplateService from "../../../services/OgTemplateService";
// Models
import { OgTemplateDto } from "../../../models/ogTemplate/OgTemplateDto";

interface OgTemplatePanelProps {
  notificationCallback?: (msg: string, severity?: string) => void;
  selectedOgTemplate?: OgTemplateDto;
  loadTemplates?: boolean;
  setLoadTemplates?: (loading: boolean) => void;
  setSelectedOgTemplate?: (ogTemplate: OgTemplateDto) => void;
}

const TEMPLATE_DEFAULT_NAME = "OG template ";

const OgTemplatePanel = (props: OgTemplatePanelProps): JSX.Element => {
  const {
    notificationCallback,
    selectedOgTemplate,
    loadTemplates,
    setLoadTemplates,
    setSelectedOgTemplate,
  } = props;
  const { t } = useI18n();
  const { setReadOnlyMode, setEditMode } = useTabActions();
  const { configMode } = useSelector(selectRoot);
  const { configEditMode } = useSelector(selectRoot);
  const { selectedSiteId } = useSelector(selectSites);

  const [ogTemplates, setOgTemplates] = useState<OgTemplateDto[]>([]);
  const [filteredOgTemplates, setFilteredOgTemplates] =
    useState<OgTemplateDto[]>(null);
  const [createdOgTemplate, setCreatedOgTemplate] = useState<string>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const findOriginalTemplate = (templates, templateId: string) => {
    return templates.find((template) => template.id === templateId);
  };

  const getOgTemplates = async () => {
    try {
      if (!selectedSiteId) return;
      let templatesRes = await OgTemplateService.getAllOgTemplates(
        selectedSiteId
      );
      if (templatesRes) {
        templatesRes = templatesRes.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        const selectedId: string =
          createdOgTemplate ||
          (selectedOgTemplate ? selectedOgTemplate.id : undefined);
        let updatedOgTemplate: OgTemplateDto = null;
        if (selectedId) {
          updatedOgTemplate = findOriginalTemplate(templatesRes, selectedId);
          if (updatedOgTemplate)
            setSelectedOgTemplate(
              JSON.parse(JSON.stringify(updatedOgTemplate))
            );
        }
        if (!updatedOgTemplate && templatesRes.length > 0) {
          setSelectedOgTemplate(JSON.parse(JSON.stringify(templatesRes[0])));
        }
        setOgTemplates(templatesRes);
        setCreatedOgTemplate(null);
        setFilteredOgTemplates(templatesRes);
      }
    } catch (error) {
      setOgTemplates([]);
      setSelectedOgTemplate(null);
      setFilteredOgTemplates(null);
      notificationCallback(t("operatorGuide.failToLoadOgs"));
    }
  };

  const createNewTemplate = () => {
    setReadOnlyMode();
    const newTemplate = {} as OgTemplateDto;
    newTemplate.siteId = selectedSiteId;
    newTemplate.name = `${TEMPLATE_DEFAULT_NAME}${
      ogTemplates ? ogTemplates.length + 1 : 1
    }`;
    OgTemplateService.createTemplate(newTemplate)
      .then((res) => {
        setCreatedOgTemplate(res?.id);
        setLoadTemplates(true);
        setEditMode();
        notificationCallback(t("operatorGuide.successCreatedOg"), "success");
      })
      .catch(() => {
        notificationCallback(t("operatorGuide.failCreateOg"));
      });
  };

  useEffect(() => {
    if (selectedSiteId) {
      setReadOnlyMode();
      setSelectedOgTemplate(null);
      setLoadTemplates(true);
    }
  }, [selectedSiteId]);

  const searchCallback = useCallback(
    (filter: string) => {
      if (filter.length > 0) {
        const filteredTemplates = ogTemplates?.filter((s) => {
          return s?.name?.toLowerCase().includes(filter.toLowerCase());
        });
        setFilteredOgTemplates(
          filteredTemplates.length > 0 ? filteredTemplates : null
        );
      } else {
        setFilteredOgTemplates(ogTemplates);
      }
    },
    [filteredOgTemplates, ogTemplates]
  );

  useEffect(() => {
    if (loadTemplates) {
      getOgTemplates().then(() => {
        setLoadTemplates(false);
      });
    }
  }, [loadTemplates, selectedSiteId]);

  const handleCancel = () => {
    setSelectedOgTemplate(
      findOriginalTemplate(ogTemplates, selectedOgTemplate.id)
    );
    setReadOnlyMode();
  };

  const handleSave = () => {
    if (
      selectedOgTemplate?.name.length === 0 ||
      selectedOgTemplate?.name.length > 50
    ) {
      notificationCallback(
        selectedOgTemplate?.name.length === 0
          ? t("operatorGuide.failOgNameEmpty")
          : t("operatorGuide.failOgNameTooLong")
      );
      setReadOnlyMode();
      setEditMode();
      return;
    }
    OgTemplateService.updateTemplate(selectedOgTemplate)
      .then(() => {
        setLoadTemplates(true);
        setReadOnlyMode();
        notificationCallback(t("operatorGuide.successUpdatedOg"), "success");
      })
      .catch(() => {
        setReadOnlyMode();
        setEditMode();
        notificationCallback(t("operatorGuide.failUpdateOg"));
      });
  };

  const handleDelete = () => {
    setReadOnlyMode();
    setOpenDeleteModal(true);
  };

  const strategyConfigMode = Object.freeze({
    [ConfigModes.CREATE]: createNewTemplate,
    [ConfigModes.DELETE]: handleDelete,
  });

  const strategyConfigEditionMode = Object.freeze({
    [ConfigEditionModes.CANCEL]: handleCancel,
    [ConfigEditionModes.SAVE]: handleSave,
  });

  useEffect(() => {
    strategyConfigEditionMode[configEditMode]?.();
  }, [configEditMode]);

  useEffect(() => {
    strategyConfigMode[configMode]?.();
  }, [configMode]);

  const toggleModal = useCallback((event?: any, forceState?: boolean) => {
    if (event) event.stopPropagation();
    setOpenDeleteModal((prevState: boolean) => forceState ?? !prevState);
  }, []);

  const deleteOgClean = (event) => {
    toggleModal(event, false);
  };

  const removeItem = () => {
    if (selectedOgTemplate) {
      OgTemplateService.deleteTemplateById(selectedOgTemplate.id)
        .then(() => {
          setSelectedOgTemplate(null);
          setLoadTemplates(true);
          toggleModal(null, false);
          notificationCallback(t("operatorGuide.successDeleteOg"), "success");
        })
        .catch(() => {
          toggleModal(null, false);
          notificationCallback(t("operatorGuide.failDeleteOg"));
        });
    }
  };

  const isLoadingComponent = () => {
    return (
      <div id="task-types-loading" key={2}>
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
      </div>
    );
  };

  return (
    <CollapsiblePanel
      data-cr="og-template-panel"
      searchBar={{
        dataCr: "search-in-templates",
        searchCallback,
        placeholderLabel: t("operatorGuide.search"),
      }}
    >
      {loadTemplates && isLoadingComponent()}
      {!loadTemplates && (
        <TemplatesListView
          templates={filteredOgTemplates}
          selectedOgTemplate={selectedOgTemplate}
          setSelectedOgTemplate={setSelectedOgTemplate}
        />
      )}
      <CompassDialog
        onClose={deleteOgClean}
        dialogState={openDeleteModal}
        dialogContent={<OgDialogContent ogName={selectedOgTemplate?.name} />}
        dialogActions={
          <OgDialogActions onCancel={deleteOgClean} onSuccess={removeItem} />
        }
      />
    </CollapsiblePanel>
  );
};

OgTemplatePanel.defaultProps = {
  notificationCallback: undefined,
  selectedOgTemplate: null,
  loadTemplates: false,
  setLoadTemplates: () => {},
  setSelectedOgTemplate: () => {},
};

export default OgTemplatePanel;
