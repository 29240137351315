// General
import React from "react";
import { useI18n } from "compass-commons";
import { Control, Controller } from "react-hook-form";
// Styles
import "../behaviourProperties.module.css";
// Components
import { IncidentIcon } from "dms-lib";
// Models
import { RuleDto } from "../../../../../models/incidents/RuleDto";

interface BehaviourPropertiesIncidentPreviewProps {
  control: Control<RuleDto, any>;
  incidentTypeIconPath: string;
  dataCr?: string;
}

const BehaviourPropertiesIncidentPreview = (
  props: BehaviourPropertiesIncidentPreviewProps
): JSX.Element => {
  const { t: translate } = useI18n();
  const { incidentTypeIconPath, dataCr, control } = props;

  return (
    <div className="config-incidents__properties__input" data-cr={dataCr}>
      <span>{translate("behaviors.propertyFieldsDescription.preview")}:</span>
      <Controller
        name="behaviour.incidentPriority"
        control={control}
        render={({ field: { value } }) => {
          return (
            <div className="config-incidents__properties__incident-preview">
              <Controller
                name="behaviour.incidentTypeIconPath"
                control={control}
                render={({ field: { value: iconPath } }) => {
                  return (
                    <IncidentIcon
                      data-cr={`${dataCr}-image`}
                      className="config-incidents__properties__incident-preview__icon"
                      src={iconPath ?? incidentTypeIconPath}
                      priority={value}
                      size="extra-large"
                    />
                  );
                }}
              />

              <Controller
                name="behaviour.description"
                control={control}
                render={({ field: { value: description } }) => {
                  return <span>{description}</span>;
                }}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

BehaviourPropertiesIncidentPreview.defaultProps = {
  dataCr: "behaviour-properties-preview",
};

export default BehaviourPropertiesIncidentPreview;
