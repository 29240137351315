// General
import React from "react";
import { useI18n } from "compass-commons";
// Components
import AssignmentLateRoundedIcon from "@mui/icons-material/AssignmentLateRounded";
// Styles
import "./edgeDeviceStatus.module.css";

const EdgeDeviceStatusUnregisteredIcon = (): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <AssignmentLateRoundedIcon
      className="edge-device-status-unregistered"
      titleAccess={translate(`edgeDevices.statusList.unregistered`)}
    />
  );
};

export default EdgeDeviceStatusUnregisteredIcon;
