// General
import { createAsyncThunk } from "@reduxjs/toolkit";

function createExtraActions() {
  const addTemplateStub = () => {
    return createAsyncThunk(
      `operatorGuide/addTemplate`,
      async (params?: string) => {
        return params;
      }
    );
  };
  const removeTemplateStub = () => {
    return createAsyncThunk(
      `operatorGuide/removeTemplate`,
      async (params?: string) => {
        return params;
      }
    );
  };
  const setIsEditingTaskText = () => {
    return createAsyncThunk(
      `operatorGuide/editingTaskText`,
      async (params?: boolean) => {
        return params;
      }
    );
  };
  return {
    addTemplateStub: addTemplateStub(),
    removeTemplateStub: removeTemplateStub(),
    setIsEditingTaskText: setIsEditingTaskText(),
  };
}

const operatorGuideExtraActions = createExtraActions();

export default operatorGuideExtraActions;
