// General
import React from "react";
// Components
import SelectedEdgeDeviceDetailsHeader from "./SelectedEdgeDeviceDetailsHeader";
import SelectedEdgeDeviceDetailsBody from "./SelectedEdgeDeviceDetailsBody";
// Models
import { EdgeDeviceDTO } from "../../../../models/edgeDevices/EdgeDeviceDTO";
// Styles
import "./selectedEdgeDeviceDetails.module.css";

interface SelectedEdgeDeviceDetailsProps {
  edgeDevice: EdgeDeviceDTO;
}

const SelectedEdgeDeviceDetails = ({
  edgeDevice,
}: SelectedEdgeDeviceDetailsProps): JSX.Element => {
  return (
    <section className="selected-edge-device-details-panel">
      <SelectedEdgeDeviceDetailsHeader
        edgeDeviceId={edgeDevice.id}
        edgeDeviceName={edgeDevice.name}
      />
      <SelectedEdgeDeviceDetailsBody
        edgeDeviceId={edgeDevice.id}
        edgeDeviceName={edgeDevice.name}
        edgeDeviceStatus={edgeDevice.status}
        editMode={false}
      />
    </section>
  );
};

export default SelectedEdgeDeviceDetails;
