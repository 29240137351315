// General
import React from "react";
import { Control, Controller } from "react-hook-form";
import * as z from "zod";
import { useI18n } from "compass-commons";
// Styles
import "../../subsystemConnectionDataPanel.module.css";
// Components
import { AutoComplete, OptionType } from "dms-lib";
import ConnectionPropertyHeader from "../connectionPropertyHeader";
// Model
import {
  ConnectionPropertyTemplateDto,
  ListBoxDto,
} from "../../../../../../../../models/subsystems/ConnectionPropertyTemplateDto";
import { FormErrors } from "../../../../models/SubsystemConnectionDataTypes";
import { ConnectionPropertyType } from "../../../../../../../../models/subsystems/ConnectionPropertyType";
import { ConnectionPropertiesData } from "../../../../../../../../models/subsystems/SubsystemDto";
// Utils
import { compassOptional } from "../../../../../../../../utils/Validations";

interface ConnectionPropertyListBoxOption extends OptionType {
  id: string;
  name: string;
}

interface ConnectionPropertyListBoxProps {
  dataCr: string;
  connectionPropertyListBox: ConnectionPropertyTemplateDto;
  readOnlyMode: boolean;
  control: Control<ConnectionPropertiesData, any>;
  errors?: FormErrors<ConnectionPropertiesData>;
}

const ConnectionPropertyListBox = ({
  dataCr,
  connectionPropertyListBox,
  readOnlyMode,
  control,
  errors,
}: ConnectionPropertyListBoxProps): JSX.Element => {
  const { t } = useI18n();

  const EMPTY_DEFAULT_VALUE = {
    id: "",
    propertyType: ConnectionPropertyType.LIST_BOX,
    value: "",
    connectionPropertyListBox: "",
  };

  const baseSchema = z.string();

  const requiredSchema = baseSchema.min(1, {
    message: t("subsystems.propertiesFieldsValidation.required", {
      label: connectionPropertyListBox.label,
    }),
  });

  const composedSchema = z.object({
    connectionPropertyListBox: compassOptional(
      baseSchema,
      requiredSchema,
      !connectionPropertyListBox.required
    ),
  });

  const listBoxDto = connectionPropertyListBox.connectionPropertyData
    .data as ListBoxDto;

  const listBoxOptions: ConnectionPropertyListBoxOption[] = Object.entries(
    listBoxDto.options
  ).map(([optionKey, optionValue]) => {
    return {
      id: optionKey,
      name: optionValue,
      propertyType: ConnectionPropertyType.LIST_BOX,
      propertyId: connectionPropertyListBox.id,
    };
  });

  function optionToConnectionPropertyListboxMapper(
    selectedListBox?: ConnectionPropertyListBoxOption
  ) {
    return selectedListBox
      ? {
          id: connectionPropertyListBox.id,
          propertyType: connectionPropertyListBox.propertyType,
          value: selectedListBox.name,
          connectionPropertyListBox: selectedListBox.id,
        }
      : EMPTY_DEFAULT_VALUE;
  }

  return (
    <div className="config-subsystem-connection-data__field-wrapper--width">
      <ConnectionPropertyHeader
        dataCr={dataCr}
        label={connectionPropertyListBox.label}
        required={connectionPropertyListBox.required}
      />
      <div>
        <Controller
          name={connectionPropertyListBox.label}
          rules={{
            validate: (value) =>
              composedSchema
                .parseAsync(value)
                .then((result) => !!result)
                .catch((e: z.ZodError) => e.issues[0].message),
          }}
          control={control}
          defaultValue={optionToConnectionPropertyListboxMapper(
            listBoxOptions.find((lb) => {
              return lb.id === listBoxDto.value;
            })
          )}
          render={({ field: { onChange, ...field } }) => {
            return (
              <AutoComplete<ConnectionPropertyListBoxOption>
                {...field}
                value={{
                  id: field.value.connectionPropertyListBox,
                  name: field.value.value,
                }}
                className="field-width"
                dataCr={dataCr}
                options={listBoxOptions}
                getOptionLabel={(option) => option.name}
                id={`property-id-${connectionPropertyListBox.id}`}
                disabled={readOnlyMode}
                placeholder={t("subsystems.connectionData.listBoxPlaceholder")}
                onChangeCallback={(
                  selectedListBox: ConnectionPropertyListBoxOption
                ) => {
                  // convertion to backend endpoint model
                  onChange(
                    optionToConnectionPropertyListboxMapper(selectedListBox)
                  );
                }}
                error={!!errors?.[connectionPropertyListBox.label]}
                errorMessage={errors?.[
                  connectionPropertyListBox.label
                ]?.message.toString()}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

ConnectionPropertyListBox.defaultProps = {
  errors: undefined,
};

export default ConnectionPropertyListBox;
