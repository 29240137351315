// General
import React, { useRef, useState } from "react";
import { useI18n } from "compass-commons";
// Components
import { IconButton } from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { Pagination } from "dms-lib";
import ColorLegendContextMenu from "./ColorLegendContextMenu";
// Styles
import "./edgeDevicesPanel.module.css";

interface EdgeDevicesPanelFooterProps {
  currentPage: number;
  totalPages: number;
  onPageChangedCallback: (newPage: number) => void;
}

const EdgeDevicesPanelFooter = ({
  currentPage,
  totalPages,
  onPageChangedCallback,
}: EdgeDevicesPanelFooterProps): JSX.Element => {
  const { t: translate } = useI18n();
  const [showColorLegendMenu, setShowColorLegendMenu] = useState(false);
  const colorLegendButton = useRef(null);
  const onColorLegendClick = () => {
    setShowColorLegendMenu(true);
  };

  const handleCloseColorLegendMenu = () => {
    setShowColorLegendMenu(false);
  };

  return (
    <div className="edge-devices-panel-footer-div">
      <div className="color-legend">
        <span className="edge-devices-panel-color-legend-span">
          {translate("edgeDevices.colorLegend")}
        </span>
        <IconButton
          ref={colorLegendButton}
          onClick={onColorLegendClick}
          size="small"
          className={showColorLegendMenu ? "color-legend-button-active" : ""}
        >
          <ArrowDropDownRoundedIcon />
        </IconButton>
        {showColorLegendMenu && (
          <ColorLegendContextMenu
            open={showColorLegendMenu}
            handleClose={handleCloseColorLegendMenu}
            element={colorLegendButton.current}
          />
        )}
      </div>
      <Pagination
        id="edge-devices-pagination"
        dataCr="edge-devices-pagination"
        className="edge-devices-pagination"
        onPageChangedCallback={onPageChangedCallback}
        totalPages={totalPages}
        currentPage={currentPage}
      />
      <div className="dms-col-flex dms-col-width--33" />
    </div>
  );
};

export default EdgeDevicesPanelFooter;
