// General
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useFeatureFlag, useI18n } from "compass-commons";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
// Styles
import "./behavioursConfiguration.module.css";
// Components
import BehaviourListPanel from "./components/behaviourListPanel/BehaviourListPanel";
import BehaviourTriggerPanel from "./components/behaviourTriggers/BehaviourTriggerPanel";
import BehaviourPropertyPanel from "./components/behaviourProperties/BehaviourProperties";
import CompassDialog from "../commons/dialog";
import BehaviourDialogContent from "./components/behaviourDialog/behaviourDialogContent/BehaviourDialogContent";
import BehaviourDialogActions from "./components/behaviourDialog/behaviourDialogActions/BehaviourDialogActions";
import BehaviourTestButton from "./components/behaviourTestButton/BehaviourTestButton";
// Context
import { useStateContext } from "../../contexts/StateContext";
import { useTabActionsContext } from "../../contexts/TabActionsContext";
// Services
import StateService from "../../services/StateService";
// Store
import {
  selectIncidents,
  selectRoot,
  selectSites,
  useStoreDispatch,
} from "../../store";
import {
  ConfigEditionModes,
  ConfigModes,
  creationMode,
  editionMode,
  rootActions,
} from "../../store/root";
import {
  incidentsActions,
  selectIsPlaceholderRule,
} from "../../store/incidents";
// Models
import { RuleDto } from "../../models/incidents/RuleDto";
import { RuleLightDto } from "../../models/incidents/RuleLightDto";
import {
  FormRuleDto,
  FormRuleDtoSchema,
} from "../../models/incidents/form/FormRuleDto";
// Utils
import {
  NEW_RULE_ID_PLACEHOLDER,
  NEW_RULE_NAME_PLACEHOLDER,
} from "./utils/Settings";
import useTabActions from "../../hooks/useTabActions";
import { getFormDefaultValue } from "./utils/FormValues";
import {
  GROUPING_BY_ACTIVATION_FEATURE_FLAG,
  ON_DEMAND_INCIDENT_FEATURE_FLAG,
} from "../../utils/Constants";

/**
 * Wrapper Layout for the Incident Configurator panel
 * This panel is related to incidents configuration
 * @returns JSX.Element
 */
const BehavioursConfigurator = (): JSX.Element => {
  const { t: translate } = useI18n();

  const NEW_RULE_PLACEHOLDER: RuleLightDto = {
    ruleId: NEW_RULE_ID_PLACEHOLDER,
    displayName: translate(NEW_RULE_NAME_PLACEHOLDER),
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // Rxjs
  const stateService: StateService = useStateContext();
  const { alertSubject } = stateService;

  const errorCallback = useCallback((msg: string) => {
    if (msg) {
      alertSubject.next({ title: msg });
    }
  }, []);

  // ACTIONS Handlers
  const {
    setConfigMainButtons,
    setEditActionsAllowed,
    setExtraResetHandler,
    setDisableAllButtons,
    setActivateAll,
  } = useTabActionsContext();

  // Redux State Management
  const {
    incidentsError,
    filteredRulesList,
    selectedRule,
    loadingSelectedRule,
    ruleCrudLoading,
  } = useSelector(selectIncidents);
  const isNewRule = useSelector(selectIsPlaceholderRule);
  const { configMode, configEditMode } = useSelector(selectRoot);
  const { selectedSiteId } = useSelector(selectSites);
  const isCreationMode = useSelector(creationMode);
  const isEditionMode = useSelector(editionMode);
  const readonly = !isCreationMode && !isEditionMode;
  const { setReadOnlyMode } = useTabActions();
  const dispatch = useStoreDispatch();

  const currentFilteredList = useMemo(
    () => [
      ...filteredRulesList,
      ...(isCreationMode ? [NEW_RULE_PLACEHOLDER] : []),
    ],
    [filteredRulesList, isCreationMode, selectedRule]
  );

  const {
    reset: resetAll,
    control: controlData,
    formState: { errors: errorsData, isValid: isValidData },
    setValue: setFieldValue,
    watch: watchField,
    getValues,
    handleSubmit,
    resetField,
    trigger,
  } = useForm<FormRuleDto>({
    mode: "all",
    resolver: zodResolver(FormRuleDtoSchema), // We can use the dto itself to perform validation
    defaultValues: {
      ...getFormDefaultValue(selectedRule, selectedSiteId, translate),
    },
  });

  const { enabled: enabledOnDemandFeature } = useFeatureFlag(
    appConfig,
    ON_DEMAND_INCIDENT_FEATURE_FLAG
  );

  const { enabled: groupingByActivationFeature } = useFeatureFlag(
    appConfig,
    GROUPING_BY_ACTIVATION_FEATURE_FLAG
  );

  const showAggregationMethod =
    groupingByActivationFeature &&
    !(enabledOnDemandFeature && watchField("trigger.availableForOnDemand"));

  //
  // MODAL ACTIONS
  //

  /**
   * Action responsible to update the modal status
   */
  // eslint-disable-next-line
  const toggleModal = useCallback((event?: any, forceState?: boolean) => {
    if (event) event.stopPropagation();
    setOpenDeleteModal((prevState: boolean) => forceState ?? !prevState);
  }, []);

  const deleteBehaviourSuccess = async () => {
    toggleModal(null, false);
    dispatch(
      incidentsActions.deleteRule({
        ruleId: selectedRule?.ruleId,
        siteId: selectedSiteId,
      })
    );
    setReadOnlyMode();
  };

  const deleteBehaviourClean = (event) => {
    toggleModal(event, false);
    setReadOnlyMode();
  };

  const handleDelete = () => {
    if (selectedRule?.ruleId) toggleModal(null, true);
  };

  const handleCreate = () => {
    dispatch(incidentsActions.ruleSelected(NEW_RULE_PLACEHOLDER));
  };

  const handleCancel = (
    shouldResetSelectedRule = false,
    newRuleSelected = true
  ) => {
    resetAll();
    if (shouldResetSelectedRule) dispatch(incidentsActions.ruleSelected(null));
    if (newRuleSelected) setReadOnlyMode();
  };

  const handleSave = async () => {
    try {
      const behaviourDto: RuleDto = RuleDto.parse(getValues());

      if (isCreationMode) {
        const createdRuleId = await dispatch(
          incidentsActions.createRule(behaviourDto)
        ).unwrap();
        dispatch(incidentsActions.getRuleById(createdRuleId));
      } else {
        await dispatch(incidentsActions.updateRule(behaviourDto));
        dispatch(incidentsActions.getRuleById(behaviourDto?.ruleId));
      }
      dispatch(rootActions.activateReadOnlyMode(true));
    } catch (error) {
      dispatch(rootActions.activateEditReadOnlyMode());
    }
  };

  const handleInvalidSave = async () => {
    dispatch(rootActions.activateEditReadOnlyMode());
  };

  const strategyConfigMode = Object.freeze({
    [ConfigModes.CREATE]: handleCreate,
    [ConfigModes.DELETE]: handleDelete,
  });

  const strategyConfigEditionMode = Object.freeze({
    [ConfigEditionModes.CANCEL]: () => handleCancel(true),
    [ConfigEditionModes.SAVE]: handleSubmit(handleSave, handleInvalidSave),
  });

  /**
   * Activate Specific tab buttons
   */
  const initTabActionsConfig = () => {
    setConfigMainButtons({
      showCreateButton: true,
      showEditButton: !!selectedRule,
      showDeleteButton: !!selectedRule,
    });
    setActivateAll(false);
    setEditActionsAllowed(true);
    setExtraResetHandler(() => dispatch(incidentsActions.ruleSelected(null)));
  };

  useEffect(() => {
    resetAll({
      ...getFormDefaultValue(selectedRule, selectedSiteId, translate),
    });
    initTabActionsConfig();
    if (!isNewRule) {
      trigger();
    }
    return () => {
      handleCancel(false, isNewRule || isEditionMode);
    };
  }, [selectedRule, isNewRule, isEditionMode]);

  useEffect(() => {
    handleCancel(true);
    if (selectedSiteId) {
      dispatch(incidentsActions.getAllRules(selectedSiteId));
    }
  }, [selectedSiteId]);

  useEffect(() => {
    if (!incidentsError) return;
    if (!incidentsError.parameters) {
      errorCallback(`${translate(incidentsError.label)}`);
    } else {
      errorCallback(
        `${translate(incidentsError.label, incidentsError.parameters)}`
      );
    }
  }, [incidentsError]);

  useEffect(() => {
    strategyConfigEditionMode[configEditMode]?.();
    if (configEditMode === ConfigEditionModes.SAVE) {
      if (isValidData) {
        handleSubmit(handleSave, handleInvalidSave);
      }
    }
  }, [configEditMode]);

  // Incidents Button Actions Initialization
  useEffect(() => {
    strategyConfigMode[configMode]?.();
  }, [configMode]);

  useEffect(() => {
    setDisableAllButtons(ruleCrudLoading);
  }, [ruleCrudLoading]);

  return (
    <>
      <div data-cr="config-incident-panel" className="config-incidents">
        <BehaviourListPanel
          selectedSiteId={selectedSiteId}
          selectedRule={selectedRule}
          filteredRuleList={currentFilteredList}
        />
        {selectedRule && !ruleCrudLoading ? (
          <>
            <article className="config-incidents__panel config-incidents__trigger-panel__wrapper compass-rounded-corner">
              <BehaviourTriggerPanel
                ruleId={selectedRule.ruleId}
                selectedTrigger={selectedRule?.trigger}
                selectedSiteId={selectedSiteId}
                readOnlyMode={readonly}
                control={controlData}
                errors={errorsData}
                reset={resetAll}
                setFieldValue={setFieldValue}
                resetField={resetField}
                watchField={watchField}
                subsystemId={getValues("trigger.eventTypeSubsystemId")}
                enabledOnDemandFeature={enabledOnDemandFeature}
              />
            </article>
            <div className="config-incidents__properties-panel__wrapper compass-rounded-corner">
              <article className="config-incidents__properties-panel__main compass-rounded-corner">
                <BehaviourPropertyPanel
                  ruleId={selectedRule.ruleId}
                  selectedBehaviour={selectedRule?.behaviour}
                  selectedSiteId={selectedSiteId}
                  readOnlyMode={readonly}
                  control={controlData}
                  errors={errorsData}
                  reset={resetAll}
                  setFieldValue={setFieldValue}
                  resetField={resetField}
                  showAggregationMethod={showAggregationMethod}
                />
              </article>
              <BehaviourTestButton isValidData={isValidData} />
            </div>
          </>
        ) : (
          <article className="config-incidents__panel config-incidents__empty-panel compass-rounded-corner">
            {loadingSelectedRule || ruleCrudLoading ? (
              <div>{translate("loadingContent")}</div>
            ) : (
              <div>{translate("behaviors.noBehaviorSelected")}</div>
            )}
          </article>
        )}
      </div>
      <CompassDialog
        onClose={deleteBehaviourClean}
        dialogState={openDeleteModal}
        dialogContent={<BehaviourDialogContent />}
        dialogActions={
          <BehaviourDialogActions
            onCancel={deleteBehaviourClean}
            onSuccess={deleteBehaviourSuccess}
          />
        }
      />
    </>
  );
};

export default BehavioursConfigurator;
