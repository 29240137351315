// Generic
import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { DevicesResponse, FloorPlanDevice } from "compass-commons";
// Models
import { ResourceState } from "..";
// Actions
import resourcesExtraActions from "../extraActions";
// Utils
import { DEFAULT_PAGE_SIZE } from "../../../components/resourceConfigurator/utils/constants";

/* eslint-disable no-param-reassign, @typescript-eslint/no-explicit-any */

const resourcesGetAllReducer = (
  builder: ActionReducerMapBuilder<ResourceState>
) => {
  builder
    .addCase(
      resourcesExtraActions.getAllResources.pending,
      (state: ResourceState) => {
        state.selectedResource = null;
        state.loadingResourcesList = true;
        state.resourcesError = null;
      }
    )
    .addCase(
      resourcesExtraActions.getAllResources.fulfilled,
      (state: ResourceState, action: PayloadAction<DevicesResponse>) => {
        if (action.payload?.deviceList) {
          state.resourcesList = action.payload?.deviceList;
          state.resourcesError = null;
          state.isResourceListLastPage =
            action.payload.deviceList.length < DEFAULT_PAGE_SIZE;
        } else {
          state.resourcesError = {
            label: "devices.failToLoadSiteResources",
            message: null,
          };
        }
        state.loadingResourcesList = false;
      }
    )
    .addCase(
      resourcesExtraActions.getAllResources.rejected,
      (state: ResourceState, action) => {
        state.loadingResourcesList = false;
        state.resourcesError = {
          label: "devices.failToLoadSiteResources",
          message: action.error.message,
        };
      }
    );
};

const resourcesGetNextBatchReducer = (
  builder: ActionReducerMapBuilder<ResourceState>
) => {
  builder
    .addCase(
      resourcesExtraActions.getNextResources.pending,
      (state: ResourceState) => {
        state.selectedResource = null;
        state.loadingNextResourcesList = true;
        state.resourcesError = null;
      }
    )
    .addCase(
      resourcesExtraActions.getNextResources.fulfilled,
      (state: ResourceState, action: PayloadAction<DevicesResponse>) => {
        if (action.payload?.deviceList) {
          state.resourcesList =
            state.resourcesList?.length > 0
              ? state.resourcesList.concat(action.payload.deviceList)
              : [...action.payload.deviceList];
          state.isResourceListLastPage =
            action.payload.deviceList.length < DEFAULT_PAGE_SIZE;
          state.resourcesError = null;
        } else {
          state.resourcesError = {
            label: "devices.failToLoadSiteResources",
            message: null,
          };
        }
        state.loadingNextResourcesList = false;
      }
    )
    .addCase(
      resourcesExtraActions.getNextResources.rejected,
      (state: ResourceState, action) => {
        state.loadingNextResourcesList = false;
        state.resourcesError = {
          label: "devices.failToLoadSiteResources",
          message: action.error.message,
        };
      }
    );
};

const resourcesUpdateGeoReducer = (
  builder: ActionReducerMapBuilder<ResourceState>
) => {
  builder
    .addCase(
      resourcesExtraActions.updateGeoResources.pending,
      (state: ResourceState) => {
        state.resourceCrudLoading = true;
        state.resourcesError = null;
      }
    )
    .addCase(
      resourcesExtraActions.updateGeoResources.fulfilled,
      (state: ResourceState) => {
        state.resourceCrudLoading = false;
        state.resourcesError = null;
      }
    )
    .addCase(
      resourcesExtraActions.updateGeoResources.rejected,
      (state: ResourceState, action) => {
        state.resourcesError = {
          label: "devices.failUpdatePosition",
          message: action.error.message,
        };
        state.resourceCrudLoading = false;
      }
    );
};

const resourcesGetResourceByResourceMappingId = (
  builder: ActionReducerMapBuilder<ResourceState>
) => {
  builder
    .addCase(
      resourcesExtraActions.getResourceByResourceMappingId.pending,
      (state: ResourceState) => {
        state.loadingSelectedResource = true;
        state.resourcesError = null;
      }
    )
    .addCase(
      resourcesExtraActions.getResourceByResourceMappingId.fulfilled,
      (state: ResourceState, action: PayloadAction<FloorPlanDevice>) => {
        state.selectedResource = action?.payload;
        state.loadingSelectedResource = false;
        state.resourcesError = null;
      }
    )
    .addCase(
      resourcesExtraActions.getResourceByResourceMappingId.rejected,
      (state: ResourceState, action) => {
        state.selectedResource = null;
        state.resourcesError = {
          label: "devices.errorLoadingDevice",
          message: action.error.message,
        };
        state.loadingSelectedResource = false;
      }
    );
};

const resourcesUpdateFloorPlanReducer = (
  builder: ActionReducerMapBuilder<ResourceState>
) => {
  builder
    .addCase(
      resourcesExtraActions.updateFloorPlan.pending,
      (state: ResourceState) => {
        state.loadingSelectedResource = true;
        state.resourceCrudLoading = true;
        state.resourcesError = null;
      }
    )
    .addCase(
      resourcesExtraActions.updateFloorPlan.fulfilled,
      (state: ResourceState) => {
        state.loadingSelectedResource = false;
        state.resourceCrudLoading = false;
        state.resourcesError = null;
      }
    )
    .addCase(
      resourcesExtraActions.updateFloorPlan.rejected,
      (state: ResourceState, action) => {
        state.selectedResource = null;
        state.resourcesError = {
          label: "resources.failToUpdateFloorPlan",
          message: action.error.message,
        };
        state.loadingSelectedResource = false;
        state.resourceCrudLoading = false;
      }
    );
};

const getGeoReferencedResources = (
  builder: ActionReducerMapBuilder<ResourceState>
) => {
  builder
    .addCase(
      resourcesExtraActions.getAllGeoReferencedResources.pending,
      (state: ResourceState) => {
        state.resourceCrudLoading = true;
      }
    )
    .addCase(
      resourcesExtraActions.getAllGeoReferencedResources.fulfilled,
      (state: ResourceState, action: PayloadAction<DevicesResponse>) => {
        state.geoReferencedResources = action.payload?.deviceList;
        state.resourceCrudLoading = false;
      }
    )
    .addCase(
      resourcesExtraActions.getAllGeoReferencedResources.rejected,
      (state: ResourceState) => {
        state.resourceCrudLoading = false;
      }
    );
};

const resourcesExtraReducers = (
  builder: ActionReducerMapBuilder<ResourceState>
): any => {
  resourcesGetAllReducer(builder);
  resourcesUpdateGeoReducer(builder);
  resourcesGetNextBatchReducer(builder);
  resourcesGetResourceByResourceMappingId(builder);
  resourcesUpdateFloorPlanReducer(builder);
  getGeoReferencedResources(builder);
};

export default resourcesExtraReducers;
