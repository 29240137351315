import { useEffect } from "react";
import {
  useNodesState,
  Node,
  ReactFlowInstance,
  XYPosition,
  NodeDimensionChange,
} from "reactflow";
// Models
import { useSelector } from "react-redux";
import { OgTaskTypeDto } from "../../../../models/ogTaskTypes/OgTaskTypeDto";
import { OgTemplateDto } from "../../../../models/ogTemplate/OgTemplateDto";
// Helpers

import { initialNode, newNode } from "../helpers/nodes";
import { readOnlyMode } from "../../../../store/root";

const useNodes = (
  reactFlowInstance: ReactFlowInstance,
  reactFlowWrapper: React.MutableRefObject<any>,
  template?: OgTemplateDto
): [
  Node<any>[],
  (nodeChanges: NodeDimensionChange[]) => void,
  (dataId: string, extraAction?: (removedNode: Node) => void) => void,
  (type: OgTaskTypeDto, id: string, position: XYPosition) => void
] => {
  const isReadOnly = useSelector(readOnlyMode);
  const [nodes, setNodes, onNodesChange] = useNodesState<Node[]>([]);

  // Default implementation
  const onNodeDelete = (
    dataId: string,
    extraNodeRemovedAction?: (removedNode: Node) => void
  ): void => {
    setNodes((prevNodes) => {
      const newNodes = [];
      prevNodes.forEach((n) => {
        if (n.id === dataId) extraNodeRemovedAction?.(n);
        else newNodes.push(n);
      });
      return newNodes;
    });
  };

  const onAddNode = (type: OgTaskTypeDto, id: string, position: XYPosition) => {
    setNodes((prevNodes) => [...prevNodes, newNode(type, id, position)]);
  };

  useEffect(() => {
    if (!template || !reactFlowInstance) return;
    const initialPosition = reactFlowInstance?.project({
      x:
        JSON.stringify(reactFlowInstance.getViewport()) ===
        JSON.stringify({ x: 0, y: 0, zoom: 1 })
          ? reactFlowWrapper?.current?.getBoundingClientRect()?.width / 2
          : reactFlowInstance.getViewport().x +
            (reactFlowWrapper?.current?.getBoundingClientRect()?.width *
              reactFlowInstance.getViewport().zoom) /
              2,
      y: reactFlowInstance.getViewport()?.y,
    });
    const listWithInitialNode = [
      initialNode(template?.entryPointId, initialPosition),
    ];

    const taskTypeList: OgTaskTypeDto[] = template?.taskTypeDTOList;
    setNodes(
      taskTypeList?.reduce(
        (acc, task) => {
          const node: Node = newNode(task);
          if (node) {
            acc.push(node);
          }
          return acc;
        },
        template ? listWithInitialNode : []
      )
    );
  }, [template, reactFlowInstance, isReadOnly]);

  return [nodes, onNodesChange, onNodeDelete, onAddNode];
};

// eslint-disable-next-line import/prefer-default-export
export { useNodes };
