import { HttpClient } from "compass-shared-services";
import { OgTaskTypeDto } from "../models/ogTaskTypes/OgTaskTypeDto";
import { OgTaskTypeListDto } from "../models/ogTaskTypes/OgTaskTypeListDto";

const { OPERATOR_GUIDE_TEMPLATE_MANAGER_PATH } = appConfig;
const URL_PATHS = {
  OG_TASK_TYPES: `${OPERATOR_GUIDE_TEMPLATE_MANAGER_PATH}/type-manager/type`,
};

const httpClient = new HttpClient(appConfig);

export default class OgTaskTypeService {
  static async getAllTaskTypes(): Promise<OgTaskTypeDto[]> {
    const urlPath = `${URL_PATHS.OG_TASK_TYPES}`;
    return httpClient
      .get<OgTaskTypeListDto>({ url: urlPath })
      .then((response) => {
        const taskTypes: OgTaskTypeDto[] = new Array<OgTaskTypeDto>();
        if (response?.taskTypeDTOs && response.taskTypeDTOs.length > 0) {
          response.taskTypeDTOs.forEach((o) => taskTypes.push(o));
        }
        return taskTypes;
      })
      .catch(async () => {
        throw Error("Failed to get og task types");
      });
  }
}
