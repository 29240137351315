// General
import React from "react";
import { useI18n } from "compass-commons";
// Styles
import { Button } from "dms-lib";
import "./floorPlanDialogActions.module.css";

/* eslint-disable @typescript-eslint/no-explicit-any */

interface FloorPlanDialogActionsProps {
  onCancel: (event?: any) => void;
  onSuccess: (event?: any) => void;
}

const FloorPlanDialogActions = ({
  onCancel,
  onSuccess,
}: FloorPlanDialogActionsProps): JSX.Element => {
  const { t } = useI18n();
  return (
    <div className="floor-plan-dialog-actions__wrapper">
      <Button
        dataCr="cancel-floor-plan-delete"
        color="primary"
        variant="contained"
        onClick={onCancel}
      >
        {t("cancel", { ns: "Shared" })}
      </Button>
      <Button
        dataCr="confirm-floor-plan-delete"
        color="secondary"
        variant="outlined"
        onClick={onSuccess}
      >
        {t("confirmDelete", { ns: "Shared" })}
      </Button>
    </div>
  );
};

export default FloorPlanDialogActions;
