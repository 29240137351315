// General
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// Material UI
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
// Styles
import "./siteNodeStyles.module.css";
// Components
import SiteLeaf from "./SiteLeaf";
// Hooks
import useNavigateToSite from "../../hooks/useNavigateToSite";
// Model
import { SiteTreeNodeDto } from "../../models/sites/SiteTreeNodeDto";
// Store
import { selectSites } from "../../store";
import { creationMode, readOnlyMode } from "../../store/root";
import { currentSiteSnapAddedChild } from "../../store/sites";

interface SiteNodeProps {
  siteData: SiteTreeNodeDto;
  ancestors: SiteTreeNodeDto[];
  depth: number;
  expandTrigger?: () => void;
}

/**
 * Component that represents the site node.
 * A site node is the parent representation,
 * it means that there are childs to be built
 * and a new collapsable entry has to be built
 * @param siteData - the site information, contains name, id and childsites
 * @param hierarchy - all the sites ancestors
 * @param depth - depth in the site tree hierarchy
 * @removeChildFromParent - callback action to remove a child from parent
 * @returns JSX.Element
 */
const SiteNode = ({
  siteData,
  ancestors,
  depth,
  expandTrigger,
}: SiteNodeProps): JSX.Element => {
  // Redux State
  const isReadOnlyMode = useSelector(readOnlyMode);
  const isCreationMode = useSelector(creationMode);
  const siteSnapAddedChild = useSelector(currentSiteSnapAddedChild);
  const { siteCurrentSnap } = useSelector(selectSites);
  const { metadata: siteCurrentSnapMetadata } = siteCurrentSnap || {};

  // Navigation Hooks
  const navigateToSite = useNavigateToSite();

  // Component State
  const [expand, setExpand] = useState(false);
  const [tempSiteData, setTempSiteData] = useState(siteData);

  // Really important condition
  // Only changes tempSiteData if we are inside the node that contains the child to be rendered
  // We have to be listening the node changes so we can first render the child
  const nodeHandlersCondition = siteData.id === siteCurrentSnapMetadata?.id;

  // Really important condition
  // After the child is created we have to keep track of child changes to update in real time
  const creationChildCondition =
    isCreationMode && siteData.id === siteSnapAddedChild?.id;

  /**
   * Action responsable to update the accordion child details visibility
   */
  // eslint-disable-next-line
  const toggleAccordion = useCallback((event?: any, forceState?: boolean) => {
    if (event) event.stopPropagation();
    setExpand((prevState: boolean) => forceState ?? !prevState);
    if (expandTrigger && forceState) expandTrigger();
  }, []);

  /**
   * Action responsable to navigate to the corresponding site URL
   */
  const navigationClick = () => {
    navigateToSite(tempSiteData.id);
  };

  // Look at nodeHandlersCondition
  useEffect(() => {
    if (isReadOnlyMode) setTempSiteData(siteData);
  }, [siteData]);

  // Look at creationChildCondition
  // Important to have different use effects for each parent and child so that
  // in creation mode we are able to render the child node and then to navigate to the child
  useEffect(() => {
    if (creationChildCondition) setTempSiteData(siteSnapAddedChild);
  }, [siteSnapAddedChild]);

  useEffect(() => {
    if (nodeHandlersCondition) setTempSiteData(siteCurrentSnapMetadata);
  }, [siteCurrentSnapMetadata]);

  return (
    <>
      <Accordion className="config-site__node" expanded={expand}>
        <SiteLeaf
          siteData={tempSiteData}
          ancestors={ancestors}
          depth={depth}
          toggleAccordion={toggleAccordion}
          onClick={navigationClick}
          isCollapsable={!!tempSiteData.childSites.length}
        />
        {(tempSiteData.childSites.length || !depth) && (
          <AccordionDetails className="config-site__node-details">
            {tempSiteData.childSites?.map((s: SiteTreeNodeDto) => (
              <SiteNode
                key={`site-node-${s.id}`}
                siteData={s}
                ancestors={[...ancestors, tempSiteData]}
                depth={depth + 1}
                expandTrigger={() => toggleAccordion(null, true)}
              />
            ))}
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
};

SiteNode.defaultProps = {
  expandTrigger: undefined,
};

export default SiteNode;
