// General
import React from "react";
import { Control, Controller } from "react-hook-form";
// Components
import { Switch } from "@mui/material";
// Styles
import "../behaviourTriggerPanel.module.css";
// Models
import { FormRuleDto } from "../../../../../models/incidents/form/FormRuleDto";

interface BehaviourTriggerEnableProps {
  ruleId: string;
  readOnlyMode: boolean;
  control: Control<FormRuleDto, any>;
  handleEnableSelect: (select: boolean) => void;
}

const BehaviourTriggerEnabled = (
  props: BehaviourTriggerEnableProps
): JSX.Element => {
  const { ruleId, readOnlyMode, control, handleEnableSelect } = props;
  return (
    <Controller
      name="trigger.disabled"
      control={control}
      render={({ field: { onChange, value: disabled } }) => {
        return (
          <div id={ruleId}>
            <Switch
              checked={!disabled}
              disabled={readOnlyMode}
              onChange={(e, checked) => {
                onChange(!e.target.checked);
                handleEnableSelect(checked);
              }}
              data-cr="incident-trigger-enabled"
              size="small"
            />
          </div>
        );
      }}
    />
  );
};

export default BehaviourTriggerEnabled;
