import { BehaviorSubject } from "rxjs";
import { FloorPlanDevice } from "compass-commons";
import { IAlertNotification } from "dms-lib";
import { OgTemplateDto } from "../models/ogTemplate/OgTemplateDto";
import { ResourceLocationHolder } from "../models/resourceMapping/ResourceLocationHolder";

class StateService {
  public currentOgTemplate: BehaviorSubject<OgTemplateDto>;

  public alertSubject: BehaviorSubject<IAlertNotification>;

  public selectedOgTemplate?: BehaviorSubject<OgTemplateDto>;

  public infoText: BehaviorSubject<[string, boolean]>;

  public currentMapZoom: BehaviorSubject<number>;

  public currentMapCenter: BehaviorSubject<[number, number]>;

  public currentResourceToPlace: BehaviorSubject<FloorPlanDevice>;

  public actualResourceLocation: BehaviorSubject<ResourceLocationHolder>;

  constructor() {
    this.currentOgTemplate = new BehaviorSubject<OgTemplateDto>(null);
    this.alertSubject = new BehaviorSubject<IAlertNotification>(null);
    this.selectedOgTemplate = new BehaviorSubject<OgTemplateDto>(null);
    this.infoText = new BehaviorSubject<[string, boolean]>(null);
    this.currentMapZoom = new BehaviorSubject<number>(null);
    this.currentMapCenter = new BehaviorSubject<[number, number]>(null);
    this.currentResourceToPlace = new BehaviorSubject<FloorPlanDevice>(null);
    this.actualResourceLocation = new BehaviorSubject<ResourceLocationHolder>(
      null
    );
  }
}

export default StateService;
