// Contexts
import { ActionState } from "../../contexts/TabActionsContext";
// Models
import { MenuTabRoute } from "../../models/configMenu/MenuTabRoute";
// Store
import { useStoreDispatch } from "../../store";
import { rootActions } from "../../store/root";

export interface ActionsHandler {
  resetAction?: (contextToReset: MenuTabRoute) => void;
  setCreateMode?: ActionState;
  setEditMode?: ActionState;
  setDeleteMode?: ActionState;
  setCancelMode?: ActionState;
  setSaveMode?: ActionState;
  setReadOnlyMode?: ActionState;
}

/**
 * React Hook that helps using the sites CRUD operations.
 * Handlers that are used by the tab header actions
 * They are responsable to trigger actions that will update the redux store
 * and afterwards each slicer that wants to use a required action is responsible
 * to be listening for the required dispatched action
 *
 * @returns Function that receives two parameters:
 * setAddHandler - action to remove a site,
 * setEditHandler - action to active add a site,
 * setDeleteHandler - action to edit a site,
 * setSaveHandler - action to delete a site,
 * setTestHandler - action to delete a site,
 */
const useTabActions = (): ActionsHandler => {
  const dispatch = useStoreDispatch();

  //
  // CONFIG BREADCRUMB ACTIONS CALLBACKS
  //

  const setSaveMode = (preExtraActions?: ActionState) => {
    preExtraActions?.();
    dispatch(rootActions.activateSaveMode());
  };
  const setCancelMode = (preExtraActions?: ActionState) => {
    preExtraActions?.();
    dispatch(rootActions.activateCancelMode());
  };

  const setCreateMode = (preExtraActions?: ActionState) => {
    preExtraActions?.();
    dispatch(rootActions.activateCreationMode());
  };

  const setDeleteMode = (preExtraActions?: ActionState) => {
    preExtraActions?.();
    dispatch(rootActions.activateDeletionMode());
  };

  const setEditMode = (preExtraActions?: ActionState) => {
    preExtraActions?.();
    dispatch(rootActions.activateEditionMode());
  };

  const setReadOnlyMode = (preExtraActions?: ActionState) => {
    preExtraActions?.();
    dispatch(rootActions.activateReadOnlyMode());
  };

  return {
    setSaveMode,
    setCancelMode,
    setCreateMode,
    setDeleteMode,
    setEditMode,
    setReadOnlyMode,
  };
};

export default useTabActions;
