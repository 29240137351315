// General
import React, { PropsWithChildren, useEffect } from "react";
import { useSelector } from "react-redux";
// Components
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Button } from "dms-lib";
// Styles
import "./siteLeafStyles.module.css";
// Store
import { selectSites, useStoreDispatch } from "../../store";
import { sitesActions } from "../../store/sites";
// Models
import {
  SiteTreeNodeDto,
  SitesHashMetadata,
} from "../../models/sites/SiteTreeNodeDto";

interface SiteLeafProps {
  siteData: SiteTreeNodeDto;
  ancestors: SiteTreeNodeDto[];
  depth: number;
  // eslint-disable-next-line
  toggleAccordion: (event: any, forceState?: boolean) => void;
  // eslint-disable-next-line
  onClick: (event?: any) => void;
  isCollapsable?: boolean;
}

/**
 * Component that represents the site leafs.
 * Site leafs can be parents of other leafs, in that case collapsable is set to true
 * @param siteData - the site information, contains name, id and childsites
 * @param hierarchy - all the sites ancestors
 * @param depth - depth in the site tree hierarchy
 * @toggleAccordion - action for the accordion
 * @onClick - action when clicking a siteLeaf
 * @isCollapsable - indicates weather there's more childs or not
 * @returns JSX.Element
 */
const SiteLeaf = ({
  siteData,
  ancestors,
  depth,
  toggleAccordion,
  onClick,
  isCollapsable,
}: PropsWithChildren<SiteLeafProps>): JSX.Element => {
  const { id, name } = siteData;
  const siteTreeBranch: SitesHashMetadata = {
    ancestors,
    metadata: siteData,
  };

  const { selectedSiteId } = useSelector(selectSites);
  const dispath = useStoreDispatch();

  /**
   * Important to create an entry inside the siteAncestorsDict
   * for easier and faster access to siteIds
   */
  useEffect(() => {
    dispath(sitesActions.addToSiteHierarchy(siteTreeBranch));
    if (id === selectedSiteId) toggleAccordion(null, true);
  }, [siteData]);

  const active = id === selectedSiteId;
  return (
    <AccordionSummary
      style={{ "--depth": `${depth < 5 ? depth : 5}` } as React.CSSProperties}
      className={`config-site__leaf-summary${active ? " active" : ""}${
        depth <= 1 && !active ? ` depth${depth}` : ""
      }`}
      onClick={onClick}
      expandIcon={
        isCollapsable ? (
          <Button
            color="inherit"
            variant="contained"
            onClick={(event) => toggleAccordion(event)}
            icon
          >
            <ExpandMoreRoundedIcon />
          </Button>
        ) : null
      }
    >
      <Typography
        className="config-site__leaf-text"
        data-cr={`config-site__leaf-${id}`}
      >
        {name}
      </Typography>
    </AccordionSummary>
  );
};

export default SiteLeaf;
