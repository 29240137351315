// General
import { createAsyncThunk } from "@reduxjs/toolkit";
// Services
import IncidentsService from "../../../services/IncidentsService";
// Models
import { RuleDto } from "../../../models/incidents/RuleDto";
import { incidentsActions } from "../index";
import SubsystemService from "../../../services/SubsystemService";
import OgTemplateService from "../../../services/OgTemplateService";
import { IncidentTypeRulesRequestDto } from "../../../models/incidents/IncidentTypeRule";
import { IncidentTypeRequestDTO } from "../../../models/incidents/IncidentTypeRequestDTO";
import OIMService from "../../../services/OIMService";
import { OnDemandIncidentSubmit } from "../../../models/onDemandIncident/onDemandIncident";

/* eslint-disable no-return-await */
function createExtraActions() {
  const getAllRules = () => {
    return createAsyncThunk(
      `incidents/getAllRules`,
      async (siteId: string) => await IncidentsService.getAllRules(siteId)
    );
  };

  const getRuleById = () => {
    return createAsyncThunk(
      `incidents/getRuleById`,
      async (ruleId: string) => await IncidentsService.getRuleById(ruleId)
    );
  };

  const createRule = () => {
    return createAsyncThunk(
      `incidents/createRule`,
      async (ruleDto: RuleDto, { dispatch }) => {
        const result = await IncidentsService.postRule(ruleDto);
        // Wait till the new list is fully fetched
        await dispatch(incidentsActions.getAllRules(ruleDto.siteId));
        return result;
      }
    );
  };

  const updateRule = () => {
    return createAsyncThunk(
      `incidents/updateRule`,
      async (ruleDto: RuleDto, { dispatch }) => {
        const result = await IncidentsService.putRule(ruleDto);
        // Wait till the new list is fully fetched
        await dispatch(incidentsActions.getAllRules(ruleDto?.siteId));
        return result;
      }
    );
  };

  const deleteRule = () => {
    return createAsyncThunk(
      `incidents/deleteRule`,
      async (params: { ruleId: string; siteId: string }, { dispatch }) => {
        await IncidentsService.deleteRule(params?.ruleId);
        // Wait till the new list is fully fetched
        await dispatch(incidentsActions.getAllRules(params?.siteId));
      }
    );
  };

  const getEventTypes = () => {
    return createAsyncThunk(
      `incidents/getEventTypes`,
      async (subsystemId: string) => SubsystemService.getEventTypes(subsystemId)
    );
  };

  const getIncidentTypes = () => {
    return createAsyncThunk(
      `incidents/getIncidentTypes`,
      async (countRules: boolean) =>
        await IncidentsService.getIncidentTypes(countRules)
    );
  };

  const getIncidentTypeRules = () => {
    return createAsyncThunk(
      `incidents/getIncidentTypeRules`,
      async (incidentTypeRulesRequestDTO: IncidentTypeRulesRequestDto) =>
        await IncidentsService.getIncidentTypeRules(incidentTypeRulesRequestDTO)
    );
  };

  const createIncidentType = () => {
    return createAsyncThunk(
      `incidents/createIncidentType`,
      async (incidentTypeRequestDTO: IncidentTypeRequestDTO, { dispatch }) => {
        await IncidentsService.postIncidentType(incidentTypeRequestDTO);
        // Wait till the new list is fully fetched
        await dispatch(incidentsActions.getIncidentTypes(true));
      }
    );
  };

  const updateIncidentType = () => {
    return createAsyncThunk(
      `incidents/updateIncidentType`,
      async (
        params: {
          incidentTypeId: string;
          incidentTypeRequestDTO: IncidentTypeRequestDTO;
        },
        { dispatch }
      ) => {
        await IncidentsService.putIncidentType(
          params.incidentTypeId,
          params.incidentTypeRequestDTO
        );
        // Wait till the new list is fully fetched
        await dispatch(incidentsActions.getIncidentTypes(true));
      }
    );
  };

  const deleteIncidentType = () => {
    return createAsyncThunk(
      `incidents/deleteIncidentType`,
      async (params: { incidentTypeId: string }, { dispatch }) => {
        await IncidentsService.deleteIncidentType(params.incidentTypeId);
        // Wait till the new list is fully fetched
        await dispatch(incidentsActions.getIncidentTypes(true));
      }
    );
  };

  const forwardIncidentTypes = () => {
    return createAsyncThunk(
      `incidents/forwardIncidentTypes`,
      async (
        params: {
          incidentTypeId: string;
          incidentTypeIdToForward: string;
        },
        { dispatch }
      ) => {
        await IncidentsService.forwardIncidentType(
          params.incidentTypeId,
          params.incidentTypeIdToForward
        );
        // Wait till the new list is fully fetched
        await dispatch(incidentsActions.getIncidentTypes(true));
      }
    );
  };

  const getOperatorGuideTemplates = () => {
    return createAsyncThunk(
      `incidents/getOperatorGuides`,
      async (siteId: string) =>
        OgTemplateService.getAllOgTemplates(siteId, true)
    );
  };

  const testRule = () => {
    return createAsyncThunk(
      `incidents/testRule`,
      async (params: { ruleId: string; siteId: string }) => {
        await IncidentsService.testRule(params?.siteId, params?.ruleId);
      }
    );
  };

  const testOnDemandRule = () => {
    return createAsyncThunk(
      `incidents/testOnDemandRule`,
      async (params: { ruleId: string; siteId: string }) => {
        const payload = {
          siteId: params.siteId,
          ruleId: params.ruleId,
        } as OnDemandIncidentSubmit;
        await OIMService.createTestOnDemandIncident(payload);
      }
    );
  };

  return {
    getAllRules: getAllRules(),
    getRuleById: getRuleById(),
    createRule: createRule(),
    updateRule: updateRule(),
    deleteRule: deleteRule(),
    getEventTypes: getEventTypes(),
    getIncidentTypes: getIncidentTypes(),
    createIncidentType: createIncidentType(),
    updateIncidentType: updateIncidentType(),
    deleteIncidentType: deleteIncidentType(),
    forwardIncidentTypeRules: forwardIncidentTypes(),
    getIncidentTypeRules: getIncidentTypeRules(),
    getOperatorGuideTemplates: getOperatorGuideTemplates(),
    testRule: testRule(),
    testOnDemandRule: testOnDemandRule(),
  };
}

const incidentsExtraActions = createExtraActions();

export default incidentsExtraActions;
