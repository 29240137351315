// General
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
// Contexts
import {
  ActionState,
  useTabActionsContext,
} from "../../contexts/TabActionsContext";
// Hooks
import useNavigateToSite from "../useNavigateToSite";
import useTabActions from "../useTabActions";
// Store
import { selectSites, useStoreDispatch } from "../../store";
import { creationMode } from "../../store/root";
import {
  currentSiteSnapAddedChild,
  selectedSiteMetadata,
  selectedSiteParentId,
  sitesActions,
} from "../../store/sites";

export interface SiteActions {
  deleteSite: ActionState;
  cancelAction: ActionState;
}

/**
 * React Hook that helps using the sites CRUD operations.
 * preSave prop is used to validate form before submitting.
 * @returns Function that receives two parameters:
 * deleteSite - action to remove a site,
 * cancelAction - action that guarantees that everything goes back to normal,
 */
const useSiteActions = (): {
  updateSite: () => Promise<void>;
  cancelAction: (postExtraActions?: ActionState) => Promise<void>;
  deleteSite: (extraActions?: ActionState) => Promise<void>;
  createSite: () => Promise<void>;
} => {
  // Root Actions
  const isCreationMode = useSelector(creationMode);
  // Redux State Management
  const selectedSite = useSelector(selectedSiteMetadata);
  const siteSnapAddedChild = useSelector(currentSiteSnapAddedChild);
  const siteParentId = useSelector(selectedSiteParentId);

  const { siteLastSnap, siteCurrentSnap, selectedSiteId } =
    useSelector(selectSites);
  const { metadata: siteLastSnapMetadata } = siteLastSnap || {};
  const { metadata: siteCurrentSnapMetadata } = siteCurrentSnap || {};

  const dispatch = useStoreDispatch();
  const navigateToSite = useNavigateToSite();
  const { setReadOnlyMode } = useTabActions();
  const { setExtraResetHandler } = useTabActionsContext();

  //
  // CONFIG BREADCRUMB ACTIONS CALLBACKS
  //

  /**
   * Action responsable to delete a site from the tree
   */
  const deleteSite = async (extraActions?: ActionState) => {
    dispatch(sitesActions.deleteSite(selectedSite.id));
    extraActions?.();
    // After removing navigate to parentId
    navigateToSite(siteParentId);
  };

  const keepSiteContext = useCallback(() => {
    if (isCreationMode)
      navigateToSite(siteLastSnapMetadata?.id, { replace: true });
  }, [isCreationMode, selectedSiteId, siteLastSnapMetadata]);

  const cancelAction = async (postExtraActions?: ActionState) => {
    keepSiteContext();
    setReadOnlyMode();
    postExtraActions?.();
  };

  const createSite = async () => {
    const siteCreated = await dispatch(
      sitesActions.createSite({
        parentSiteId: siteCurrentSnapMetadata.id,
        name: siteSnapAddedChild.name,
        isTimeZoneInherited: siteSnapAddedChild.isTimeZoneInherited,
        timeZone: siteSnapAddedChild.timeZone,
      })
    ).unwrap();
    navigateToSite(siteCreated.id);
  };

  const updateSite = async () => {
    await dispatch(
      sitesActions.updateSite({
        siteId: siteCurrentSnapMetadata.id,
        body: {
          name: siteCurrentSnapMetadata.name,
          isTimeZoneInherited: siteCurrentSnapMetadata.isTimeZoneInherited,
          timeZone: siteCurrentSnapMetadata.timeZone,
        },
      })
    );
  };

  //
  // LifeCycles
  //

  // It resets the site handler to be used when leaving the route
  useEffect(() => {
    setExtraResetHandler?.(keepSiteContext);
  }, [keepSiteContext]);

  // Use effect used when adding a new site.
  // It is important to return to a valid state
  useEffect(() => {
    if (isCreationMode && siteSnapAddedChild)
      navigateToSite(siteSnapAddedChild.id, { replace: true });
  }, [siteSnapAddedChild]);

  return {
    deleteSite,
    cancelAction,
    createSite,
    updateSite,
  };
};

export default useSiteActions;
