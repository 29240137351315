import { z, string } from "zod";

// eslint-disable-next-line no-shadow
enum EStateColor {
  RED = "RED",
  YELLOW = "YELLOW",
  BLUE = "BLUE",
  GREEN = "GREEN",
  ORANGE = "ORANGE",
  GRAY = "GRAY",
}

const StateColor = z.nativeEnum(EStateColor);

export type StateColor = z.infer<typeof StateColor>;

export const ResourceMappingContition = z.object({
  id: string(),
  name: string(),
  color: StateColor,
});

export type ResourceMappingContition = z.infer<typeof ResourceMappingContition>;
