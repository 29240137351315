// Generic
import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { HttpAbortCodes } from "compass-shared-services";
// Models
import {
  IncidentState,
  PROP_SEARCH_TERM,
  RESOURCE_MAPPING_PAGE_LENGTH,
} from "..";
import { RuleLightDto } from "../../../models/incidents/RuleLightDto";
// Actions
import incidentsExtraActions from "../extraActions";
import { resourceMappingActions } from "../../resourceMapping";
// Models
import { RuleDto } from "../../../models/incidents/RuleDto";
import { EventTypesDto } from "../../../models/subsystems/EventTypesDto";
import { IncidentTypeDto } from "../../../models/incidents/IncidentTypeDto";
import { OgTemplateDto } from "../../../models/ogTemplate/OgTemplateDto";
import { ResourceMappingLiteData } from "../../../models/resourceMapping/ResourceMappingFilters";
import { IncidentTypeRulesPageDto } from "../../../models/incidents/IncidentTypeRule";
// Utils
import { sortByProp, filterByProp } from "../../../utils/Util";
/* eslint-disable no-param-reassign, @typescript-eslint/no-explicit-any */

const fromResourceMappingReducer = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      resourceMappingActions.getLiteResourceMappings.pending,
      (state: IncidentState) => {
        state.loadingResourceMappings = true;
        state.incidentsError = null;
      }
    )
    .addCase(
      resourceMappingActions.getLiteResourceMappings.fulfilled,
      (
        state: IncidentState,
        action: PayloadAction<ResourceMappingLiteData>
      ) => {
        state.loadingResourceMappings = false;
        state.resourceMappings =
          (action.payload.filters?.pageIndex &&
            state.resourceMappings.concat(action.payload.data)) ||
          action.payload.data;
        state.searchTermsResourceMappings = {
          ...state.searchTermsResourceMappings,
          hasMore: action.payload.data.length >= RESOURCE_MAPPING_PAGE_LENGTH,
        };
      }
    )
    .addCase(
      resourceMappingActions.getLiteResourceMappings.rejected,
      (state: IncidentState, action) => {
        if (action?.error.message === HttpAbortCodes.ERR_CANCELED) return;
        state.loadingResourceMappings = false;
        state.incidentsError = {
          label: "behaviors.errorGettingResourceMappings",
          message: action.error.message,
        };
      }
    );
};

const incidentsGetAllRulesReducer = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.getAllRules.pending,
      (state: IncidentState) => {
        state.selectedRule = null;
        state.loadingRulesList = true;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.getAllRules.fulfilled,
      (state: IncidentState, action: PayloadAction<RuleLightDto[]>) => {
        state.rulesList = sortByProp(action.payload, PROP_SEARCH_TERM);
        state.filteredRulesList = filterByProp(
          action.payload,
          PROP_SEARCH_TERM,
          state.searchTerm
        );
        state.loadingRulesList = false;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.getAllRules.rejected,
      (state: IncidentState, action) => {
        state.loadingRulesList = false;
        state.incidentsError = {
          label: "behaviors.rulesNotFound",
          message: action.error.message,
        };
      }
    );
};

const incidentsGetRuleByIdReducer = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.getRuleById.pending,
      (state: IncidentState) => {
        state.loadingSelectedRule = true;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.getRuleById.fulfilled,
      (state: IncidentState, action: PayloadAction<RuleDto>) => {
        state.selectedRule = action?.payload;
        state.loadingSelectedRule = false;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.getRuleById.rejected,
      (state: IncidentState, action) => {
        state.selectedRule = null;
        state.incidentsError = {
          label: "behaviors.errorLoadingBehavior",
          message: action.error.message,
        };
        state.loadingSelectedRule = false;
      }
    );
};

const incidentGetEventTypes = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.getEventTypes.pending,
      (state: IncidentState) => {
        state.loadingEventTypes = true;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.getEventTypes.fulfilled,
      (state: IncidentState, action: PayloadAction<EventTypesDto>) => {
        state.eventTypes = action.payload;
        state.loadingEventTypes = false;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.getEventTypes.rejected,
      (state: IncidentState, action) => {
        state.loadingEventTypes = false;
        state.incidentsError = {
          label: "behaviors.errorGettingEventTypes",
          message: action.error.message,
        };
      }
    );
};

const incidentGetOperatorGuideTemplates = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.getOperatorGuideTemplates.pending,
      (state: IncidentState) => {
        state.loadingOperatorGuideTemplates = true;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.getOperatorGuideTemplates.fulfilled,
      (state: IncidentState, action: PayloadAction<OgTemplateDto[]>) => {
        state.operatorGuideTemplates = action.payload;
        state.loadingOperatorGuideTemplates = false;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.getOperatorGuideTemplates.rejected,
      (state: IncidentState, action) => {
        state.loadingOperatorGuideTemplates = false;
        state.incidentsError = {
          label: "behaviors.errorGettingOperatorGuidesTemplates",
          message: action.error.message,
        };
      }
    );
};

const incidentsCreateRuleReducer = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.createRule.pending,
      (state: IncidentState) => {
        state.ruleCrudLoading = true;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.createRule.fulfilled,
      (state: IncidentState) => {
        state.ruleCrudLoading = false;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.createRule.rejected,
      (state: IncidentState, action) => {
        state.selectedRule = null;
        state.incidentsError = {
          label: "behaviors.failToCreateBehavior",
          message: action.error.message,
        };
        state.ruleCrudLoading = false;
      }
    );
};

const incidentsUpdateRuleReducer = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.updateRule.pending,
      (state: IncidentState) => {
        state.ruleCrudLoading = true;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.updateRule.fulfilled,
      (state: IncidentState) => {
        state.ruleCrudLoading = false;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.updateRule.rejected,
      (state: IncidentState, action) => {
        state.selectedRule = null;
        state.incidentsError = {
          label: "behaviors.failToUpdateBehavior",
          message: action.error.message,
        };
        state.ruleCrudLoading = false;
      }
    );
};

const incidentsDeleteRuleReducer = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.deleteRule.pending,
      (state: IncidentState) => {
        state.incidentsError = null;
        state.ruleCrudLoading = true;
      }
    )
    .addCase(
      incidentsExtraActions.deleteRule.fulfilled,
      (state: IncidentState) => {
        state.ruleCrudLoading = false;
      }
    )
    .addCase(
      incidentsExtraActions.deleteRule.rejected,
      (state: IncidentState, action) => {
        state.incidentsError = {
          label: "behaviors.failToDeleteBehavior",
          message: action.error.message,
        };
        state.ruleCrudLoading = false;
      }
    );
};

const incidentsTestRuleReducer = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(incidentsExtraActions.testRule.pending, (state: IncidentState) => {
      state.incidentsError = null;
      state.testingRule = true;
    })
    .addCase(
      incidentsExtraActions.testRule.fulfilled,
      (state: IncidentState) => {
        state.testingRule = false;
      }
    )
    .addCase(
      incidentsExtraActions.testRule.rejected,
      (state: IncidentState, action) => {
        state.incidentsError = {
          label: "behaviors.failToTestBehavior",
          message: action.error.message,
        };
        state.testingRule = false;
      }
    );
};

const incidentsTestRuleOnDemandReducer = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.testOnDemandRule.pending,
      (state: IncidentState) => {
        state.incidentsError = null;
        state.testingRule = true;
      }
    )
    .addCase(
      incidentsExtraActions.testOnDemandRule.fulfilled,
      (state: IncidentState) => {
        state.testingRule = false;
      }
    )
    .addCase(
      incidentsExtraActions.testOnDemandRule.rejected,
      (state: IncidentState, action) => {
        state.incidentsError = {
          label: "behaviors.failToTestBehavior",
          message: action.error.message,
        };
        state.testingRule = false;
      }
    );
};

const incidentGetIncidentTypes = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.getIncidentTypes.pending,
      (state: IncidentState, { meta }) => {
        if (meta.arg) state.loadingIncidentTypesRuleCount = true;
        else state.loadingIncidentTypes = true;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.getIncidentTypes.fulfilled,
      (
        state: IncidentState,
        { meta, payload }: PayloadAction<IncidentTypeDto[], any, any>
      ) => {
        if (meta.arg) state.loadingIncidentTypesRuleCount = false;
        else state.loadingIncidentTypes = false;
        state.incidentTypes = Array.from(payload).sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.getIncidentTypes.rejected,
      (state: IncidentState, { meta, error }) => {
        if (meta.arg) state.loadingIncidentTypesRuleCount = false;
        else state.loadingIncidentTypes = false;
        state.incidentsError = {
          label: "behaviors.errorGettingIncidentTypes",
          message: error.message,
        };
      }
    );
};

const incidentGetIncidentTypeRules = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.getIncidentTypeRules.pending,
      (state: IncidentState, { meta }) => {
        state.loadingIncidentTypesRules[meta.arg.incidentTypeId] = true;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.getIncidentTypeRules.fulfilled,
      (
        state: IncidentState,
        { meta, payload }: PayloadAction<IncidentTypeRulesPageDto, any, any>
      ) => {
        delete state.loadingIncidentTypesRules[meta.arg.incidentTypeId];
        if (meta.arg.index > 0) {
          state.incidentTypesPageRules[
            meta.arg.incidentTypeId
          ].incidentTypeRules.push(...payload.incidentTypeRules);
          state.incidentTypesPageRules[meta.arg.incidentTypeId].totalResults =
            payload.totalResults;
        } else {
          state.incidentTypesPageRules[meta.arg.incidentTypeId] = payload;
        }
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.getIncidentTypeRules.rejected,
      (state: IncidentState, { meta, error }) => {
        delete state.loadingIncidentTypesRules[meta.arg.incidentTypeId];
        delete state.incidentTypesPageRules[meta.arg.incidentTypeId];
        state.incidentsError = {
          label: "incidentTypes.fail.getBehaviors",
          message: error.message,
          parameters: { incidentType: meta.arg.incidentTypeName },
        };
      }
    );
};

const incidentsCreateIncidentTypeReducer = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.createIncidentType.pending,
      (state: IncidentState) => {
        state.incidentTypeCrudLoading = true;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.createIncidentType.fulfilled,
      (state: IncidentState) => {
        state.incidentTypeCrudLoading = false;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.createIncidentType.rejected,
      (state: IncidentState, action) => {
        state.incidentsError = {
          label: "incidentTypes.fail.createIncidentType",
          message: action.error.message,
        };
        state.incidentTypeCrudLoading = false;
      }
    );
};

const incidentsUpdateIncidentTypeReducer = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.updateIncidentType.pending,
      (state: IncidentState) => {
        state.incidentTypeCrudLoading = true;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.updateIncidentType.fulfilled,
      (state: IncidentState) => {
        state.incidentTypeCrudLoading = false;
        state.incidentsError = null;
      }
    )
    .addCase(
      incidentsExtraActions.updateIncidentType.rejected,
      (state: IncidentState, action) => {
        state.incidentsError = {
          label: "incidentTypes.fail.updateIncidentType",
          message: action.error.message,
        };
        state.incidentTypeCrudLoading = false;
      }
    );
};

const incidentsDeleteIncidentTypeReducer = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.deleteIncidentType.pending,
      (state: IncidentState) => {
        state.incidentsError = null;
        state.incidentTypeCrudLoading = true;
      }
    )
    .addCase(
      incidentsExtraActions.deleteIncidentType.fulfilled,
      (state: IncidentState) => {
        state.incidentTypeCrudLoading = false;
      }
    )
    .addCase(
      incidentsExtraActions.deleteIncidentType.rejected,
      (state: IncidentState, action) => {
        state.incidentsError = {
          label: "incidentTypes.fail.deleteIncidentType",
          message: action.error.message,
        };
        state.incidentTypeCrudLoading = false;
      }
    );
};

const incidentsForwardIncidentTypeRulesReducer = (
  builder: ActionReducerMapBuilder<IncidentState>
) => {
  builder
    .addCase(
      incidentsExtraActions.forwardIncidentTypeRules.pending,
      (state: IncidentState) => {
        state.incidentsError = null;
        state.incidentTypeForwardError = false;
        state.incidentTypeCrudLoading = true;
      }
    )
    .addCase(
      incidentsExtraActions.forwardIncidentTypeRules.fulfilled,
      (state: IncidentState) => {
        state.incidentTypeForwardError = false;
        state.incidentTypeCrudLoading = false;
      }
    )
    .addCase(
      incidentsExtraActions.forwardIncidentTypeRules.rejected,
      (state: IncidentState, action) => {
        state.incidentTypeForwardError = true;
        state.incidentsError = {
          label: "incidentTypes.fail.forwardIncidentType",
          message: action.error.message,
        };
        state.incidentTypeCrudLoading = false;
      }
    );
};

const incidentsExtraReducers = (
  builder: ActionReducerMapBuilder<IncidentState>
): any => {
  fromResourceMappingReducer(builder);
  incidentsGetAllRulesReducer(builder);
  incidentsGetRuleByIdReducer(builder);
  incidentsCreateRuleReducer(builder);
  incidentsUpdateRuleReducer(builder);
  incidentsDeleteRuleReducer(builder);
  incidentGetEventTypes(builder);
  incidentGetOperatorGuideTemplates(builder);
  incidentsTestRuleReducer(builder);
  incidentsTestRuleOnDemandReducer(builder);
  incidentGetIncidentTypes(builder);
  incidentGetIncidentTypeRules(builder);
  incidentsCreateIncidentTypeReducer(builder);
  incidentsUpdateIncidentTypeReducer(builder);
  incidentsDeleteIncidentTypeReducer(builder);
  incidentsForwardIncidentTypeRulesReducer(builder);
};

export default incidentsExtraReducers;
