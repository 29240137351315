import { createAsyncThunk } from "@reduxjs/toolkit";
// Services
import SubsystemService from "../../../services/SubsystemService";
// Models
import { SubsystemDto } from "../../../models/subsystems/SubsystemDto";
import { UpdateSubsystemDto } from "../../../models/subsystems/UpdateSubsystemDto";
// Actions
import { subsystemsActions } from "../index";

function createExtraActions() {
  const getSiteSubsystems = (includeAncestors = false) => {
    return createAsyncThunk(
      `subsystems/getSiteSubsystems`,
      async (siteId: string) =>
        SubsystemService.getSubsystems(siteId, includeAncestors)
    );
  };

  const getSiteSubsystem = () => {
    return createAsyncThunk(
      `subsystems/getSiteSubsystem`,
      async (subsystemId: string) => SubsystemService.getSubsystem(subsystemId)
    );
  };

  const subsystemResourcesPolling = () => {
    return createAsyncThunk(
      `subsystems/subsystemResourcesPolling`,
      async (subsystemId: string, { dispatch }) => {
        const result = await SubsystemService.getSubsystem(subsystemId);
        dispatch(subsystemsActions.updateSubsystemRefreshState(result));
        return result;
      }
    );
  };

  const refreshResources = () => {
    return createAsyncThunk(
      `subsystems/refreshResources`,
      async (subsystemId: string) =>
        SubsystemService.refreshResources(subsystemId)
    );
  };

  const getConnectionProperties = () => {
    return createAsyncThunk(
      `subsystems/getConnectionProperties`,
      async (subsystemId: string) =>
        SubsystemService.getConnectionProperties(subsystemId)
    );
  };

  const getConnectionPropertiesTemplate = () => {
    return createAsyncThunk(
      `subsystems/getConnectionPropertiesTemplate`,
      async ({
        brandId,
        modelId,
        integrationRegistrationId,
      }: {
        brandId: string;
        modelId: string;
        integrationRegistrationId: string;
      }) =>
        SubsystemService.getConnectionPropertiesTemplate(
          brandId,
          modelId,
          integrationRegistrationId
        )
    );
  };

  const getModels = () => {
    return createAsyncThunk(
      `subsystems/getModels`,
      async ({
        brandId,
        subsystemId,
      }: {
        brandId: string;
        subsystemId: string;
      }) => SubsystemService.getModels(brandId, subsystemId)
    );
  };

  const getBrands = () => {
    return createAsyncThunk(`subsystems/getBrands`, async () =>
      SubsystemService.getBrands()
    );
  };

  const createSubsystem = () => {
    return createAsyncThunk(
      "subsystem/createSubsystem",
      async (
        params: {
          subsystemDto: SubsystemDto;
          integrationRegistrationId: string;
        },
        { dispatch }
      ) => {
        const subsystemId = await SubsystemService.createSubsystem(
          params.subsystemDto,
          params.integrationRegistrationId
        );
        // Wait till the new list is fully fetched
        await dispatch(
          subsystemsActions.getSiteSubsystems(params.subsystemDto.siteId)
        );
        await dispatch(subsystemsActions.refreshResources(subsystemId));
        return subsystemId;
      }
    );
  };

  const updateSubsystem = () => {
    return createAsyncThunk(
      "subsystem/updateSubsystem",
      async (
        params: {
          updateSubsystemDto: UpdateSubsystemDto;
          subsystemId: string;
          siteId: string;
        },
        { dispatch }
      ) => {
        const result = await SubsystemService.updateSubsystem(
          params.updateSubsystemDto,
          params.subsystemId
        );
        // Wait till the new list is fully fetched
        await dispatch(subsystemsActions.getSiteSubsystems(params.siteId));
        return result;
      }
    );
  };

  const deleteSubsystem = () => {
    return createAsyncThunk(
      "subsystem/deleteSubsystem",
      async (
        params: {
          subsystemId: string;
          siteId: string;
        },
        { dispatch }
      ) => {
        await SubsystemService.deleteSubsystem(params.subsystemId);
        // Wait till the new list is fully fetched
        await dispatch(subsystemsActions.getSiteSubsystems(params.siteId));
      }
    );
  };

  return {
    deleteSubsystem: deleteSubsystem(),
    createSubsystem: createSubsystem(),
    updateSubsystem: updateSubsystem(),
    getBrands: getBrands(),
    getConnectionProperties: getConnectionProperties(),
    getConnectionPropertiesTemplate: getConnectionPropertiesTemplate(),
    getSiteSubsystems: getSiteSubsystems(),
    getSubsystemWithAncestors: getSiteSubsystems(true),
    getSiteSubsystem: getSiteSubsystem(),
    getModels: getModels(),
    subsystemResourcesPolling: subsystemResourcesPolling(),
    refreshResources: refreshResources(),
  };
}

const subsystemsExtraActions = createExtraActions();

export default subsystemsExtraActions;
