// General
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "compass-commons";
// Components
import { Button } from "dms-lib";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteEdgeDeviceModal from "../../deleteEdgeDeviceModal/DeleteEdgeDeviceModal";
import EdgeDeviceWasDeletedModal from "../../edgeDeviceWasDeletedModal/EdgeDeviceWasDeletedModal";
// Store
import {
  selectEdgeDevices,
  selectSites,
  useStoreDispatch,
} from "../../../../store";
import { edgeDevicesActions } from "../../../../store/edgeDevices";

interface SelectedEdgeDeviceDetailsHeaderProps {
  edgeDeviceId: string;
  edgeDeviceName: string;
}

const SelectedEdgeDeviceDetailsHeader = ({
  edgeDeviceId,
  edgeDeviceName,
}: SelectedEdgeDeviceDetailsHeaderProps): JSX.Element => {
  const { t: translate } = useI18n();
  const dispatch = useStoreDispatch();
  const { selectedSiteId } = useSelector(selectSites);
  const { loadingEdgeDevicesCrud, currentPage, deletedSuccessfully } =
    useSelector(selectEdgeDevices);
  const [showDeleteEdgeDeviceModal, setShowDeleteEdgeDeviceModal] =
    useState(false);
  const [showEdgeDeviceWasDeletedModal, setShowEdgeDeviceWasDeletedModal] =
    useState(false);
  const handleCancelDeleteEdgeDevice = () => {
    setShowDeleteEdgeDeviceModal(false);
  };

  useEffect(() => {
    if (deletedSuccessfully) {
      setShowEdgeDeviceWasDeletedModal(true);
    }
  }, [deletedSuccessfully]);

  const handleDeleteEdgeDevice = () => {
    dispatch(edgeDevicesActions.deleteEdgeDevice(edgeDeviceId)).then(() => {
      setShowDeleteEdgeDeviceModal(false);
    });
  };

  const handleConfirmEdgeDeviceDeleted = () => {
    setShowEdgeDeviceWasDeletedModal(false);
    dispatch(edgeDevicesActions.selectEdgeDevice(null));
    dispatch(
      edgeDevicesActions.getAllEdgeDevices({
        page: currentPage,
        siteId: selectedSiteId,
      })
    );
    dispatch(edgeDevicesActions.setDeletedSuccessfully(null));
  };

  const onDeleteClick = () => {
    setShowDeleteEdgeDeviceModal(true);
  };

  const onCloseClick = () => {
    dispatch(edgeDevicesActions.selectEdgeDevice(null));
  };

  return (
    <>
      <div className="selected-edge-device-details-panel-header">
        <span className="selected-edge-device-details-panel-header-title">
          {translate("edgeDevices.selectedEdgeDevice.details")}
        </span>
        <div className="selected-edge-device-details-panel-header-buttons">
          <Button
            dataCr="delete-edge-device"
            color="primary"
            variant="contained"
            onClick={onDeleteClick}
            title={translate("delete", { ns: "Shared" })}
            icon
          >
            <DeleteRoundedIcon />
          </Button>
          <Button
            dataCr="close-edge-device"
            color="primary"
            variant="contained"
            onClick={onCloseClick}
            title={translate("close", { ns: "Shared" })}
            icon
          >
            <CloseRoundedIcon />
          </Button>
        </div>
      </div>
      <DeleteEdgeDeviceModal
        edgeDeviceName={edgeDeviceName}
        open={showDeleteEdgeDeviceModal}
        onCancelCallback={handleCancelDeleteEdgeDevice}
        onDeleteCallback={handleDeleteEdgeDevice}
        saving={loadingEdgeDevicesCrud}
      />
      <EdgeDeviceWasDeletedModal
        edgeDeviceName={edgeDeviceName}
        open={showEdgeDeviceWasDeletedModal}
        onConfirmCallback={handleConfirmEdgeDeviceDeleted}
      />
    </>
  );
};

export default SelectedEdgeDeviceDetailsHeader;
