import { useEffect } from "react";
import {
  Connection,
  Edge,
  EdgeChange,
  addEdge,
  useEdgesState,
} from "reactflow";
import { debounce } from "lodash";
// Models
import { OgTaskTypeDto } from "../../../../models/ogTaskTypes/OgTaskTypeDto";
import { OgTemplateDto } from "../../../../models/ogTemplate/OgTemplateDto";
// Helpers
import { obtainConnections } from "../helpers/connections";
import { initialEdge, newEdgeWithConnection } from "../helpers/edges";

const useEdges = (
  template?: OgTemplateDto
): [
  Edge<any>[],
  (edgeChanges: EdgeChange[]) => void,
  (connection: Connection) => Edge,
  (
    dataIds: string[],
    extraEdgeRemovedAction?: (removedEdge: Edge) => void
  ) => void
] => {
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[]>([]);
  const debouncedSetEdges = debounce(setEdges, 100);
  // Default implementation
  const onEdgesDelete = (
    dataIds: string[],
    extraEdgeRemovedAction?: (removedEdge: Edge) => void
  ): void => {
    setEdges((prevEdges) => {
      const newEdges = [];
      prevEdges.forEach((e) => {
        if (dataIds.includes(e.id)) extraEdgeRemovedAction?.(e);
        else newEdges.push(e);
      });
      return newEdges;
    });
  };

  const onAddEdge = (connection: Connection): Edge => {
    const newEdge = newEdgeWithConnection(connection);
    setEdges((prevEdges) => {
      return addEdge(newEdge, prevEdges);
    });
    return newEdge;
  };

  useEffect(() => {
    if (!template) return;
    const listWithInitialEdge = template?.entryPointId
      ? [initialEdge(template.entryPointId)]
      : [];

    const taskTypeList: OgTaskTypeDto[] = template?.taskTypeDTOList || [];
    debouncedSetEdges(
      taskTypeList?.reduce((acc, task) => {
        const buildedEdges: Edge[] = obtainConnections(task);
        if (buildedEdges && buildedEdges.length > 0) {
          acc = acc.concat(buildedEdges);
        }
        return acc;
      }, listWithInitialEdge)
    );
  }, [template]);

  return [edges, onEdgesChange, onAddEdge, onEdgesDelete];
};

// eslint-disable-next-line import/prefer-default-export
export { useEdges };
