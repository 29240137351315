import React from "react";
import { WeekDayPicker } from "dms-lib";
import { useI18n } from "compass-commons";
import { Control, Controller } from "react-hook-form";
import { RuleDto } from "../../../../../models/incidents/RuleDto";
import { FormErrors } from "../../../../subsystemConfigurator/components/connectionPropertiesPanel/models/SubsystemConnectionDataTypes";

interface BehaviourTriggerWeekDayProps {
  ruleId: string;
  readOnlyMode: boolean;
  control: Control<RuleDto, any>;
  errors?: FormErrors<RuleDto>;
  handleWeekDaysSelect: (weekDays: string[]) => void;
}

const BehaviourTriggerWeekDay = (
  props: BehaviourTriggerWeekDayProps
): JSX.Element => {
  const { ruleId, readOnlyMode, control, errors, handleWeekDaysSelect } = props;
  const { t: translate } = useI18n();

  return (
    <div className="config-incidents__trigger-weekdays__section">
      <span>{translate("behaviors.triggerFieldsDescription.weekDays")}</span>
      <Controller
        name="trigger.daysOfWeek"
        control={control}
        render={({ field: { value, ..._field } }) => {
          return (
            <div id={ruleId}>
              <WeekDayPicker
                key={ruleId}
                id={ruleId}
                dataCr="config-incidents__trigger-weekdays"
                disabled={readOnlyMode}
                displayLabel
                selectedWeekDays={
                  value ? value.filter((o) => o.trim().length > 0) : []
                }
                callBackHandler={handleWeekDaysSelect}
              />
            </div>
          );
        }}
      />
      {errors?.trigger?.daysOfWeek && (
        <div className="config-incidents__trigger-weekdays__section-error">
          {translate(errors?.trigger?.daysOfWeek?.message)}
        </div>
      )}
    </div>
  );
};

BehaviourTriggerWeekDay.defaultProps = {
  errors: undefined,
};

export default BehaviourTriggerWeekDay;
