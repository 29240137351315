import * as zod from "zod";
import { string } from "zod";

export const MIN_EDGE_DEVICE_NAME_LENGTH = 1;
export const MAX_EDGE_DEVICE_NAME_LENGTH = 25;

const EdgeDeviceStringConstraint = string()
  .min(MIN_EDGE_DEVICE_NAME_LENGTH, {
    message: "propertiesFieldsValidation.required",
  })
  .max(MAX_EDGE_DEVICE_NAME_LENGTH, {
    message: "propertiesFieldsValidation.maxSize",
  });

export const NewEdgeDeviceDTO = zod.object({
  name: EdgeDeviceStringConstraint,
});

export type NewEdgeDeviceDTO = zod.infer<typeof NewEdgeDeviceDTO>;
