import React from "react";
import { Menu, MenuItem, MenuList } from "@mui/material";
import { useI18n } from "compass-commons";
import "./colorLegendContextMenu.module.css";
import EdgeDeviceStatusConnectedIcon from "./status/EdgeDeviceStatusConnectedIcon";
import EdgeDeviceStatusDisconnectedIcon from "./status/EdgeDeviceStatusDisconnectedIcon";
import EdgeDeviceStatusUnregisteredIcon from "./status/EdgeDeviceStatusUnregisteredIcon";
import EdgeDeviceStatusUnknownIcon from "./status/EdgeDeviceStatusUnknownIcon";

interface ColorLegendContextMenuProps {
  open: boolean;
  handleClose: () => void;
  element: Element;
}

const ColorLegendContextMenu = ({
  open,
  handleClose,
  element,
}: ColorLegendContextMenuProps): JSX.Element => {
  const { t: translate } = useI18n();

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorEl={element}
      open={open}
      onClose={handleClose}
      className="color-legend-menu"
    >
      <MenuList className="color-legend-menu-list">
        <MenuItem disabled className="color-legend-menu-item">
          <EdgeDeviceStatusConnectedIcon />
          <span>{translate("edgeDevices.statusList.connected")}</span>
        </MenuItem>
        <MenuItem disabled className="color-legend-menu-item">
          <EdgeDeviceStatusDisconnectedIcon />
          <span>{translate("edgeDevices.statusList.disconnected")}</span>
        </MenuItem>
        <MenuItem disabled className="color-legend-menu-item">
          <EdgeDeviceStatusUnregisteredIcon />
          <span>{translate("edgeDevices.statusList.unregistered")}</span>
        </MenuItem>
        <MenuItem disabled className="color-legend-menu-item">
          <EdgeDeviceStatusUnknownIcon />
          <span>{translate("edgeDevices.statusList.unknown")}</span>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ColorLegendContextMenu;
