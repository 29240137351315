// General
import { useEffect, useRef, useState } from "react";
// Services
import SandboxIntegrationManagerService from "../../services/SandboxIntegrationManagerService";
// Models
import EdgeDeviceResponseDTO from "../../models/edgeDevices/EdgeDeviceResponseDTO";
import { EdgeDeviceDTO } from "../../models/edgeDevices/EdgeDeviceDTO";

const FETCH_INTERVAL = 10000;

const useFetchEdgeDevices = (
  selectedSiteId: string,
  edgeDevicesList: EdgeDeviceDTO[],
  totalPages: number,
  currentPage: number
): { edgeDevices: EdgeDeviceDTO[] } => {
  const [edgeDevices, setEdgeDevices] = useState<EdgeDeviceDTO[]>();
  const fetchEdgeDevicesSetTimeout = useRef<NodeJS.Timeout>();

  const parseEdgeDevicesResponse = (response: EdgeDeviceResponseDTO) => {
    const edgeDevicesReponse = response.edgeDevices;
    const edgeDevicesMapById = edgeDevicesList.reduce(
      (accumulator, edgeDevice) => {
        accumulator[edgeDevice.id] = edgeDevice;
        return accumulator;
      },
      {}
    );
    const newEdgeDevices = edgeDevicesReponse.filter((edgeDevice) =>
      Object.keys(edgeDevicesMapById).includes(edgeDevice.id)
    );
    if (JSON.stringify(newEdgeDevices) !== JSON.stringify(edgeDevicesList)) {
      setEdgeDevices(newEdgeDevices);
    }
  };

  useEffect(() => {
    if (totalPages === 0) {
      return;
    }
    let mounted = true;

    const fetchEdgeDevices = () => {
      SandboxIntegrationManagerService.getAllEdgeDevices(
        currentPage,
        selectedSiteId
      )
        .then((response) => {
          if (!mounted) {
            return;
          }
          parseEdgeDevicesResponse(response);
        })
        .finally(() => {
          if (!mounted) {
            return;
          }
          fetchEdgeDevicesSetTimeout.current = setTimeout(
            fetchEdgeDevices,
            FETCH_INTERVAL
          );
        });
    };

    fetchEdgeDevicesSetTimeout.current = setTimeout(
      fetchEdgeDevices,
      FETCH_INTERVAL
    );

    // eslint-disable-next-line consistent-return
    return () => {
      mounted = false;
      clearTimeout(fetchEdgeDevicesSetTimeout.current);
    };
  }, [selectedSiteId, edgeDevicesList, totalPages, currentPage]);

  return { edgeDevices };
};

export default useFetchEdgeDevices;
