// General
import React from "react";
import { useI18n } from "compass-commons";
// Styles
import "../IncidentTypeDrawer.module.css";
// Components
import { Button } from "dms-lib";
import {
  AddRounded,
  ArrowBack,
  KeyboardDoubleArrowRightRounded,
} from "@mui/icons-material";
// Models
import IncidentTypeDrawerState from "../../../models/incidents/IncidentTypeDrawerState";
import { IncidentTypeDto } from "../../../models/incidents/IncidentTypeDto";

interface IncidentTypesDrawerHeaderProps {
  closeDrawer: () => void;
  drawerState: IncidentTypeDrawerState;
  configDrawerState: (s: IncidentTypeDrawerState, i?: IncidentTypeDto) => void;
}

const IncidentTypesDrawerHeader = (
  props: IncidentTypesDrawerHeaderProps
): JSX.Element => {
  const { closeDrawer, configDrawerState, drawerState } = props;
  const { t: translate } = useI18n();

  const getTranslationKeyName = () => {
    switch (drawerState) {
      case IncidentTypeDrawerState.List:
        return "incidentTypes.incidentTypes";
      case IncidentTypeDrawerState.Add:
        return "incidentTypes.upsert.addIncidentType";
      case IncidentTypeDrawerState.Edit:
        return "incidentTypes.upsert.editIncidentType";
      case IncidentTypeDrawerState.Delete:
        return "incidentTypes.delete.title";
      default:
        return "incidentTypes.incidentTypes";
    }
  };

  return (
    <div className="config-incident-types__header">
      <div
        className="config-incident-types__header__button"
        data-cr="incident-types-header-button"
      >
        {drawerState === IncidentTypeDrawerState.List ? (
          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={closeDrawer}
            icon
          >
            <KeyboardDoubleArrowRightRounded />
          </Button>
        ) : (
          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={() => configDrawerState(IncidentTypeDrawerState.List)}
            icon
          >
            <ArrowBack />
          </Button>
        )}
      </div>
      <div
        className="config-incident-types__header__panel"
        data-cr="incident-types-header-panel"
      >
        <b className="config-incident-types__header__title">
          {translate(getTranslationKeyName())}
        </b>
        {drawerState === IncidentTypeDrawerState.List && (
          <span className="config-incident-types__header__subtitle">
            {translate("incidentTypes.globalSetting")}
          </span>
        )}
      </div>
      {drawerState === IncidentTypeDrawerState.List && (
        <div
          className="config-incident__types__header__add"
          data-cr="incident-types-header-add"
        >
          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={() => configDrawerState(IncidentTypeDrawerState.Add)}
            icon
          >
            <AddRounded />
          </Button>
        </div>
      )}
    </div>
  );
};

export default IncidentTypesDrawerHeader;
