// General
import React from "react";
import { useI18n } from "compass-commons";
import { useSelector } from "react-redux";
// Components
import { Button } from "dms-lib";
import { TextField } from "@mui/material";
// Store
import { editionMode } from "../../../../store/root";
// Styles
import "./templatesListView.module.css";
// Models
import { OgTemplateDto } from "../../../../models/ogTemplate/OgTemplateDto";
import useTabActions from "../../../../hooks/useTabActions";

interface TemplatesListViewProps {
  templates?: OgTemplateDto[];
  selectedOgTemplate?: OgTemplateDto;
  setSelectedOgTemplate?: (ogTemplate: OgTemplateDto) => void;
}

const TemplatesListView = (props: TemplatesListViewProps): JSX.Element => {
  const { templates, selectedOgTemplate, setSelectedOgTemplate } = props;
  const { setReadOnlyMode } = useTabActions();
  const isEditionMode = useSelector(editionMode);
  const { t } = useI18n();

  const getNoResultsDefault = (tag): JSX.Element => {
    return (
      <div
        data-cr="no-result-component"
        className="og-template-panel-no-results"
      >
        <span className="no-results-title">{t(tag)}</span>
      </div>
    );
  };

  const updateOgName = (event) => {
    const { value } = event.target;
    if (selectedOgTemplate) {
      setSelectedOgTemplate({ ...selectedOgTemplate, name: value });
    }
  };

  const templateItemClick = (item: OgTemplateDto) => {
    if (selectedOgTemplate && selectedOgTemplate?.id === item.id) return;
    setSelectedOgTemplate(JSON.parse(JSON.stringify(item)));
    setReadOnlyMode();
  };

  const getTemplatesView = () => {
    if (templates) {
      if (templates?.length > 0) {
        const items = templates.map((tp: OgTemplateDto, index) => (
          <Button
            color="primary"
            className="template-item"
            variant={tp.id === selectedOgTemplate?.id ? "contained" : "text"}
            dataCr="template-item"
            {...{ disableRipple: isEditionMode }}
            onClick={() => templateItemClick(tp)}
            id={`template-id-${tp.id}`}
            tabIndex={index}
          >
            {!isEditionMode || tp.id !== selectedOgTemplate?.id ? (
              <div className="og-text-field-placeholder">{tp.name}</div>
            ) : (
              <TextField
                className="compass-rounded-corner compass-text-field-input"
                data-cr="compass-text-field-input"
                size="small"
                placeholder={tp.name}
                autoFocus
                onChange={updateOgName}
                defaultValue={tp.name}
              />
            )}
          </Button>
        ));
        return (
          <div
            data-cr="template-list-component"
            className="template-list-component"
          >
            <div data-cr="template-list-items" className="template-list">
              {...items}
            </div>
          </div>
        );
      }
      return getNoResultsDefault("operatorGuide.failToFindOgs");
    }
    return getNoResultsDefault("operatorGuide.noOgs");
  };
  return <div className="template-list-root">{getTemplatesView()}</div>;
};

TemplatesListView.defaultProps = {
  templates: null,
  selectedOgTemplate: null,
  setSelectedOgTemplate: () => null,
};

export default TemplatesListView;
