// General
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "compass-commons";
// Components
import { Button, Dialog, OptionType } from "dms-lib";
import AddEdgeDeviceIntegrationModalContent from "./AddEdgeDeviceIntegrationModalContent";
// Models
import { NewEdgeDeviceIntegrationDTO } from "../../../models/edgeDevices/NewEdgeDeviceIntegrationDTO";
import { DriverDataDTO } from "../../../models/edgeDevices/DriverDataDTO";
// Styles
import "./addEdgeDeviceIntegrationModal.module.css";
// Store
import { selectEdgeDevices, useStoreDispatch } from "../../../store";
import { edgeDevicesActions } from "../../../store/edgeDevices";

interface AddEdgeDeviceIntegrationModalProps {
  open: boolean;
  onCancelCallback: () => void;
  onSaveCallback: (integration: NewEdgeDeviceIntegrationDTO) => void;
  saving: boolean;
}

const AddEdgeDeviceIntegrationModal = ({
  open,
  onCancelCallback,
  onSaveCallback,
  saving,
}: AddEdgeDeviceIntegrationModalProps): JSX.Element => {
  const { t: translate } = useI18n();
  const dispatch = useStoreDispatch();
  const { loadingDriversList, driversList, edgeDevicesError } =
    useSelector(selectEdgeDevices);
  const [selectedDriver, setSelectedDriver] = useState<DriverDataDTO>(null);
  const [selectedIntegration, setSelectedIntegration] =
    useState<OptionType>(null);
  const [integrationsList, setIntegrationsList] = useState<OptionType[]>([]);
  const [versionsList, setVersionsList] = useState<DriverDataDTO[]>([]);
  const [integrationName, setIntegrationName] = useState("");

  useEffect(() => {
    setIntegrationsList(
      driversList.map((driver) => {
        return {
          id: driver.name,
        };
      })
    );
  }, [driversList]);

  useEffect(() => {
    if (open) {
      dispatch(edgeDevicesActions.getAllDrivers());
    }
  }, [open]);

  useEffect(() => {
    if (open && edgeDevicesError) {
      onCancelCallback();
    }
  }, [edgeDevicesError]);

  useEffect(() => {
    if (selectedIntegration) {
      const filteredDriver = driversList.filter(
        (driver) => driver.name === selectedIntegration.id
      )[0];
      setVersionsList(filteredDriver.data);
    } else {
      setVersionsList([]);
    }
  }, [selectedIntegration]);

  const handleSelectIntegration = (option: OptionType) => {
    setSelectedIntegration(option);
    if (!option) {
      setSelectedDriver(null);
    }
  };

  const handleSelectVersion = (option: DriverDataDTO) => {
    setSelectedDriver(option);
  };

  const handleSave = () => {
    onSaveCallback({
      name: integrationName,
      driverId: selectedDriver.id,
    });
  };

  return (
    <Dialog
      id="add-edge-device-integration-modal"
      open={open}
      size="xs"
      dialogContent={
        <AddEdgeDeviceIntegrationModalContent
          integrationName={integrationName}
          onIntegrationNameChange={(newIntegrationName) =>
            setIntegrationName(newIntegrationName)
          }
          integrationsList={integrationsList}
          selectedIntegration={selectedIntegration}
          onSelectedIntegrationChange={handleSelectIntegration}
          loadingDriversList={loadingDriversList}
          versionsList={versionsList}
          selectedVersion={selectedDriver}
          onSelectedVersionChange={handleSelectVersion}
        />
      }
      dialogActions={
        <>
          <Button
            dataCr="cancel-add-edge-device-integration"
            color="primary"
            variant="contained"
            onClick={onCancelCallback}
            disabled={saving}
          >
            {translate("cancel", { ns: "Shared" })}
          </Button>
          <Button
            dataCr="save-add-edge-device-integration"
            color="secondary"
            variant="outlined"
            onClick={handleSave}
            disabled={saving || integrationName.length === 0 || !selectedDriver}
          >
            {translate("add", { ns: "Shared" })}
          </Button>
        </>
      }
    />
  );
};

export default AddEdgeDeviceIntegrationModal;
