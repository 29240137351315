// General
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { safelyFormatDate, useGetToken, useI18n } from "compass-commons";
// Components
import { CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import EdgeDeviceList from "./list/EdgeDeviceList";
import EdgeDevicesPanelHeader from "./EdgeDevicesPanelHeader";
import EdgeDevicesPanelFooter from "./EdgeDevicesPanelFooter";
// Styles
import "./edgeDevicesPanel.module.css";
// Store
import { selectEdgeDevices, selectSites, useStoreDispatch } from "../../store";
import { edgeDevicesActions } from "../../store/edgeDevices";
import { useStateContext } from "../../contexts/StateContext";
// Models
import { EdgeDevice } from "../../models/edgeDevices/EdgeDeviceDTO";
// Router
import { SelectedEdgeDeviceMenuTabRoute } from "../../router/SelectedEdgeDevicePanelRoutes";
// Services
import StateService from "../../services/StateService";
import {
  DATE_FORMAT,
  SELF_INTEGRATIONS_PORTAL_FEATURE_FLAG,
} from "../../utils/Constants";
import EdgeDeviceStatus from "../../models/edgeDevices/EdgeDeviceStatus";
import EdgeDeviceStatusConnectedIcon from "./status/EdgeDeviceStatusConnectedIcon";
import EdgeDeviceStatusDisconnectedIcon from "./status/EdgeDeviceStatusDisconnectedIcon";
import EdgeDeviceStatusUnregisteredIcon from "./status/EdgeDeviceStatusUnregisteredIcon";
import EdgeDeviceStatusUnknownIcon from "./status/EdgeDeviceStatusUnknownIcon";
import useFetchEdgeDevices from "../../hooks/useFetchEdgeDevices";

interface EdgeDevicesPanelProps {
  isFeatureEnabledFunc: (featureKey: string) => Promise<any>;
}

const EdgeDevicesPanel = ({
  isFeatureEnabledFunc,
}: EdgeDevicesPanelProps): JSX.Element => {
  const { t: translate } = useI18n();
  const stateService: StateService = useStateContext();
  const { alertSubject } = stateService;
  const dispatch = useStoreDispatch();
  const {
    selectedEdgeDevice,
    edgeDevicesList,
    loadingEdgeDevicesList,
    edgeDevicesError,
    currentPage,
  } = useSelector(selectEdgeDevices);
  const { selectedSiteId } = useSelector(selectSites);
  const { totalPages } = useSelector(selectEdgeDevices);
  const [edgeDevices, setEdgeDevices] = useState<EdgeDevice[]>([]);
  const [query, setQuery] = useState("");
  const params = useParams();
  const token = useGetToken();

  useMemo(() => {
    if (params["*"] !== "") {
      const paramsElements = params["*"].split("/");
      dispatch(edgeDevicesActions.selectEdgeDevice(paramsElements[0]));
      let selectedEdgeDeviceSubTab = SelectedEdgeDeviceMenuTabRoute.Details;
      if (
        Object.values(SelectedEdgeDeviceMenuTabRoute).includes(
          paramsElements[1] as SelectedEdgeDeviceMenuTabRoute
        )
      ) {
        selectedEdgeDeviceSubTab =
          paramsElements[1] as SelectedEdgeDeviceMenuTabRoute;
      }
      dispatch(
        edgeDevicesActions.setSelectedEdgeDeviceSubTab(selectedEdgeDeviceSubTab)
      );
    }
  }, []);
  const dmsNavigate = useNavigate();
  const edgeDeviceStatusIconMap = {
    [EdgeDeviceStatus.CONNECTED]: <EdgeDeviceStatusConnectedIcon />,
    [EdgeDeviceStatus.DISCONNECTED]: <EdgeDeviceStatusDisconnectedIcon />,
    [EdgeDeviceStatus.UNREGISTERED]: <EdgeDeviceStatusUnregisteredIcon />,
    [EdgeDeviceStatus.UNKNOWN]: <EdgeDeviceStatusUnknownIcon />,
  };
  const { edgeDevices: hookEdgeDevices } = useFetchEdgeDevices(
    selectedSiteId,
    edgeDevicesList,
    totalPages,
    currentPage
  );

  useEffect(() => {
    if (hookEdgeDevices?.length > 0) {
      dispatch(edgeDevicesActions.updateEdgeDevicesList(hookEdgeDevices));
    }
  }, [hookEdgeDevices]);

  useEffect(() => {
    isFeatureEnabledFunc(SELF_INTEGRATIONS_PORTAL_FEATURE_FLAG).then(
      (enabled) => {
        if (!enabled) {
          dmsNavigate("../", { relative: "path" });
        }
      }
    );
  }, [token]);

  const errorMessageCallback = useCallback((msg: string) => {
    if (msg) {
      alertSubject.next({ title: msg });
    }
  }, []);

  useEffect(() => {
    if (!edgeDevicesError) return;
    errorMessageCallback(
      `${translate(edgeDevicesError.label)} - ${edgeDevicesError.message}`
    );
  }, [edgeDevicesError]);

  const onSearchChanged = (newValue: string) => {
    setQuery(newValue);
  };

  const deselectEdgeDevice = () => {
    dispatch(edgeDevicesActions.selectEdgeDevice(null));
  };

  const onPageChanged = (selectedPage: number) => {
    if (currentPage !== selectedPage) {
      deselectEdgeDevice();
      dispatch(edgeDevicesActions.changePage(selectedPage));
    }
  };

  const parseEdgeDeviceList = (): EdgeDevice[] => {
    return edgeDevicesList.map((edgeDevice) => ({
      status:
        edgeDeviceStatusIconMap[EdgeDeviceStatus[edgeDevice.status]] ??
        edgeDeviceStatusIconMap[EdgeDeviceStatus.UNKNOWN],
      id: edgeDevice.id,
      name: edgeDevice.name,
      createdDate: safelyFormatDate(edgeDevice.createdDate, DATE_FORMAT),
      createdBy: edgeDevice.createdBy,
      lastModifiedDate: safelyFormatDate(
        edgeDevice.lastModifiedDate,
        DATE_FORMAT
      ),
      lastModifiedBy: edgeDevice.lastModifiedBy,
    }));
  };

  const filterEdgeDevices = (filter: string) => {
    const parsedEdgeDeviceList = parseEdgeDeviceList();
    if (parsedEdgeDeviceList.length === 0) {
      setEdgeDevices([]);
      return;
    }
    let filteredContainsSelectedEdgeDevice = false;
    const filteredEdgeDevices = filter.length
      ? parsedEdgeDeviceList?.filter((edgeDevice) => {
          if (edgeDevice.name?.toLowerCase().includes(filter.toLowerCase())) {
            if (
              !filteredContainsSelectedEdgeDevice &&
              edgeDevice.id === selectedEdgeDevice
            ) {
              filteredContainsSelectedEdgeDevice = true;
            }
            return true;
          }
          return false;
        })
      : parsedEdgeDeviceList;
    if (filter.length && !filteredContainsSelectedEdgeDevice) {
      dispatch(edgeDevicesActions.selectEdgeDevice(null));
    }
    setEdgeDevices(filteredEdgeDevices);
  };

  useEffect(() => {
    filterEdgeDevices(query);
  }, [query, edgeDevicesList]);

  const loadEdgeDevices = () => {
    if (selectedSiteId) {
      dispatch(
        edgeDevicesActions.getAllEdgeDevices({
          page: currentPage,
          siteId: selectedSiteId,
        })
      );
    }
  };

  useEffect(() => {
    loadEdgeDevices();

    return () => {
      dispatch(edgeDevicesActions.setSelectedEdgeDeviceSubTab(null));
      dispatch(edgeDevicesActions.selectEdgeDevice(null));
    };
  }, [currentPage, selectedSiteId]);

  return (
    <section className="edge-devices-content__panel">
      {loadingEdgeDevicesList && (
        <div data-cp="player-spinner" className="edge-devices-loading">
          <CircularProgress color="inherit" />
        </div>
      )}
      {!loadingEdgeDevicesList && (
        <>
          <EdgeDevicesPanelHeader
            query={query}
            onSearchChangedCallback={onSearchChanged}
          />
          <EdgeDeviceList edgeDevices={edgeDevices} />
          {edgeDevices.length > 0 && (
            <EdgeDevicesPanelFooter
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChangedCallback={onPageChanged}
            />
          )}
        </>
      )}
    </section>
  );
};

export default EdgeDevicesPanel;
