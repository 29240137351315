// General
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import {
  calculateImageCorners,
  FloorPlanDevice,
  ImageCorners,
} from "compass-commons";
import { useSelector } from "react-redux";
// Components
import { SizeMe } from "react-sizeme";
// Styles
import "mapbox-gl/dist/mapbox-gl.css";
import "./mapComponent.module.css";
// Store
import { IncidentIcon } from "dms-lib";
import { readOnlyMode } from "../../../../../store/root";
// Utils
import { DEFAULT_ICON_IMAGE_PLACEHOLDER } from "../../../utils/Settings";

interface MapComponentProps {
  resources?: FloorPlanDevice[];
  isTest?: boolean;
  selectedResource?: FloorPlanDevice;
  updateDeviceCoordinate?: (x, y) => void;
  isGeographical?: boolean;
  showLabels?: boolean;
  overrideInitialZoom?: number;
  overrideInitialCenter?: [number, number];
  handleZoomUpdate?: (zoom, center) => void;
  isPlaceable?: boolean;
  imageInfo?: { url: string; width: number; height: number };
}

const MapComponent = (props: MapComponentProps): JSX.Element => {
  const {
    resources,
    isTest,
    selectedResource,
    updateDeviceCoordinate,
    isGeographical,
    showLabels,
    overrideInitialZoom,
    overrideInitialCenter,
    handleZoomUpdate,
    isPlaceable,
    imageInfo,
  } = props;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const DEFAULT_LNG = 0;
  const DEFAULT_LAT = 0;
  const DEFAULT_ZOOM = 15; // 4.8;
  const initialZoom = (isGeographical && overrideInitialZoom) || DEFAULT_ZOOM;

  const isReadOnlyMode = useSelector(readOnlyMode);

  const getMapStyle = () => {
    if (isGeographical) {
      return "mapbox://styles/mapbox/light-v10";
    }

    return {
      version: 8,
      sources: {},
      layers: [
        {
          id: "background",
          type: "background",
          paint: {
            "background-color": "white",
          },
        },
      ],
    };
  };

  const getCenter = (imageCoordinates: ImageCorners) => {
    return (
      (isGeographical && overrideInitialCenter) || [
        imageCoordinates
          ? DEFAULT_LNG + imageCoordinates.topRight.lng / 2
          : DEFAULT_LNG,
        imageCoordinates
          ? DEFAULT_LAT + imageCoordinates.topRight.lat / 2
          : DEFAULT_LAT,
      ]
    );
  };

  const createMapbox = (imageCoordinates: ImageCorners) => {
    if (!isTest) {
      mapboxgl.accessToken = appConfig.MAPBOX_TOKEN;
    }

    const mb = new mapboxgl.Map({
      attributionControl: false,
      container: mapContainer.current,
      testMode: isTest,
      interactive: !isTest && !isReadOnlyMode,
      center: getCenter(imageCoordinates),
      style: getMapStyle(),
      zoom: initialZoom,
      minZoom: 1,
      dragRotate: false,
    });
    mb.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
    return mb;
  };

  const setMapboxFloorPlanImage = (mapbox, imageCoordinates: ImageCorners) => {
    const floorCoordinatesByImage = [
      [imageCoordinates.topLeft.lng, imageCoordinates.topLeft.lat],
      [imageCoordinates.topRight.lng, imageCoordinates.topRight.lat],
      [imageCoordinates.bottomRight.lng, imageCoordinates.bottomRight.lat],
      [imageCoordinates.bottomLeft.lng, imageCoordinates.bottomLeft.lat],
    ];

    mapbox.on("load", () => {
      mapbox.addSource("radar", {
        type: "image",
        url: imageInfo.url,
        coordinates: floorCoordinatesByImage,
      });
      mapbox.addLayer({
        id: "radar-layer",
        type: "raster",
        source: "radar",
        paint: {
          "raster-fade-duration": 0,
        },
      });
    });
  };

  const setMapboxListeners = (mb) => {
    mb.on("zoom", () => {
      handleZoomUpdate(mb.getZoom(), mb.getCenter());
    });
    mb.on("move", () => {
      handleZoomUpdate(mb.getZoom(), mb.getCenter());
    });
  };

  const createNewMarker = (mapbox: mapboxgl.Map, markerEl: HTMLElement) => {
    const deviceCoords = mapbox.getCenter();
    const marker: mapboxgl.Marker = new mapboxgl.Marker(markerEl).setLngLat(
      deviceCoords
    );
    selectedResource.xCoordinate = String(deviceCoords?.lng);
    selectedResource.yCoordinate = String(deviceCoords?.lat);
    selectedResource.deviceOnMap = isGeographical;
    updateDeviceCoordinate(deviceCoords?.lng, deviceCoords?.lat);
    return marker;
  };

  const createMarkerElement = (dev: FloorPlanDevice) => {
    const el: HTMLElement = document.createElement("div");
    el.className = `map-marker ${
      selectedResource?.id === dev.id && "map-marker-selected"
    }`;
    el.setAttribute("data-cr", `mapbox-device-${dev.id}`);

    try {
      ReactDOM.render(
        <>
          <IncidentIcon
            id={`device-${dev.id}`}
            key={`device-${dev?.resourceMappingId}`}
            src={dev?.icon || DEFAULT_ICON_IMAGE_PLACEHOLDER}
            className="config-resource__marker--blue"
            priority="warning"
            size="large"
          />
          {showLabels && (
            <div
              data-cr={`mapbox-device-label-${dev.id}`}
              className="device-marker-label"
            >
              {dev?.name}
            </div>
          )}
        </>,
        el
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        `Failed to create device with id: ${dev?.resourceMappingId}`
      );
    }

    return el;
  };

  const setDraggableMarker = (marker) => {
    function onDragEng(ev) {
      const m: mapboxgl.Marker = ev.target;
      const lngLat = m.getLngLat();
      if (lngLat?.lat && lngLat?.lng) {
        selectedResource.xCoordinate = String(lngLat.lng);
        selectedResource.yCoordinate = String(lngLat.lat);
        updateDeviceCoordinate(lngLat.lng, lngLat.lat);
      }
    }

    marker.setDraggable(!isReadOnlyMode);
    marker.on("dragend", onDragEng);
  };

  const isValidToDisplay = (dev: FloorPlanDevice) => {
    // Removing resource from floor plan means
    // Device can still have floor plan id or deviceOnMap true
    // Device's coordinates can be null
    // It simply disappears from map but association to Floor Plan or MapView still maintains

    if (
      (dev.floorPlanId != null || dev.deviceOnMap) &&
      selectedResource.id !== dev.id &&
      dev.xCoordinate === null &&
      dev.yCoordinate === null
    ) {
      return false;
    }

    if (selectedResource && !isPlaceable && selectedResource.id === dev.id) {
      return false;
    }

    return true;
  };

  const placeDevicesOnFloorPlan = (mapbox) => {
    if (selectedResource) {
      resources?.forEach((dev) => {
        if (!isValidToDisplay(dev)) return;
        const el: HTMLElement = createMarkerElement(dev);
        if ((isGeographical && dev?.deviceOnMap) || !isGeographical) {
          let marker: mapboxgl.Marker;

          if (Number(dev.xCoordinate) && Number(dev.yCoordinate)) {
            const deviceCoords = [
              Number(dev.xCoordinate),
              Number(dev.yCoordinate),
            ];
            marker = new mapboxgl.Marker(el).setLngLat(deviceCoords);
          } else {
            marker = createNewMarker(mapbox, el);
          }

          if (selectedResource?.id === dev?.id) {
            setDraggableMarker(marker);
          }

          // when we place the marker without changing its location, it overlaps with other markers,
          // and overlapping markers do not let drag drop the others
          // if we have overlapping markers we hide others.
          if (
            selectedResource?.id !== dev?.id &&
            Number(selectedResource.xCoordinate) === Number(dev.xCoordinate) &&
            Number(selectedResource.yCoordinate) === Number(dev.yCoordinate)
          ) {
            el.className = `${el.className} hide`;
          }

          marker.addTo(mapbox);
        }
      });
    }
  };

  const loadMapBox = () => {
    if (mapContainer?.current) {
      let imageCoordinates = null;
      if (imageInfo) {
        imageCoordinates = calculateImageCorners(
          { lng: DEFAULT_LNG, lat: DEFAULT_LAT },
          initialZoom,
          {
            width: imageInfo.width,
            height: imageInfo.height,
          }
        );
      }
      const mapbox = createMapbox(imageCoordinates);
      if (!isGeographical && imageInfo) {
        setMapboxFloorPlanImage(mapbox, imageCoordinates);
      }
      placeDevicesOnFloorPlan(mapbox);
      setMapboxListeners(mapbox);
      map.current = mapbox;
    }
  };

  useEffect(() => {
    if (map.current) {
      return;
    } // initialize map only once
    loadMapBox();
  }, [mapContainer]);

  return (
    <>
      {/* Mapbox doesn't support being wrapped by sizeMe,
      therefore we use a variable change to perform map.resize() */}
      <SizeMe>
        {() => {
          if (map?.current) {
            map.current.resize();
          }
          return <div />;
        }}
      </SizeMe>
      <div
        data-cr="mapbox-container"
        ref={mapContainer}
        className="map-container compass-rounded-corner compass-fade-in-smooth"
      />
    </>
  );
};

MapComponent.defaultProps = {
  resources: null,
  isTest: false,
  selectedResource: null,
  updateDeviceCoordinate: () => null,
  handleZoomUpdate: () => null,
  isGeographical: false,
  showLabels: false,
  overrideInitialZoom: null,
  overrideInitialCenter: null,
  isPlaceable: true,
  imageInfo: null,
};

export default MapComponent;
