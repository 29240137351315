// General
import React from "react";
import { Control, Controller } from "react-hook-form";
import {
  useI18n,
  capitalizeFirstLetter,
  getBaseColor,
  incidentPriority,
} from "compass-commons";
// Styles
import "../behaviourProperties.module.css";
// Components
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
// Models
import { RuleDto } from "../../../../../models/incidents/RuleDto";

interface BehaviourPropertiesPriorityProps {
  readOnlyMode: boolean;
  control: Control<RuleDto, any>;
  priorities: string[];
  dataCr?: string;
}

const PriorityRadioLabel = ({ priority }: { priority: string }) => {
  return (
    <div className="config-incidents__properties__priority__radio-label">
      <div
        className="radio-label__color-box"
        style={{
          backgroundColor: getBaseColor(priority),
        }}
      />
      <span>{capitalizeFirstLetter(priority)}</span>
    </div>
  );
};

const BehaviourPropertiesPriority = (
  props: BehaviourPropertiesPriorityProps
): JSX.Element => {
  const { t: translate } = useI18n();
  const { readOnlyMode, control, priorities, dataCr } = props;

  return (
    <div>
      <div className="config-incidents__properties__input">
        <span>
          {translate("behaviors.propertyFieldsDescription.priority")}:
        </span>
        <div
          className="config-incidents__properties__priority"
          id="input-wrapper"
        >
          <Controller
            name="behaviour.incidentPriority"
            control={control}
            render={({ field: { onChange, value, ...field } }) => {
              return (
                <RadioGroup
                  {...field}
                  aria-labelledby="config-incidents-properties-priority"
                  name="config-incidents-properties-priority"
                  className="config-incidents__properties__priority__radio"
                  id="behaviour-property-priority"
                  data-cr={dataCr}
                  value={
                    value !== ""
                      ? value
                      : incidentPriority.WARNING.toUpperCase()
                  }
                  onChange={(data, _ev) => {
                    onChange(data);
                  }}
                >
                  {priorities?.map((priority) => (
                    <FormControlLabel
                      value={priority?.toUpperCase()}
                      control={<Radio size="small" />}
                      label={<PriorityRadioLabel priority={priority} />}
                      key={priority}
                      id={`behaviour-property-priority-${priority}`}
                      disabled={readOnlyMode}
                    />
                  ))}
                </RadioGroup>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};
BehaviourPropertiesPriority.defaultProps = {
  dataCr: "behaviour-properties-priority",
};

export default React.memo(BehaviourPropertiesPriority);
