// General
import React from "react";
import { useI18n } from "compass-commons";
import { Button } from "dms-lib";
// Styles
import "./behaviourDialogActions.module.css";

interface BehaviourDialogActionsProps {
  onCancel: (event?: any) => void;
  onSuccess: (event?: any) => void;
}

const BehaviourDialogActions = ({
  onCancel,
  onSuccess,
}: BehaviourDialogActionsProps): JSX.Element => {
  const { t } = useI18n();
  return (
    <div className="behaviour-dialog-actions__wrapper">
      <Button
        dataCr="cancel-behaviour-delete"
        color="primary"
        variant="contained"
        onClick={onCancel}
      >
        {t("behaviors.deleteModal.button.cancel")}
      </Button>
      <Button
        dataCr="confirm-behaviour-delete"
        color="secondary"
        variant="outlined"
        onClick={onSuccess}
      >
        {t("behaviors.deleteModal.button.delete")}
      </Button>
    </div>
  );
};

export default BehaviourDialogActions;
