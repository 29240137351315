// General
import React, { useCallback, useEffect } from "react";
import { FloorPlanDevice, useI18n } from "compass-commons";
import { useSelector } from "react-redux";
// Components
import { Button } from "dms-lib";
import { LocationOnRounded } from "@mui/icons-material";
import CollapsiblePanel from "../../../commons/collapsiblePanel";
import CompassInfiniteList from "../../../commons/compassInfiniteList/CompassInfiniteList";
// Styles
import "./resourceListPanel.module.css";
// Store
import {
  selectResources,
  selectSites,
  useStoreDispatch,
} from "../../../../store";
import { resourcesActions } from "../../../../store/resources";
// Utils
import { debounce } from "../../../../utils/Util";
import { DEFAULT_DEBOUNCE_TIMEOUT } from "../../utils/constants";

const ResourceListPanel = (): JSX.Element => {
  const { t: translate } = useI18n();

  // Redux
  const {
    resourcesList,
    selectedResource,
    resourceListPageIndex,
    resourceListFilter,
    isResourceListLastPage,
    loadingResourcesList,
  } = useSelector(selectResources);
  const dispatch = useStoreDispatch();
  const { selectedSiteId: siteId } = useSelector(selectSites);

  useEffect(() => {
    if (!siteId) return;
    dispatch(resourcesActions.resetState());
    dispatch(
      resourcesActions.getAllResources({
        siteId,
        pageIndex: 0,
        filterName: undefined,
      })
    );
    dispatch(
      resourcesActions.getAllGeoReferencedResources({
        siteId,
      })
    );
  }, [siteId]);

  useEffect(() => {
    if (!siteId) return;
    const args = {
      siteId,
      pageIndex: resourceListPageIndex,
      filterName: resourceListFilter,
    };

    if (resourceListPageIndex === 0) {
      dispatch(resourcesActions.getAllResources(args));
    } else {
      dispatch(resourcesActions.getNextResources(args));
    }
  }, [resourceListPageIndex, resourceListFilter]);

  const searchCallback = (filter: string) => {
    dispatch(resourcesActions.setResourcesListFilter(filter));
  };

  const selectResourceCallback = (res: FloorPlanDevice) => {
    dispatch(
      resourcesActions.getResourceByResourceMappingId({
        siteId,
        resourceMappingId: res.resourceMappingId,
      })
    );
  };

  const fetchNextResourcesPage = () => {
    dispatch(resourcesActions.nextResourcesListPage());
  };

  const getDataElement = useCallback(
    (element: FloorPlanDevice): JSX.Element => (
      <Button
        dataCr={`resource-list-item-${element.id}`}
        className={`config-resources-list__item${
          element.id === selectedResource?.id ? " selected" : ""
        }`}
        key={element.id}
        color="primary"
        variant="text"
        onClick={() => selectResourceCallback(element)}
        title={element.name}
      >
        <div className="config-resources-list__location-selected-pin">
          {element.xCoordinate && element.yCoordinate && (
            <LocationOnRounded id="select_pos" />
          )}
        </div>
        <div className="config-resource-list__wrapper">
          <span
            className="config-resources-list__resource"
            style={{ flex: "1" }}
            title={`${element.name}`}
          >
            {element.name}
          </span>
          <div
            className={`config-resources-list-el-subsys${
              element.id === selectedResource?.id ? " selected" : ""
            }`}
          >
            <span title={`${element.subsystemName}`}>
              {element.subsystemName}
            </span>
          </div>
        </div>
      </Button>
    ),
    [selectedResource, selectResourceCallback]
  );

  const LoadingResources = useCallback(() => {
    return (
      <div className="config-resources-list--loading">
        {translate("loadingContent")}
      </div>
    );
  }, []);

  return (
    <CollapsiblePanel
      dataCr="devices-list"
      searchBar={{
        dataCr: "devices-search-bar",
        searchCallback: debounce(searchCallback, DEFAULT_DEBOUNCE_TIMEOUT),
        placeholderLabel: translate("devices.search", {}),
      }}
    >
      <div
        className="config-resources-list-in"
        data-cr="device-config-list-items"
        id="device-config-list-items"
      >
        <div className="config-resource-list-panel-label">
          <span>{translate("devices.resources", {})}</span>
          <span>{translate("devices.subsystems", {})}</span>
        </div>
        {loadingResourcesList ? (
          <LoadingResources />
        ) : (
          <CompassInfiniteList
            data={resourcesList}
            emptyMessage={translate("devices.noResources", {})}
            endMessage={translate("devices.finishedLoading")}
            isLastPage={isResourceListLastPage}
            dataCr="resource-list"
            fetchNextData={fetchNextResourcesPage}
            dataElement={getDataElement}
            scrollableTarget="config-collapsible__content"
          />
        )}
      </div>
    </CollapsiblePanel>
  );
};

export default ResourceListPanel;
