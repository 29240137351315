// Generic
import { HttpClient } from "compass-shared-services";
import { getUrl } from "compass-commons";
import EdgeDeviceResponseDTO from "../models/edgeDevices/EdgeDeviceResponseDTO";
import { buildErrorMessage } from "../utils/Util";
import { EdgeDeviceIntegrationDTO } from "../models/edgeDevices/EdgeDeviceIntegrationDTO";
import { NewEdgeDeviceIntegrationDTO } from "../models/edgeDevices/NewEdgeDeviceIntegrationDTO";
import { DriversResponseDTO } from "../models/edgeDevices/DriversResponseDTO";
import { EdgeDeviceIntegrationsResponseDTO } from "../models/edgeDevices/EdgeDeviceIntegrationsResponseDTO";

const SANDBOX_INTEGRATION_MANAGER_URL =
  appConfig.SANDBOX_INTEGRATION_MANAGER_PATH;
const httpClient = new HttpClient(appConfig);

const URL_PATHS = {
  GET_ALL_EDGE_DEVICES: `${SANDBOX_INTEGRATION_MANAGER_URL}/edgeDevices/?page=(page)&siteId=(siteId)`,
  CREATE_EDGE_DEVICE: `${SANDBOX_INTEGRATION_MANAGER_URL}/edgeDevices/`,
  DELETE_EDGE_DEVICE: `${SANDBOX_INTEGRATION_MANAGER_URL}/edgeDevices/(edgeDeviceId)`,
  GET_EDGE_DEVICE_INTEGRATIONS: `${SANDBOX_INTEGRATION_MANAGER_URL}/edgeDevices/(edgeDeviceId)/integrations/`,
  GET_EDGE_DEVICE_TOKEN: `${SANDBOX_INTEGRATION_MANAGER_URL}/edgeDevices/(edgeDeviceId)/token/`,
  GET_AVAILABLE_DRIVERS: `${SANDBOX_INTEGRATION_MANAGER_URL}/drivers/`,
  ADD_EDGE_DEVICE_INTEGRATION: `${SANDBOX_INTEGRATION_MANAGER_URL}/edgeDevices/(edgeDeviceId)/integrations/`,
};

class SandboxIntegrationManagerService {
  static async getAllEdgeDevices(
    page: number,
    siteId: string
  ): Promise<EdgeDeviceResponseDTO> {
    const uri = {
      page,
      siteId,
    };
    const urlPath = getUrl(URL_PATHS.GET_ALL_EDGE_DEVICES, uri);
    return httpClient
      .get<EdgeDeviceResponseDTO>({
        url: urlPath,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async createEdgeDevice(
    edgeDeviceName: string,
    siteId: string
  ): Promise<EdgeDeviceResponseDTO> {
    return httpClient
      .post<EdgeDeviceResponseDTO>({
        url: URL_PATHS.CREATE_EDGE_DEVICE,
        payload: JSON.stringify({ name: edgeDeviceName, siteId }),
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async deleteEdgeDevice(edgeDeviceId: string): Promise<void> {
    const uri = {
      edgeDeviceId,
    };
    const urlPath = getUrl(URL_PATHS.DELETE_EDGE_DEVICE, uri);
    return httpClient
      .delete<void>({
        url: urlPath,
      })
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async getEdgeDeviceIntegrations(
    edgeDeviceId: string
  ): Promise<EdgeDeviceIntegrationsResponseDTO> {
    const uri = {
      edgeDeviceId,
    };
    const urlPath = getUrl(URL_PATHS.GET_EDGE_DEVICE_INTEGRATIONS, uri);
    return httpClient
      .get<EdgeDeviceIntegrationsResponseDTO>({
        url: urlPath,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async getAllDrivers(): Promise<DriversResponseDTO> {
    return httpClient
      .get<DriversResponseDTO>({
        url: URL_PATHS.GET_AVAILABLE_DRIVERS,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async getEdgeDeviceToken(edgeDeviceId: string): Promise<string> {
    const uri = {
      edgeDeviceId,
    };
    const urlPath = getUrl(URL_PATHS.GET_EDGE_DEVICE_TOKEN, uri);
    return httpClient
      .get<string>({
        url: urlPath,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async addEdgeDeviceIntegration(
    edgeDeviceId: string,
    integration: NewEdgeDeviceIntegrationDTO
  ): Promise<EdgeDeviceIntegrationDTO> {
    const uri = {
      edgeDeviceId,
    };
    const urlPath = getUrl(URL_PATHS.GET_EDGE_DEVICE_INTEGRATIONS, uri);
    return httpClient
      .post<EdgeDeviceIntegrationDTO>({
        url: urlPath,
        payload: JSON.stringify(integration),
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }
}

export default SandboxIntegrationManagerService;
