import React from "react";
import { useSelector } from "react-redux";
import { Button } from "dms-lib";
import { DeleteRounded, LocationOnRounded } from "@mui/icons-material";
import { readOnlyMode } from "../../../../store/root";

interface ResourceContentHeaderActionsProps {
  isSelectPositionEnabled?: boolean;
  isDeleteDeviceEnabled?: boolean;
  translation?: (text: string) => string;
  handlePlace: () => void;
  handleDelete: () => void;
}

const ResourceContentHeaderActions = (
  props: ResourceContentHeaderActionsProps
): JSX.Element => {
  const {
    isSelectPositionEnabled,
    isDeleteDeviceEnabled,
    handlePlace,
    handleDelete,
    translation,
  } = props;
  const isReadOnlyMode = useSelector(readOnlyMode);

  return (
    <>
      <Button
        id="select_pos"
        color="primary"
        variant="contained"
        dataCr="device-select-pos-button"
        disabled={isReadOnlyMode || !isSelectPositionEnabled}
        onClick={() => handlePlace()}
        title={translation("devices.selPos")}
        icon
      >
        <LocationOnRounded />
      </Button>
      <Button
        id="delete_resource"
        color="primary"
        variant="contained"
        dataCr="device-delete-button"
        disabled={isReadOnlyMode || !isDeleteDeviceEnabled}
        onClick={() => handleDelete()}
        title={translation("devices.deleteDevice")}
        icon
      >
        <DeleteRounded />
      </Button>
    </>
  );
};

ResourceContentHeaderActions.defaultProps = {
  translation: (text) => text,
  isDeleteDeviceEnabled: true,
  isSelectPositionEnabled: true,
};

export default ResourceContentHeaderActions;
