// General
import React, { PropsWithChildren, useCallback } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// Components
import { NavbarWithRoutes } from "dms-lib";
import { useI18n } from "compass-commons";
import SelectedEdgeDeviceDetails from "./detailsPanel/SelectedEdgeDeviceDetails";
import SelectedEdgeDeviceIntegrations from "./integrationsPanel/SelectedEdgeDeviceIntegrations";
// Models
import { EdgeDeviceDTO } from "../../../models/edgeDevices/EdgeDeviceDTO";
// Routes
import SelectedEdgeDevicePanelRoutes, {
  SelectedEdgeDeviceMenuTabRoute,
} from "../../../router/SelectedEdgeDevicePanelRoutes";
import RouterGuard from "../../../router/RouterGuard";
// Store
import { useStoreDispatch } from "../../../store";
import { edgeDevicesActions } from "../../../store/edgeDevices";
// Styles
import "./selectedEdgeDevicePanel.module.css";

/**
 * Dynamic Router Guard Helper for Selected edge device sub tabs
 * Important to know the sub tab context
 */
const SubTabRouterWrapper = ({
  tabName,
  children,
}: PropsWithChildren<{
  tabName: SelectedEdgeDeviceMenuTabRoute;
}>): JSX.Element => {
  const dispatch = useStoreDispatch();

  const onEnterHandler = useCallback(() => {
    dispatch(edgeDevicesActions.setSelectedEdgeDeviceSubTab(tabName));
  }, [tabName]);

  return <RouterGuard onEnter={onEnterHandler}>{children}</RouterGuard>;
};

interface SelectedEdgeDevicePanelProps {
  edgeDevice: EdgeDeviceDTO;
}

const SelectedEdgeDevicePanel = ({
  edgeDevice,
}: SelectedEdgeDevicePanelProps): JSX.Element => {
  const { t: translate } = useI18n();

  return (
    <>
      <NavbarWithRoutes
        routesMap={SelectedEdgeDevicePanelRoutes.map((route) => ({
          name: translate(route.name),
          path: `${edgeDevice.id}/${route.path}`,
          otherPaths: route.otherPaths,
        }))}
        className="selected-edge-device-panel-nav-bar"
      />
      <Routes>
        <Route
          path={`:id/${SelectedEdgeDeviceMenuTabRoute.Default}`}
          element={
            <SubTabRouterWrapper
              tabName={SelectedEdgeDeviceMenuTabRoute.Default}
            >
              <Navigate to={`${SelectedEdgeDeviceMenuTabRoute.Details}`} />
            </SubTabRouterWrapper>
          }
        />
        <Route
          path={`:id/${SelectedEdgeDeviceMenuTabRoute.Details}`}
          element={
            <SubTabRouterWrapper
              tabName={SelectedEdgeDeviceMenuTabRoute.Details}
            >
              <SelectedEdgeDeviceDetails edgeDevice={edgeDevice} />{" "}
            </SubTabRouterWrapper>
          }
        />
        <Route
          path={`:id/${SelectedEdgeDeviceMenuTabRoute.Integrations}`}
          element={
            <SubTabRouterWrapper
              tabName={SelectedEdgeDeviceMenuTabRoute.Integrations}
            >
              <SelectedEdgeDeviceIntegrations edgeDevice={edgeDevice} />
            </SubTabRouterWrapper>
          }
        />
      </Routes>
    </>
  );
};

export default SelectedEdgeDevicePanel;
