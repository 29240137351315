// eslint-disable-next-line import/prefer-default-export
export enum ConnectionPropertyType {
  "UNKNOWN" = "UNKNOWN",
  BOOLEAN = "BOOLEAN",
  INTEGER = "INTEGER",
  STRING = "STRING",
  PASSWORD = "PASSWORD",
  LIST_BOX = "LIST_BOX",
}

export enum ConnectionPropertyTypeToSubsystemDtoType {
  BOOLEAN = "connectionPropertyBoolean",
  INTEGER = "connectionPropertyInteger",
  STRING = "connectionPropertyString",
  PASSWORD = "connectionPropertyString",
  LIST_BOX = "connectionPropertyListBox",
}
