// General
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// Styles
import "./IncidentType.module.css";
// Components
import { Accordion } from "@mui/material";
// Store
import { selectIncidents, useStoreDispatch } from "../../../../../store";
import { incidentsActions } from "../../../../../store/incidents";
// Models
import { IncidentTypeDto } from "../../../../../models/incidents/IncidentTypeDto";
import IncidentTypeSummary from "./components/IncidentTypeSummary";
import IncidentTypeBehaviors from "./components/IncidentTypeBehaviors";
import IncidentTypeDrawerState from "../../../../../models/incidents/IncidentTypeDrawerState";

// Utils
import { INCIDENT_TYPES_RULES_PAGE_SIZE } from "../../../../../utils/Constants";

export interface IncidentTypeProps {
  incidentType: IncidentTypeDto;
  configDrawerState?: (s: IncidentTypeDrawerState, i?: IncidentTypeDto) => void;
  readOnly?: boolean;
  initialExpand?: boolean;
  deleteDisabled?: boolean;
}

const IncidentType = (props: IncidentTypeProps): JSX.Element => {
  const {
    incidentType,
    configDrawerState,
    readOnly = false,
    initialExpand = false,
    deleteDisabled = false,
  } = props;
  const [fetchedIndex, setFetchedIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState(-1);
  const [expand, setExpand] = React.useState(initialExpand);
  const dispatch = useStoreDispatch();
  const { incidentTypesPageRules, loadingIncidentTypesRules } =
    useSelector(selectIncidents);

  useEffect(() => {
    if (expand) setPageIndex(0);
  }, [expand]);

  const dispatchIncidentTypeRules = (index: number) => {
    dispatch(
      incidentsActions.getIncidentTypeRules({
        incidentTypeId: incidentType.id,
        incidentTypeName: incidentType.name,
        index,
        pageSize: INCIDENT_TYPES_RULES_PAGE_SIZE,
      })
    );
  };

  useEffect(() => {
    if (fetchedIndex <= pageIndex) {
      dispatchIncidentTypeRules(pageIndex);
      setFetchedIndex(pageIndex + 1);
    }
  }, [pageIndex]);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
  };

  return (
    <Accordion
      disableGutters
      elevation={0}
      expanded={expand}
      className="config-incident-type__panel"
      data-cr="incident-type-panel"
    >
      <IncidentTypeSummary
        incidentType={incidentType}
        toggleAccordion={toggleAccordion}
        expandToggle={expand}
        configDrawerState={configDrawerState}
        readOnly={readOnly}
        deleteDisabled={deleteDisabled}
      />

      <IncidentTypeBehaviors
        incidentTypeRulesPage={incidentTypesPageRules[incidentType.id]}
        loadingIncidentTypeRules={loadingIncidentTypesRules[incidentType.id]}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
      />
    </Accordion>
  );
};

export default IncidentType;
