/**
 * SELECTED EDGE DEVICE ROUTER CONFIGURATION HELPERS
 */
export enum SelectedEdgeDeviceMenuTabRoute {
  Default = "",
  Details = "details",
  Integrations = "integrations",
}

export default [
  {
    path: SelectedEdgeDeviceMenuTabRoute.Details,
    otherPaths: [SelectedEdgeDeviceMenuTabRoute.Default],
    name: "edgeDevices.selectedEdgeDevice.navmenu.details",
  },
  {
    path: SelectedEdgeDeviceMenuTabRoute.Integrations,
    otherPaths: [],
    name: "edgeDevices.selectedEdgeDevice.navmenu.integrations",
  },
];
