import React from "react";
import { OgBlockTypeDto } from "../../../../../models/ogTaskTypes/OgBlockTypeDto";

interface MultiOptionsBlockTypeProps {
  block: OgBlockTypeDto;
}

const MultiOptionsBlockType = (
  props: MultiOptionsBlockTypeProps
): JSX.Element => {
  const { block } = props;

  const getOptions = () => {
    let optionCount = 0;
    return block?.options?.map((opt) => {
      optionCount += 1;
      return (
        <div
          className="compass-rounded-border task-input-text-default"
          key={opt.text}
        >
          {opt?.text || `Option ${optionCount}`}
        </div>
      );
    });
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: "4px" }}
      id="multi-options-block-type"
      data-cr="multi-options-block-type"
    >
      <div className="task-widget-multi-options-vert">
        <div className="task-widget-multi-options-inner">{getOptions()}</div>
      </div>
    </div>
  );
};

export default MultiOptionsBlockType;
