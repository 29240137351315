// General
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useSelector } from "react-redux";
import { useI18n } from "compass-commons";
// Styles
import "./subsystemConnectionPropertiesPanel.module.css";
// Store
import {
  selectRoot,
  selectSubsystems,
  useStoreDispatch,
} from "../../../../store";
import {
  ConfigEditionModes,
  ConfigModes,
  creationOrEditionMode,
  editionMode,
  rootActions,
} from "../../../../store/root";
import {
  getSubsystemModel,
  selectIsPlaceholderSubsystem,
  subsystemsActions,
} from "../../../../store/subsystems";
// Components
import SubsystemConnectionDataPanel from "./components/subsystemConnectionDataPanel";
import SubsystemDataPanel from "./components/subsystemDataPanel";
// Hooks
import useTabActions from "../../../../hooks/useTabActions";
// Models
import { SubsystemLightConnections } from "../../../../models/subsystems/SubsystemLightDto";
import {
  ConnectionData,
  ConnectionPropertiesData,
} from "../../../../models/subsystems/SubsystemDto";
import {
  convertToSubsystemDto,
  convertToUpdateSubsystemDto,
} from "../../../../models/subsystems/mapper/SubsystemMapper";

interface ConnectionPropertiesPanelProps {
  siteId: string;
  displayedSubsystem: SubsystemLightConnections;
  readOnlyMode: boolean;
}

const SubsystemConnectionPropertiesPanel = (
  props: ConnectionPropertiesPanelProps
): JSX.Element => {
  const { t } = useI18n();

  const { readOnlyMode, displayedSubsystem, siteId } = props;

  const { setReadOnlyMode } = useTabActions();

  const { configMode, configEditMode } = useSelector(selectRoot);
  const isCreationOrEditionMode = useSelector(creationOrEditionMode);
  const isEditionMode = useSelector(editionMode);
  const { brands, connectionPropertyTemplates, connectionPropertiesLoading } =
    useSelector(selectSubsystems);
  const isSelectIsPlaceholderSubsystem = useSelector(
    selectIsPlaceholderSubsystem
  );
  const subsystemModel = useSelector(getSubsystemModel);

  const dispatch = useStoreDispatch();

  // LOCAL STATE - Important to have 2 different useForms to control the subsystem creation flow
  const schema = z.object({
    name: z.string().min(1, {
      message: t("subsystems.propertiesFieldsValidation.required", {
        label: t("subsystems.propertiesFields.name"),
      }),
    }),
    brand: z.object({
      name: z.string().min(1, {
        message: t("subsystems.propertiesFieldsValidation.required", {
          label: t("subsystems.propertiesFields.brand"),
        }),
      }),
    }),
    model: z.object({
      name: z.string().min(1, {
        message: t("subsystems.propertiesFieldsValidation.required", {
          label: t("subsystems.propertiesFields.model"),
        }),
      }),
    }),
  });

  const getFormDefaultValue = (): ConnectionData => ({
    id: displayedSubsystem?.id,
    name: displayedSubsystem?.name || "",
    brand: {
      id: displayedSubsystem?.brandId,
      name: displayedSubsystem?.brandName || "",
    },
    model: {
      id: subsystemModel?.id || displayedSubsystem?.modelId,
      name: subsystemModel?.name || "",
      integrationRegistrationId: subsystemModel?.integrationRegistrationId,
    },
  });

  // Connection Data subsystem creation flow
  const {
    reset: resetConnectionData,
    resetField: resetFieldConnectionData,
    control: controlConnectionData,
    formState: { errors: errorsConnectionData, isValid: isValidConnectionData },
    getValues: getValuesConnectionData,
    handleSubmit: handleConnectionDataSubmit,
  } = useForm<ConnectionData>({
    mode: "all",
    resolver: zodResolver(schema),
    defaultValues: {
      ...getFormDefaultValue(),
    },
  });

  // Connection Property Data subsystem creation flow
  // This is dynamic and that's the reason why no schema is created
  const {
    reset: resetConnectionProperties,
    control: controlConnectionProperties,
    formState: { errors: errorsConnectionProperties },
    getValues: getValuesConnectionProperties,
    handleSubmit: handleConnectionPropertiesSubmit,
  } = useForm<ConnectionPropertiesData>({
    mode: "all",
  });

  /**
   * Strategy Config Mode
   */
  const strategyConfigMode = Object.freeze({
    [ConfigModes.CREATE]: async () => {
      const subsystemDto = convertToSubsystemDto(
        siteId,
        getValuesConnectionData(),
        getValuesConnectionProperties()
      );

      return dispatch(
        subsystemsActions.createSubsystem({
          subsystemDto,
          integrationRegistrationId: getValuesConnectionData(
            "model.integrationRegistrationId"
          ),
        })
      ).unwrap();
    },
    [ConfigModes.EDIT]: async () => {
      const updateSubsystemDto = convertToUpdateSubsystemDto(
        getValuesConnectionData(),
        getValuesConnectionProperties()
      );

      return dispatch(
        subsystemsActions.updateSubsystem({
          siteId,
          updateSubsystemDto,
          subsystemId: displayedSubsystem.id,
        })
      ).unwrap();
    },
  });

  const resetAllFields = () => {
    resetConnectionData();
    resetConnectionProperties();
  };

  const handleCancel = (
    shouldResetSelectedSubsystem = false,
    newSubsystemSelected = true
  ) => {
    if (shouldResetSelectedSubsystem)
      dispatch(subsystemsActions.subsystemSelected(null));
    if (newSubsystemSelected) setReadOnlyMode();
  };

  const handleSave = async () => {
    try {
      const createdSubsystemId = await strategyConfigMode[configMode]?.();

      dispatch(subsystemsActions.selectSubsystemById(createdSubsystemId));
      dispatch(rootActions.activateReadOnlyMode(true));
    } catch (error) {
      dispatch(rootActions.activateEditReadOnlyMode());
    }
  };

  const handleInvalidSave = async () => {
    dispatch(rootActions.activateEditReadOnlyMode());
  };

  const strategyConfigEditionMode = Object.freeze({
    [ConfigEditionModes.CANCEL]: () => handleCancel(true),
    [ConfigEditionModes.SAVE]:
      (isValidConnectionData &&
        handleConnectionPropertiesSubmit(handleSave, handleInvalidSave)) ||
      handleConnectionDataSubmit(handleSave, handleInvalidSave),
  });

  // Lifecycles
  useEffect(() => {
    if (isCreationOrEditionMode) dispatch(subsystemsActions.getBrands());
  }, [isCreationOrEditionMode]);

  // Cancel When changing subsystem
  useEffect(() => {
    return () => {
      resetAllFields();
      handleCancel(false, isSelectIsPlaceholderSubsystem || isEditionMode);
    };
  }, [displayedSubsystem?.id, isCreationOrEditionMode]);

  useEffect(() => {
    resetAllFields();
    handleCancel(false);
  }, [siteId]);

  useEffect(() => {
    strategyConfigEditionMode[configEditMode]?.();
  }, [configEditMode]);

  return (
    (!displayedSubsystem && (
      <div
        data-cr="subsystem_not-selected"
        className="config-subsystem__not-selected"
      >
        <span>{t("subsystems.noSubsystemSelected")}</span>
      </div>
    )) || (
      <>
        <SubsystemDataPanel
          readOnlyMode={readOnlyMode}
          displayedSubsystem={displayedSubsystem}
          brands={brands}
          control={controlConnectionData}
          getValues={getValuesConnectionData}
          reset={() => resetConnectionData({ ...getFormDefaultValue() })}
          resetField={(name: any) => resetFieldConnectionData(name)}
          errors={errorsConnectionData}
        />
        <SubsystemConnectionDataPanel
          connectionPropertyTemplates={connectionPropertyTemplates}
          readOnlyMode={readOnlyMode}
          isLoading={connectionPropertiesLoading}
          showConnectionProperties={isValidConnectionData}
          control={controlConnectionProperties}
          errors={errorsConnectionProperties}
        />
      </>
    )
  );
};

export default React.memo(SubsystemConnectionPropertiesPanel);
