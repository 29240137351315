import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Reducers
import edgeDevicesExtraReducers from "./extraReducers";
// Actions
import edgeDevicesExtraActions from "./extraActions";
// Models
import { EdgeDeviceDTO } from "../../models/edgeDevices/EdgeDeviceDTO";
import { EdgeDeviceIntegrationDTO } from "../../models/edgeDevices/EdgeDeviceIntegrationDTO";
import { DriverDTO } from "../../models/edgeDevices/DriverDTO";
import { SelectedEdgeDeviceMenuTabRoute } from "../../router/SelectedEdgeDevicePanelRoutes";

/* eslint-disable no-param-reassign */

type EdgeDevicesErrorType = { label: string; message: string };

export type EdgeDevicesState = {
  edgeDevicesList: EdgeDeviceDTO[];
  loadingEdgeDevicesList: boolean;
  selectedEdgeDevice?: string;
  totalPages: number;
  currentPage: number;
  loadingEdgeDevicesCrud: boolean;
  edgeDevicesError?: EdgeDevicesErrorType;
  deletedSuccessfully?: boolean;
  selectedEdgeDeviceSubTab: SelectedEdgeDeviceMenuTabRoute;
  loadingEdgeDeviceIntegrations: boolean;
  edgeDeviceIntegrationsList: EdgeDeviceIntegrationDTO[];
  loadEdgeDeviceIntegrationsCrud: boolean;
  loadingDriversList: boolean;
  driversList: DriverDTO[];
  integrationAddedSuccessfully?: boolean;
  loadingEdgeDeviceToken: boolean;
  edgeDeviceToken?: string;
};

const SLICE_NAME = "edgeDevices";

export const initialState: EdgeDevicesState = {
  edgeDevicesList: [],
  loadingEdgeDevicesList: false,
  selectedEdgeDevice: null,
  totalPages: 0,
  currentPage: 1,
  loadingEdgeDevicesCrud: false,
  edgeDevicesError: null,
  deletedSuccessfully: null,
  selectedEdgeDeviceSubTab: SelectedEdgeDeviceMenuTabRoute.Details,
  loadingEdgeDeviceIntegrations: false,
  edgeDeviceIntegrationsList: [],
  loadEdgeDeviceIntegrationsCrud: false,
  loadingDriversList: false,
  driversList: [],
  integrationAddedSuccessfully: null,
  loadingEdgeDeviceToken: false,
  edgeDeviceToken: null,
};

export const resetEdgeDeviceToDefaults = {
  name: "",
};

/**
 * Slice to manipulate all operations related to subsystems
 */
export const edgeDevicesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    selectEdgeDevice: (
      state: EdgeDevicesState,
      action: PayloadAction<string>
    ) => {
      state.selectedEdgeDevice = action.payload;
    },
    changePage: (state: EdgeDevicesState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setDeletedSuccessfully: (
      state: EdgeDevicesState,
      action: PayloadAction<boolean>
    ) => {
      state.deletedSuccessfully = action.payload;
    },
    setEdgeDeviceToken: (
      state: EdgeDevicesState,
      action: PayloadAction<string>
    ) => {
      state.edgeDeviceToken = action.payload;
    },
    setSelectedEdgeDeviceSubTab: (
      state: EdgeDevicesState,
      action: PayloadAction<SelectedEdgeDeviceMenuTabRoute>
    ) => {
      state.selectedEdgeDeviceSubTab = action.payload;
    },
    setIntegrationAddedSuccessfully: (
      state: EdgeDevicesState,
      action: PayloadAction<boolean>
    ) => {
      state.integrationAddedSuccessfully = action.payload;
    },
    updateEdgeDevicesList: (
      state: EdgeDevicesState,
      action: PayloadAction<EdgeDeviceDTO[]>
    ) => {
      state.edgeDevicesList = action.payload;
    },
    updateEdgeDeviceIntegrationsList: (
      state: EdgeDevicesState,
      action: PayloadAction<EdgeDeviceIntegrationDTO[]>
    ) => {
      state.edgeDeviceIntegrationsList = action.payload;
    },
  },
  extraReducers: edgeDevicesExtraReducers,
});

// Action creators are generated for each case reducer function
export const edgeDevicesActions = {
  ...edgeDevicesSlice.actions,
  ...edgeDevicesExtraActions,
};

export default edgeDevicesSlice.reducer;
