import React from "react";
import { useI18n } from "compass-commons";
// Custom Components
import { Button } from "dms-lib";
// Hooks
import useTabActions from "../../../../hooks/useTabActions";
// Contexts
import { useTabActionsContext } from "../../../../contexts/TabActionsContext";

interface ConfigContentHeaderEditProps {
  disableActions: boolean;
}

/**
 * Component that handles some common actions to all the configuration sub navs
 * @returns JSX.Element
 */
const ConfigContentHeaderEdit = ({
  disableActions,
}: ConfigContentHeaderEditProps): JSX.Element => {
  const { t: translate } = useI18n();

  const { setCancelMode, setSaveMode } = useTabActions();

  const {
    activateAll,
    editActionsAllowed,
    disableSaveButton,
    configEditButtons: { showCancelButton, showSaveButton },
  } = useTabActionsContext();

  return (
    <>
      {(activateAll || editActionsAllowed || showCancelButton) && (
        <Button
          dataCr="cancel-button"
          disabled={disableActions}
          onClick={() => setCancelMode()}
          color="secondary"
          variant="outlined"
        >
          <span>{translate("cancel", { ns: "Shared" })}</span>
        </Button>
      )}
      {(activateAll || editActionsAllowed || showSaveButton) && (
        <Button
          dataCr="save-button"
          disabled={disableActions || disableSaveButton}
          onClick={() => setSaveMode()}
          color="primary"
          variant="contained"
        >
          <span>{translate("save", { ns: "Shared" })}</span>
        </Button>
      )}
    </>
  );
};

export default ConfigContentHeaderEdit;
