import React from "react";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { useI18n } from "compass-commons";
import "./integrationStatus.module.css";
import IntegrationStatus from "../../../models/edgeDevices/IntegrationStatus";

interface IntegrationStatusIconProps {
  status: IntegrationStatus;
}

const IntegrationStatusIcon = ({
  status,
}: IntegrationStatusIconProps): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <CircleRoundedIcon
      className={`integration-status-${status.toLowerCase()}`}
      titleAccess={translate(
        `edgeDevices.integrationStatusList.${status.toLowerCase()}`
      )}
    />
  );
};

export default IntegrationStatusIcon;
