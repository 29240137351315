// General
import { useEffect, useRef, useState } from "react";
// Services
import SandboxIntegrationManagerService from "../../services/SandboxIntegrationManagerService";
// Models
import { EdgeDeviceIntegrationDTO } from "../../models/edgeDevices/EdgeDeviceIntegrationDTO";
import { EdgeDeviceIntegrationsResponseDTO } from "../../models/edgeDevices/EdgeDeviceIntegrationsResponseDTO";

const FETCH_INTERVAL = 10000;

const useFetchEdgeDeviceIntegrations = (
  selectedEdgeDeviceId: string,
  edgeDeviceIntegrations: EdgeDeviceIntegrationDTO[]
): { integrations: EdgeDeviceIntegrationDTO[] } => {
  const [integrations, setIntegrations] =
    useState<EdgeDeviceIntegrationDTO[]>();
  const fetchEdgeDeviceIntegrationsSetTimeout = useRef<NodeJS.Timeout>();

  const parseEdgeDeviceIntegrationsResponse = (
    response: EdgeDeviceIntegrationsResponseDTO
  ) => {
    const integrationsReponse = response.edgeDeviceIntegrations;
    const integrationsMapById = edgeDeviceIntegrations.reduce(
      (accumulator, integration) => {
        accumulator[integration.id] = integration;
        return accumulator;
      },
      {}
    );
    const newIntegrations = integrationsReponse.filter((integration) =>
      Object.keys(integrationsMapById).includes(integration.id)
    );
    if (
      JSON.stringify(newIntegrations) !== JSON.stringify(edgeDeviceIntegrations)
    ) {
      setIntegrations(newIntegrations);
    }
  };

  useEffect(() => {
    if (edgeDeviceIntegrations?.length === 0) {
      return;
    }
    let mounted = true;

    const fetchEdgeDeviceIntegrations = () => {
      SandboxIntegrationManagerService.getEdgeDeviceIntegrations(
        selectedEdgeDeviceId
      )
        .then((response) => {
          if (!mounted) {
            return;
          }
          parseEdgeDeviceIntegrationsResponse(response);
        })
        .finally(() => {
          if (!mounted) {
            return;
          }
          fetchEdgeDeviceIntegrationsSetTimeout.current = setTimeout(
            fetchEdgeDeviceIntegrations,
            FETCH_INTERVAL
          );
        });
    };

    fetchEdgeDeviceIntegrationsSetTimeout.current = setTimeout(
      fetchEdgeDeviceIntegrations,
      FETCH_INTERVAL
    );

    // eslint-disable-next-line consistent-return
    return () => {
      mounted = false;
      clearTimeout(fetchEdgeDeviceIntegrationsSetTimeout.current);
    };
  }, [selectedEdgeDeviceId, edgeDeviceIntegrations]);

  return { integrations };
};

export default useFetchEdgeDeviceIntegrations;
