// General
import React from "react";
import { useI18n } from "compass-commons";

const OgEditPanelNotSelected = (): JSX.Element => {
  const { t } = useI18n();

  return (
    <div className="og-edit-panel-inner" data-cr="og-edit-not-selected">
      {t("operatorGuide.guideNotSelected")}
    </div>
  );
};

export default OgEditPanelNotSelected;
