// General
import React from "react";
import { useI18n } from "compass-commons";
// Styles
import "../../IncidentTypeDrawer.module.css";
// Components
import IncidentType from "./incidentType/IncidentType";
// Models
import { IncidentTypeDto } from "../../../../models/incidents/IncidentTypeDto";
import IncidentTypeDrawerState from "../../../../models/incidents/IncidentTypeDrawerState";

interface IncidentTypesListProps {
  configDrawerState: (s: IncidentTypeDrawerState, i?: IncidentTypeDto) => void;
  incidentTypes: IncidentTypeDto[];
}

const IncidentTypesList = (props: IncidentTypesListProps): JSX.Element => {
  const { configDrawerState, incidentTypes } = props;
  const { t: translate } = useI18n();
  const deleteDisabled = incidentTypes?.length === 1;
  return (
    <>
      {incidentTypes && incidentTypes.length > 0 ? (
        incidentTypes.map((incidentType) => (
          <IncidentType
            key={incidentType.id}
            incidentType={incidentType}
            configDrawerState={configDrawerState}
            deleteDisabled={deleteDisabled}
          />
        ))
      ) : (
        <span className="config-incident-types__no-incidents">
          {translate("incidentTypes.noIncidentType")}
        </span>
      )}
    </>
  );
};

export default IncidentTypesList;
