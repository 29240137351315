// General
import React from "react";
import { useI18n } from "compass-commons";
// Components
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
// Styles
import "./edgeDeviceStatus.module.css";

const EdgeDeviceStatusConnectedIcon = (): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <CheckCircleOutlineRoundedIcon
      className="edge-device-status-connected"
      titleAccess={translate(`edgeDevices.statusList.connected`)}
    />
  );
};

export default EdgeDeviceStatusConnectedIcon;
