// General
import React from "react";
// Styles
import "../../subsystemConnectionDataPanel.module.css";

interface ConnectionPropertyHeaderProps {
  dataCr: string;
  label: string;
  required: boolean;
}

const ConnectionPropertyHeader = ({
  dataCr,
  label,
  required,
}: ConnectionPropertyHeaderProps): JSX.Element => {
  return (
    <div>
      <span
        data-cr={`${dataCr}-label`}
        className="config-subsystem-connection-data__field-wrapper--bold"
      >
        {label}
        <span className="config-subsystem-connection-data__asterisk">
          {required && `*`}
        </span>
        :
      </span>
    </div>
  );
};
export default ConnectionPropertyHeader;
