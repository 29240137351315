import { z, string, boolean, any } from "zod";
import { ResourceMappingHasStateFunctionalityData } from "./ResourceMappingHasStateFunctionalityData";
import { ResourceMappingSendCommandFunctionalityData } from "./ResourceMappingSendCommandFunctionalityData";

// eslint-disable-next-line no-shadow
export enum EResourceFunctionalityType {
  UNKNOWN = "UNKNOWN",
  HAS_STATE = "HAS_STATE",
  SEND_COMMAND = "SEND_COMMAND",
  EVENT_SOURCE = "EVENT_SOURCE",
  LIVE_VIDEO_STREAMING = "LIVE_VIDEO_STREAMING",
}

const ResourceFunctionalityType = z.nativeEnum(EResourceFunctionalityType);

export type ResourceFunctionalityType = z.infer<
  typeof ResourceFunctionalityType
>;

export const ResourceMappingFunctionalityDto = z.object({
  id: string(),
  enabled: boolean(),
  type: ResourceFunctionalityType,
  hasStateFunctionalityData:
    ResourceMappingHasStateFunctionalityData.optional(),
  eventSourceFunctionalityData: any().optional(),
  sendCommandFunctionalityData:
    ResourceMappingSendCommandFunctionalityData.optional(),
  liveVideoStreamingFunctionalityData: any().optional(),
});

export type ResourceMappingFunctionalityDto = z.infer<
  typeof ResourceMappingFunctionalityDto
>;
