import { HttpClient } from "compass-shared-services";
import { OnDemandIncidentSubmit } from "../models/onDemandIncident/onDemandIncident";

const { OPERATION_INCIDENT_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  CREATE_TEST_ONDEMAND_INCIDENT: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/testOnDemand`,
};

/**
 * Operator Incident Manager Service where backend service is called for
 * * incident Lists
 */
export default class OIMService {
  // We need to check the pageIndex and only abort if it is the same
  // Otherwise it can be a fast scroller
  static abortControllerData: {
    pageIndex: number;
    controller: AbortController;
  };

  static async createTestOnDemandIncident(
    formData: OnDemandIncidentSubmit
  ): Promise<boolean> {
    return httpClient
      .post<boolean>({
        url: URL_PATHS.CREATE_TEST_ONDEMAND_INCIDENT,
        payload: formData,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw Error(error);
      });
  }
}
