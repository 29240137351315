import React, { useEffect, useState } from "react";
import "./compassTextField.module.css";
import { LocalizationNS, useI18n } from "compass-commons";
import {
  ArrowBackRounded,
  CheckRounded,
  EditRounded,
} from "@mui/icons-material";
import { Button, TextField } from "dms-lib";
import { isSafeString } from "../../../utils/Util";

interface CompassTextFieldProps {
  customSuccess?: string;
  customFailure?: string;
  updateCallback: any;
  placeholder?: string;
  fieldName?: string;
  showSuccess: boolean;
  showFailure: boolean;
  rule?: RegExp;
  ruleError?: string;
  editButtonHidden?: boolean;
  textFieldId?: string;
  fieldIdInEdition?: string;
  onBackClick?: () => void;
  maxTextLength?: number;
  backButton?: boolean;
  defaultValue?: string;
  safeString?: boolean;
}

const CompassTextField = (props: CompassTextFieldProps): JSX.Element => {
  const { t: translate } = useI18n();

  const {
    customSuccess,
    customFailure,
    showSuccess,
    showFailure,
    updateCallback,
    placeholder,
    fieldName,
    rule,
    ruleError,
    editButtonHidden,
    textFieldId,
    fieldIdInEdition,
    onBackClick,
    maxTextLength,
    backButton,
    defaultValue,
    safeString,
  } = props;
  const [editing, setEditing] = useState(false);
  const [textInput, setTextInput] = useState<string>(defaultValue);
  const [invalidText, setInvalidText] = useState(false);
  const [maxLength] = useState(maxTextLength || 20);

  const editingMode = () => {
    if (editing) {
      updateCallback(textInput);
    }
    setEditing(!editing);
    setInvalidText(false);
  };

  useEffect(() => {
    if (fieldIdInEdition) {
      setEditing(true);
    } else {
      setEditing(false);
    }
  }, [editing, fieldIdInEdition]);

  const assertInputChange = (input: string) => {
    if (rule) {
      if (rule.test(input)) {
        setInvalidText(false);
        setTextInput(input);
      } else {
        setInvalidText(true);
      }
      return;
    }

    if (
      (safeString && !isSafeString(input) && input.length > 0) ||
      input.length > maxLength
    ) {
      setInvalidText(true);
    } else {
      setInvalidText(false);
      setTextInput(input);
    }
  };

  const DefaultError: React.FC = () => {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ color: "red" }}>
            {`${translate("operatorGuide.invalidContent", {
              ns: LocalizationNS.CONFIGURATION,
            })}`}
          </span>
          <span style={{ fontSize: "small", color: "grey" }}>
            {`${translate("operatorGuide.specialCharacters", {
              ns: LocalizationNS.CONFIGURATION,
            })}`}
          </span>
          <span style={{ fontSize: "small", color: "grey" }}>
            {`${translate("operatorGuide.maxNameLength", {
              ns: LocalizationNS.CONFIGURATION,
              maxLength,
            })}`}
          </span>
        </div>
      </>
    );
  };

  const CustomError: React.FC = () => {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ color: "red" }}>
            {`${translate("operatorGuide.invalidContent", {
              ns: LocalizationNS.CONFIGURATION,
            })}`}
          </span>
          <span style={{ fontSize: "small", color: "grey" }}>{ruleError}</span>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="dms-text-field-div">
        {(!fieldIdInEdition && editing) ||
        (fieldIdInEdition && textFieldId === fieldIdInEdition) ? (
          <>
            {backButton && (
              <Button
                color="inherit"
                variant="contained"
                aria-label="backButton"
                id={textFieldId ? `back-id-${textFieldId}` : "myId"}
                onClick={() => {
                  setEditing(false);
                  setInvalidText(false);
                  onBackClick();
                }}
                icon
              >
                <ArrowBackRounded />
              </Button>
            )}
            <div className="dms-input-label-block">
              <TextField
                data-cr="compass-text-field-input"
                size="small"
                placeholder={placeholder}
                disabled={!editing}
                autoFocus
                onChange={(e) => assertInputChange(e?.target?.value as string)}
                error={invalidText}
                defaultValue={defaultValue}
              />
              {invalidText && (!ruleError ? <DefaultError /> : <CustomError />)}
            </div>
            <Button
              color="inherit"
              variant="contained"
              aria-label="editButton"
              id={textFieldId ? `edit-check-id-${textFieldId}` : "myId"}
              onClick={editingMode}
              disabled={invalidText}
              dataCr="compass-text-field-check"
              icon
            >
              <CheckRounded />
            </Button>
          </>
        ) : (
          <>
            <div className="text-field-placeholder">
              {fieldName && <b>{fieldName}: </b>}
              {placeholder}
            </div>
            {!editButtonHidden && (
              <Button
                color="inherit"
                variant="contained"
                aria-label="editButton"
                id={textFieldId ? `edit-id-${textFieldId}` : "myId"}
                onClick={editingMode}
                icon
              >
                <EditRounded />
              </Button>
            )}
          </>
        )}
      </div>

      {showSuccess && <div style={{ color: "green" }}>{customSuccess}</div>}
      {showFailure && <div style={{ color: "red" }}>{customFailure}</div>}
    </div>
  );
};

CompassTextField.defaultProps = {
  rule: undefined,
  ruleError: undefined,
  editButtonHidden: undefined,
  textFieldId: undefined,
  customSuccess: "Updated Report Successfully",
  customFailure: "Failed to update Report",
  placeholder: "Insert value",
  fieldName: undefined,
  fieldIdInEdition: null,
  backButton: true,
  defaultValue: "",
  safeString: true,
  onBackClick: undefined,
  maxTextLength: undefined,
};

export default CompassTextField;
