// General
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
// Styles
import "./IncidentTypeDrawer.module.css";
// Models
import { Button } from "dms-lib";
import { MiscellaneousServicesRounded } from "@mui/icons-material";
import { SwipeableDrawer } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { IncidentTypeDto } from "../../models/incidents/IncidentTypeDto";
import IncidentTypeDrawerState from "../../models/incidents/IncidentTypeDrawerState";
// Components
import IncidentTypesList from "./components/incidentTypesList/IncidentTypesList";
import AddEditIncidentType from "./components/addEditIncidentType/AddEditIncidentType";
import DeleteIncidentType from "./components/deleteIncidentType/DeleteIncidentType";
import IncidentTypesDrawerHeader from "./components/IncidentTypesDrawerHeader";
// Store
import { selectIncidents, useStoreDispatch } from "../../store";
import { incidentsActions } from "../../store/incidents";

interface IncidentTypesDrawerProps {
  readOnlyMode: boolean;
}

const IncidentTypesDrawer = (props: IncidentTypesDrawerProps): JSX.Element => {
  const { readOnlyMode } = props;
  const [drawerState, setDrawerState] = React.useState(
    IncidentTypeDrawerState.Closed
  );
  const [selectedIncidentType, setSelectedIncidentType] = React.useState(null);
  const dispatch = useStoreDispatch();
  const {
    incidentTypes,
    loadingIncidentTypesRuleCount,
    incidentTypeCrudLoading,
  } = useSelector(selectIncidents);

  const isLoading =
    drawerState === IncidentTypeDrawerState.List &&
    (loadingIncidentTypesRuleCount || incidentTypeCrudLoading);

  const configDrawerState = useCallback(
    (state: IncidentTypeDrawerState, incidentType?: IncidentTypeDto) => {
      if (incidentType) {
        setSelectedIncidentType(incidentType);
      }
      setDrawerState(state);
    },
    []
  );

  const toggleDrawer = useCallback(
    (openDrawer: boolean) => {
      if (openDrawer) {
        dispatch(incidentsActions.getIncidentTypes(true));
        configDrawerState(IncidentTypeDrawerState.List);
      } else {
        configDrawerState(IncidentTypeDrawerState.Closed);
      }
    },
    [configDrawerState]
  );

  return (
    <>
      <div
        className="config-incident-types__button-drawer"
        data-cr="incident-types-button-drawer"
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => toggleDrawer(true)}
          disabled={readOnlyMode}
          icon
        >
          <MiscellaneousServicesRounded />
        </Button>
      </div>
      <SwipeableDrawer
        className="config-incident-types__drawer"
        data-cr="incident-types-drawer"
        anchor="right"
        open={drawerState !== IncidentTypeDrawerState.Closed}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <IncidentTypesDrawerHeader
          configDrawerState={configDrawerState}
          drawerState={drawerState}
          closeDrawer={() => toggleDrawer(false)}
        />
        {isLoading ? (
          <div className="config-loading">
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <>
            {drawerState === IncidentTypeDrawerState.List && (
              <IncidentTypesList
                incidentTypes={incidentTypes}
                configDrawerState={configDrawerState}
              />
            )}

            {drawerState === IncidentTypeDrawerState.Delete && (
              <DeleteIncidentType
                configDrawerState={configDrawerState}
                incidentTypes={incidentTypes.filter(
                  (incidentType) =>
                    incidentType?.id !== selectedIncidentType?.id
                )}
                selectedIncidentType={selectedIncidentType}
              />
            )}

            {[
              IncidentTypeDrawerState.Edit,
              IncidentTypeDrawerState.Add,
            ].includes(drawerState) && (
              <AddEditIncidentType
                drawerState={drawerState}
                configDrawerState={configDrawerState}
                incidentType={selectedIncidentType}
              />
            )}
          </>
        )}
      </SwipeableDrawer>
    </>
  );
};

export default IncidentTypesDrawer;
