import React from "react";
import ReactDOM from "react-dom";
import singleSpaCss from "single-spa-css";
import singleSpaReact from "single-spa-react";
import { ICompassI18n, LocalizationNS } from "compass-commons";
import { CompassI18n, defaultNS } from "compass-shared-services";
import App from "./App";
import "compass-commons/lib/css/compass-light-core.css";

const cssLifecycles = singleSpaCss({
  cssUrls: [],
  webpackExtractedCss: true,
});

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(_err, _info, _props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

const reactLifecyclesMountWithCompass = async <
  T extends { CompassI18n: ICompassI18n }
>(
  props: T
): Promise<any> => {
  const compassI18n = CompassI18n(appConfig.ASSET_STORE);
  await compassI18n.init({ ns: defaultNS });
  await compassI18n.loadMoreTranslations(LocalizationNS.CONFIGURATION);
  // eslint-disable-next-line no-param-reassign
  props.CompassI18n = compassI18n as ICompassI18n;
  return reactLifecycles.mount(props);
};

export const bootstrap = [cssLifecycles.bootstrap, reactLifecycles.bootstrap];
export const mount = [cssLifecycles.mount, reactLifecyclesMountWithCompass];
export const unmount = [reactLifecycles.unmount, cssLifecycles.unmount];
