// General
import { HttpClient } from "compass-shared-services";
import { getUrl } from "compass-commons";
// Models
import { ConnectionPropertyTemplateDto } from "../models/subsystems/ConnectionPropertyTemplateDto";
import { ModelDto } from "../models/subsystems/ModelDto";
import { SubsystemLightDto } from "../models/subsystems/SubsystemLightDto";
import { BrandDto } from "../models/subsystems/BrandDto";
import { SubsystemDto } from "../models/subsystems/SubsystemDto";
import { UpdateSubsystemDto } from "../models/subsystems/UpdateSubsystemDto";
import { EventTypesDto } from "../models/subsystems/EventTypesDto";
// Utils
import { buildErrorMessage } from "../utils/Util";

const { SUBSYSTEM_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  GET_SUBSYSTEMS: `${SUBSYSTEM_MANAGER_PATH}/subsystem-configuration/subsystems`,
  GET_PUT_DELETE_SUBSYSTEM: `${SUBSYSTEM_MANAGER_PATH}/subsystem-configuration/subsystems/(subsystemId)`,
  REFRESH_RESOURCES: `${SUBSYSTEM_MANAGER_PATH}/subsystem-configuration/subsystems/(subsystemId)/refresh-resources`,
  GET_CONNECTION_PROPERTIES: `${SUBSYSTEM_MANAGER_PATH}/subsystem-configuration/subsystems/(subsystemId)/connection-properties`,
  GET_CONNECTION_PROPERTIES_TEMPLATE: `${SUBSYSTEM_MANAGER_PATH}/subsystem-configuration/brands/(brandId)/models/(modelId)/connection-properties?integrationRegistrationId=(integrationRegistrationId)`,
  GET_MODELS: `${SUBSYSTEM_MANAGER_PATH}/subsystem-configuration/brands/(brandId)/models`,
  GET_BRANDS: `${SUBSYSTEM_MANAGER_PATH}/subsystem-configuration/brands`,
  GET_EVENT_TYPES: `${SUBSYSTEM_MANAGER_PATH}/subsystem-configuration/subsystems/(subsystemId)/event-types`,
  SAVE_SUBSYSTEM: `${SUBSYSTEM_MANAGER_PATH}/subsystem-configuration/subsystems`,
};

/**
 * Floor plan Service where backend service is called
 *
 */
export default class SubsystemService {
  static abortController: AbortController;

  static async getSubsystems(
    siteId: string,
    includeAncestors = false
  ): Promise<SubsystemLightDto[]> {
    const urlPath = `${URL_PATHS.GET_SUBSYSTEMS}`;
    return httpClient
      .get<SubsystemLightDto[]>({
        url: urlPath,
        config: {
          params: {
            siteId,
            ...(includeAncestors
              ? { includeSiteAncestors: includeAncestors }
              : {}),
          },
        },
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async getSubsystem(subsystemId: string): Promise<SubsystemLightDto> {
    const urlPath = getUrl(URL_PATHS.GET_PUT_DELETE_SUBSYSTEM, {
      subsystemId,
    });

    return httpClient
      .get<SubsystemLightDto>({
        url: urlPath,
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async refreshResources(subsystemId: string): Promise<boolean> {
    const urlPath = getUrl(URL_PATHS.REFRESH_RESOURCES, {
      subsystemId,
    });

    return httpClient
      .post<boolean>({
        url: urlPath,
      })
      .then(() => true)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async getConnectionProperties(
    subsystemId: string
  ): Promise<ConnectionPropertyTemplateDto[]> {
    const urlPath = getUrl(URL_PATHS.GET_CONNECTION_PROPERTIES, {
      subsystemId,
    });

    return httpClient
      .get<ConnectionPropertyTemplateDto[]>({ url: urlPath })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async getConnectionPropertiesTemplate(
    brandId: string,
    modelId: string,
    integrationRegistrationId: string
  ): Promise<ConnectionPropertyTemplateDto[]> {
    const urlPath = getUrl(URL_PATHS.GET_CONNECTION_PROPERTIES_TEMPLATE, {
      brandId,
      modelId,
      integrationRegistrationId,
    });

    return httpClient
      .get<ConnectionPropertyTemplateDto[]>({ url: urlPath })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async getModels(
    brandId: string,
    subsystemId: string
  ): Promise<ModelDto[]> {
    this.abortController?.abort();
    this.abortController = new AbortController();

    let urlPath = getUrl(URL_PATHS.GET_MODELS, {
      brandId,
    });

    if (subsystemId !== "newSubsystemId") {
      urlPath += `?subsystemId=${subsystemId}`;
    }

    return httpClient
      .get<ModelDto[]>({
        url: urlPath,
        config: {
          signal: this.abortController.signal,
        },
      })
      .then((response) => response)
      .catch((error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async getBrands(): Promise<BrandDto[]> {
    const urlPath = URL_PATHS.GET_BRANDS;

    return httpClient
      .get<BrandDto[]>({ url: urlPath })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async getEventTypes(subsystemId: string): Promise<EventTypesDto> {
    const urlPath = getUrl(URL_PATHS.GET_EVENT_TYPES, { subsystemId });

    return httpClient
      .get<EventTypesDto>({ url: urlPath })
      .then((response) => response)
      .catch((error) => {
        throw Error(error);
      });
  }

  static async createSubsystem(
    subsystemDto: SubsystemDto,
    integrationRegistrationId: string
  ): Promise<string> {
    const urlPath = URL_PATHS.SAVE_SUBSYSTEM;

    return httpClient
      .post<string>({
        url: urlPath,
        payload: subsystemDto,
        config: {
          params: { integrationRegistrationId },
        },
      })
      .then((response) => response)
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async updateSubsystem(
    updateSubsystemDto: UpdateSubsystemDto,
    subsystemId: string
  ): Promise<string> {
    const urlPath = getUrl(URL_PATHS.GET_PUT_DELETE_SUBSYSTEM, {
      subsystemId,
    });

    return httpClient
      .put<string>({
        url: urlPath,
        payload: updateSubsystemDto,
      })
      .then((response) => response)
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async deleteSubsystem(subsystemId: string): Promise<void> {
    const urlPath = getUrl(URL_PATHS.GET_PUT_DELETE_SUBSYSTEM, {
      subsystemId,
    });

    return httpClient
      .delete<void>({
        url: urlPath,
      })
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }
}
