// General
import { createAsyncThunk } from "@reduxjs/toolkit";
import { FloorPlanDevice, SiteFloorPlan } from "compass-commons";
// Services
import FloorPlanService from "../../../services/FloorPlanService";
// Models
import { DEFAULT_PAGE_SIZE } from "../../../components/resourceConfigurator/utils/constants";

/* eslint-disable no-return-await */
function createExtraActions() {
  const getDeviceByResourceMappingId = (
    prefix = "resources/getDeviceByResourceMappingId"
  ) => {
    return createAsyncThunk(
      prefix,
      async (params: { siteId: string; resourceMappingId: string }) =>
        await FloorPlanService.getDeviceByResourceMappingId(
          params.siteId,
          params.resourceMappingId
        )
    );
  };

  const updateFloorPlan = (prefix = "resources/updateFloorPlan") => {
    return createAsyncThunk(
      prefix,
      async (params: { siteId: string; floorPlan: SiteFloorPlan }) =>
        await FloorPlanService.updateFloorPlan(
          params.siteId,
          params.floorPlan.id,
          params.floorPlan
        )
    );
  };

  const getAllResources = (prefix = "resources/getAllResources") => {
    return createAsyncThunk(
      prefix,
      async (params: {
        siteId: string;
        pageIndex?: number;
        pageSize?: number;
        filterName?: string;
      }) =>
        await FloorPlanService.getResourcesBySite(
          params.siteId,
          params.pageIndex,
          params.pageSize || DEFAULT_PAGE_SIZE,
          params.filterName
        )
    );
  };

  const updateGeoResources = () => {
    return createAsyncThunk(
      `resources/updateGeoDevices`,
      async (params: { siteId: string; devices: FloorPlanDevice[] }) =>
        await FloorPlanService.updateGeoResources(params.siteId, params.devices)
    );
  };

  const getAllGeoReferencedResources = () => {
    return createAsyncThunk(
      `resources/getGeoResources`,
      async (params: { siteId: string }) =>
        await FloorPlanService.getGeoReferencedResources(params.siteId)
    );
  };

  return {
    getAllResources: getAllResources(),
    getNextResources: getAllResources("resources/getNextResources"),
    updateGeoResources: updateGeoResources(),
    getResourceByResourceMappingId: getDeviceByResourceMappingId(),
    updateFloorPlan: updateFloorPlan(),
    getAllGeoReferencedResources: getAllGeoReferencedResources(),
  };
}

const resourcesExtraActions = createExtraActions();

export default resourcesExtraActions;
