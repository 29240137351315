// Generic
import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
// Actions
import operatorGuideExtraActions from "../extraActions";
// Models
import { OperatorGuideState } from "..";

const operatorGuideGetAllReducer = (
  builder: ActionReducerMapBuilder<OperatorGuideState>
) => {
  builder
    .addCase(
      operatorGuideExtraActions.addTemplateStub.fulfilled,
      (state: OperatorGuideState, action: PayloadAction<string>) => {
        state.templateStub.push(action.payload);
      }
    )
    .addCase(
      operatorGuideExtraActions.removeTemplateStub.fulfilled,
      (state: OperatorGuideState, action: PayloadAction<string>) => {
        state.templateStub = state.templateStub.filter((id) => {
          return id !== action.payload;
        });
      }
    )
    .addCase(
      operatorGuideExtraActions.setIsEditingTaskText.fulfilled,
      (state: OperatorGuideState, action: PayloadAction<boolean>) => {
        state.editingTaskText = action.payload;
      }
    );
};

const operatorGuideExtraReducers = (
  builder: ActionReducerMapBuilder<OperatorGuideState>
): any => {
  operatorGuideGetAllReducer(builder);
};

export default operatorGuideExtraReducers;
