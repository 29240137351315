// General
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "compass-commons";
// Styles
import "./subsystemConfiguration.module.css";
// Components
import SubsystemConnectionPropertiesPanel from "./components/connectionPropertiesPanel";
import SubsystemResourcesPanel from "./components/subsystemResourcesPanel";
import SubsystemListPanel from "./components/listPanel";
import SubsystemDialogContent from "./components/subsystemDialog/subsystemDialogContent";
import SubsystemDialogActions from "./components/subsystemDialog/subsystemDialogActions";
import CompassDialog from "../commons/dialog";
// Context
import { useStateContext } from "../../contexts/StateContext";
// Services
import StateService from "../../services/StateService";
// Store
import {
  selectRoot,
  selectSites,
  selectSubsystems,
  useStoreDispatch,
} from "../../store";
import {
  ConfigModes,
  creationMode,
  readOnlyMode,
  rootActions,
} from "../../store/root";
import { subsystemsActions } from "../../store/subsystems";
// Context
import {
  ActionState,
  useTabActionsContext,
} from "../../contexts/TabActionsContext";
// Models
import { SubsystemLightDto } from "../../models/subsystems/SubsystemLightDto";
import { convertToSubsystemConnections } from "../../models/subsystems/mapper/SubsystemMapper";
// Hooks
import useTabActions from "../../hooks/useTabActions";
// Utils
import {
  NEW_BRAND_ID_PLACEHOLDER,
  NEW_MODEL_ID_PLACEHOLDER,
  NEW_SUBSYSTEM_ID_PLACEHOLDER,
  NEW_SUBSYSTEM_NAME_PLACEHOLDER,
} from "../../utils/Constants";

/**
 * Wrapper Layout for the Subsystem Configurator panel
 * This panel contains all subsystem configuration options
 * @returns JSX.Element
 */
const SubsystemConfigurator = (): JSX.Element => {
  const { t } = useI18n();

  const NEW_SUBSYSTEM_PLACEHOLDER: SubsystemLightDto = {
    id: NEW_SUBSYSTEM_ID_PLACEHOLDER,
    name: t(NEW_SUBSYSTEM_NAME_PLACEHOLDER),
    brandId: NEW_BRAND_ID_PLACEHOLDER,
    brandName: "",
    modelId: NEW_MODEL_ID_PLACEHOLDER,
  };

  // Local State
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // ACTIONS Handlers
  const {
    setConfigMainButtons,
    setEditActionsAllowed,
    setExtraResetHandler,
    setDisableAllButtons,
    setActivateAll,
  } = useTabActionsContext();

  const { setReadOnlyMode } = useTabActions();

  // Rxjs
  const stateService: StateService = useStateContext();
  const { alertSubject } = stateService;

  const errorCallback = useCallback((msg: string) => {
    if (msg) {
      alertSubject.next({ title: msg });
    }
  }, []);

  // Redux State Management
  const {
    subsystemError,
    selectedSubsystem,
    selectedSubsystemResources,
    disableRefreshingResources,
    filteredSubsystemsList,
    loadingSubsystemsList,
    subsystemCrudLoading,
  } = useSelector(selectSubsystems);

  const { configMode } = useSelector(selectRoot);
  const { selectedSiteId } = useSelector(selectSites);
  const isReadOnlyMode = useSelector(readOnlyMode);
  const isCreationMode = useSelector(creationMode);

  const dispatch = useStoreDispatch();

  const currentFilteredList = useMemo(
    () => [
      ...filteredSubsystemsList,
      ...(isCreationMode
        ? [convertToSubsystemConnections(NEW_SUBSYSTEM_PLACEHOLDER)]
        : []),
    ],
    [filteredSubsystemsList, isCreationMode]
  );

  const disablePollingButton = useMemo(
    () =>
      disableRefreshingResources ||
      !isReadOnlyMode ||
      NEW_SUBSYSTEM_PLACEHOLDER.id === selectedSubsystemResources?.id,
    [disableRefreshingResources, isReadOnlyMode, selectedSubsystemResources?.id]
  );

  //
  // MODAL ACTIONS
  //

  /**
   * Action responsable to update the modal status
   */
  // eslint-disable-next-line
  const toggleModal = useCallback((event?: any, forceState?: boolean) => {
    if (event) event.stopPropagation();
    setOpenDeleteModal((prevState: boolean) => forceState ?? !prevState);
  }, []);

  const deleteSubsystem = async (extraActions?: ActionState) => {
    extraActions?.();
    try {
      await dispatch(
        subsystemsActions.deleteSubsystem({
          subsystemId: selectedSubsystem.id,
          siteId: selectedSiteId,
        })
      ).unwrap();

      dispatch(subsystemsActions.selectSubsystemById(null));
      dispatch(rootActions.activateReadOnlyMode(true));
    } finally {
      dispatch(rootActions.activateReadOnlyMode(true));
    }
  };

  const deleteSubsystemSuccess = () =>
    deleteSubsystem(() => toggleModal(null, false));

  const deleteSubsystemClean = (event) => {
    toggleModal(event, false);
    setReadOnlyMode();
  };

  const handleDelete = () => {
    if (selectedSubsystem?.id) toggleModal(null, true);
  };

  const handleCreate = () => {
    dispatch(subsystemsActions.subsystemSelected(NEW_SUBSYSTEM_PLACEHOLDER));
  };

  const strategyConfigMode = Object.freeze({
    [ConfigModes.CREATE]: handleCreate,
    [ConfigModes.DELETE]: handleDelete,
  });

  /**
   * Activate Specific tab buttons
   */
  const initTabActionsConfig = () => {
    setConfigMainButtons({
      showCreateButton: true,
      showDeleteButton: !!selectedSubsystem,
      showEditButton: !!selectedSubsystem,
    });
    setActivateAll(false);
    setEditActionsAllowed(true);
    setExtraResetHandler(() =>
      dispatch(subsystemsActions.subsystemSelected(null))
    );
  };

  useEffect(() => {
    if (!subsystemError) return;
    errorCallback(`${t(subsystemError.label)} - ${subsystemError.message}`);
  }, [subsystemError]);

  useEffect(() => {
    initTabActionsConfig();
  }, [selectedSubsystem]);

  useEffect(() => {
    setDisableAllButtons(subsystemCrudLoading);
  }, [subsystemCrudLoading]);

  // Subsystems Button Actions Initialization
  useEffect(() => {
    strategyConfigMode[configMode]?.();
  }, [configMode]);

  useEffect(() => {
    if (selectedSiteId) {
      dispatch(subsystemsActions.getSiteSubsystems(selectedSiteId));
    }
  }, [selectedSiteId]);

  return (
    <>
      <div data-cr="config-subsystems-panel" className="config-subsystems">
        <SubsystemListPanel
          filteredSubsystemsList={currentFilteredList}
          loadingSubsystemsList={loadingSubsystemsList}
          selectedSubsystemId={selectedSubsystem?.id}
        />
        <article className="config-subsystems__panel config-subsystems__properties compass-rounded-corner">
          <div className="config-subsystems__connection-properties-item config-subsystems__panel--light-grey">
            <SubsystemConnectionPropertiesPanel
              siteId={selectedSiteId}
              displayedSubsystem={selectedSubsystem}
              readOnlyMode={isReadOnlyMode}
            />
          </div>
          {selectedSubsystemResources && (
            <div className="config-subsystems__resources-item config-subsystems__panel--light-grey">
              <SubsystemResourcesPanel
                displayedSubsystem={selectedSubsystemResources}
                disablePollingButton={disablePollingButton}
              />
            </div>
          )}
        </article>
        <CompassDialog
          onClose={deleteSubsystemClean}
          dialogState={openDeleteModal}
          dialogContent={<SubsystemDialogContent />}
          dialogActions={
            <SubsystemDialogActions
              onCancel={deleteSubsystemClean}
              onSuccess={deleteSubsystemSuccess}
            />
          }
        />
      </div>
    </>
  );
};

export default SubsystemConfigurator;
