// Generic
import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
// Store
import { EdgeDevicesState } from "..";
// Actions
import edgeDevicesExtraActions from "../extraActions";
// Models
import EdgeDeviceResponseDTO from "../../../models/edgeDevices/EdgeDeviceResponseDTO";
import { DriversResponseDTO } from "../../../models/edgeDevices/DriversResponseDTO";
import { EdgeDeviceIntegrationsResponseDTO } from "../../../models/edgeDevices/EdgeDeviceIntegrationsResponseDTO";

/* eslint-disable no-param-reassign, @typescript-eslint/no-explicit-any */

const edgeDevicesGetAllReducer = (
  builder: ActionReducerMapBuilder<EdgeDevicesState>
) => {
  builder
    .addCase(
      edgeDevicesExtraActions.getAllEdgeDevices.pending,
      (state: EdgeDevicesState) => {
        state.edgeDevicesList = [];
        state.loadingEdgeDevicesList = true;
        state.edgeDevicesError = null;
      }
    )
    .addCase(
      edgeDevicesExtraActions.getAllEdgeDevices.fulfilled,
      (
        state: EdgeDevicesState,
        action: PayloadAction<EdgeDeviceResponseDTO>
      ) => {
        if (
          state.currentPage > action.payload.totalPages &&
          action.payload.totalPages > 0
        ) {
          state.totalPages = action.payload.totalPages;
          state.currentPage = action.payload.totalPages;
          return;
        }
        state.loadingEdgeDevicesList = false;
        state.edgeDevicesList = action.payload.edgeDevices;
        state.totalPages = action.payload.totalPages;
      }
    )
    .addCase(
      edgeDevicesExtraActions.getAllEdgeDevices.rejected,
      (state: EdgeDevicesState, action) => {
        state.totalPages = 0;
        state.loadingEdgeDevicesList = false;
        state.edgeDevicesError = {
          label: "edgeDevices.getAllFailed",
          message: action.error.message,
        };
      }
    );
};

const edgeDevicesCreateNewReducer = (
  builder: ActionReducerMapBuilder<EdgeDevicesState>
) => {
  builder
    .addCase(
      edgeDevicesExtraActions.createEdgeDevice.pending,
      (state: EdgeDevicesState) => {
        state.loadingEdgeDevicesCrud = true;
        state.edgeDevicesError = null;
      }
    )
    .addCase(
      edgeDevicesExtraActions.createEdgeDevice.fulfilled,
      (state: EdgeDevicesState) => {
        state.loadingEdgeDevicesCrud = false;
      }
    )
    .addCase(
      edgeDevicesExtraActions.createEdgeDevice.rejected,
      (state: EdgeDevicesState, action) => {
        state.loadingEdgeDevicesCrud = false;
        state.edgeDevicesError = {
          label: "edgeDevices.createFailed",
          message: action.error.message,
        };
      }
    );
};

const edgeDevicesDeleteReducer = (
  builder: ActionReducerMapBuilder<EdgeDevicesState>
) => {
  builder
    .addCase(
      edgeDevicesExtraActions.deleteEdgeDevice.pending,
      (state: EdgeDevicesState) => {
        state.loadingEdgeDevicesCrud = true;
        state.edgeDevicesError = null;
        state.deletedSuccessfully = null;
      }
    )
    .addCase(
      edgeDevicesExtraActions.deleteEdgeDevice.fulfilled,
      (state: EdgeDevicesState) => {
        state.loadingEdgeDevicesCrud = false;
        state.edgeDevicesError = null;
        state.deletedSuccessfully = true;
      }
    )
    .addCase(
      edgeDevicesExtraActions.deleteEdgeDevice.rejected,
      (state: EdgeDevicesState, action) => {
        state.loadingEdgeDevicesCrud = false;
        state.edgeDevicesError = {
          label: "edgeDevices.deleteFailed",
          message: action.error.message,
        };
        state.deletedSuccessfully = false;
      }
    );
};

const edgeDeviceGetIntegrationsReducer = (
  builder: ActionReducerMapBuilder<EdgeDevicesState>
) => {
  builder
    .addCase(
      edgeDevicesExtraActions.getEdgeDeviceIntegrations.pending,
      (state: EdgeDevicesState) => {
        state.edgeDeviceIntegrationsList = [];
        state.loadingEdgeDeviceIntegrations = true;
        state.edgeDevicesError = null;
      }
    )
    .addCase(
      edgeDevicesExtraActions.getEdgeDeviceIntegrations.fulfilled,
      (
        state: EdgeDevicesState,
        action: PayloadAction<EdgeDeviceIntegrationsResponseDTO>
      ) => {
        state.loadingEdgeDeviceIntegrations = false;
        state.edgeDevicesError = null;
        state.edgeDeviceIntegrationsList =
          action.payload.edgeDeviceIntegrations;
      }
    )
    .addCase(
      edgeDevicesExtraActions.getEdgeDeviceIntegrations.rejected,
      (state: EdgeDevicesState, action) => {
        state.loadingEdgeDeviceIntegrations = false;
        state.edgeDevicesError = {
          label: "edgeDevices.getEdgeDeviceIntegrationsFailed",
          message: action.error.message,
        };
      }
    );
};

const edgeDevicesGetAllDriversReducer = (
  builder: ActionReducerMapBuilder<EdgeDevicesState>
) => {
  builder
    .addCase(
      edgeDevicesExtraActions.getAllDrivers.pending,
      (state: EdgeDevicesState) => {
        state.loadingDriversList = true;
        state.edgeDevicesError = null;
      }
    )
    .addCase(
      edgeDevicesExtraActions.getAllDrivers.fulfilled,
      (state: EdgeDevicesState, action: PayloadAction<DriversResponseDTO>) => {
        state.loadingDriversList = false;
        state.driversList = action.payload.drivers;
      }
    )
    .addCase(
      edgeDevicesExtraActions.getAllDrivers.rejected,
      (state: EdgeDevicesState, action) => {
        state.loadingDriversList = false;
        state.edgeDevicesError = {
          label: "edgeDevices.getDriversFailed",
          message: action.error.message,
        };
      }
    );
};

const edgeDevicesGetTokenReducer = (
  builder: ActionReducerMapBuilder<EdgeDevicesState>
) => {
  builder
    .addCase(
      edgeDevicesExtraActions.getEdgeDeviceToken.pending,
      (state: EdgeDevicesState) => {
        state.loadingEdgeDeviceToken = true;
        state.edgeDevicesError = null;
      }
    )
    .addCase(
      edgeDevicesExtraActions.getEdgeDeviceToken.fulfilled,
      (state: EdgeDevicesState, action: PayloadAction<string>) => {
        state.loadingEdgeDeviceToken = false;
        state.edgeDeviceToken = action.payload;
      }
    )
    .addCase(
      edgeDevicesExtraActions.getEdgeDeviceToken.rejected,
      (state: EdgeDevicesState, action) => {
        state.loadingEdgeDeviceToken = false;
        state.edgeDevicesError = {
          label: "edgeDevices.getTokenFailed",
          message: action.error.message,
        };
      }
    );
};

const edgeDevicesAddIntegrationReducer = (
  builder: ActionReducerMapBuilder<EdgeDevicesState>
) => {
  builder
    .addCase(
      edgeDevicesExtraActions.addEdgeDeviceIntegration.pending,
      (state: EdgeDevicesState) => {
        state.loadEdgeDeviceIntegrationsCrud = true;
        state.edgeDevicesError = null;
        state.integrationAddedSuccessfully = null;
      }
    )
    .addCase(
      edgeDevicesExtraActions.addEdgeDeviceIntegration.fulfilled,
      (state: EdgeDevicesState) => {
        state.loadEdgeDeviceIntegrationsCrud = false;
        state.integrationAddedSuccessfully = true;
      }
    )
    .addCase(
      edgeDevicesExtraActions.addEdgeDeviceIntegration.rejected,
      (state: EdgeDevicesState, action) => {
        state.loadEdgeDeviceIntegrationsCrud = false;
        state.edgeDevicesError = {
          label: "edgeDevices.createFailed",
          message: action.error.message,
        };
        state.integrationAddedSuccessfully = false;
      }
    );
};

const edgeDevicesExtraReducers = (
  builder: ActionReducerMapBuilder<EdgeDevicesState>
): any => {
  edgeDevicesGetAllReducer(builder);
  edgeDevicesCreateNewReducer(builder);
  edgeDevicesDeleteReducer(builder);
  edgeDeviceGetIntegrationsReducer(builder);
  edgeDevicesGetAllDriversReducer(builder);
  edgeDevicesGetTokenReducer(builder);
  edgeDevicesAddIntegrationReducer(builder);
};

export default edgeDevicesExtraReducers;
