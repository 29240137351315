// General
import React from "react";
import { useI18n } from "compass-commons";
// Components
import { Button } from "dms-lib";
// Styles
import "./siteDialogActions.module.css";

/* eslint-disable @typescript-eslint/no-explicit-any */

interface SiteDialogActionsProps {
  onCancel: (event?: any) => void;
  onSuccess: (event?: any) => void;
}

const SiteDialogActions = ({
  onCancel,
  onSuccess,
}: SiteDialogActionsProps): JSX.Element => {
  const { t } = useI18n();
  return (
    <div className="site-dialog-actions__wrapper">
      <Button color="primary" variant="contained" onClick={onCancel}>
        {t("site.deleteModal.button.cancel")}
      </Button>
      <Button color="secondary" variant="outlined" onClick={onSuccess}>
        {t("site.deleteModal.button.delete")}
      </Button>
    </div>
  );
};

export default SiteDialogActions;
