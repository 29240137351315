// General
import React from "react";
import { Control, Controller } from "react-hook-form";
import * as z from "zod";
import { useI18n } from "compass-commons";
// Styles
import "../../subsystemConnectionDataPanel.module.css";
// Components
import { TextField } from "dms-lib";
import ConnectionPropertyHeader from "../connectionPropertyHeader";
// Model
import { ConnectionPropertyTemplateDto } from "../../../../../../../../models/subsystems/ConnectionPropertyTemplateDto";
import { FormErrors } from "../../../../models/SubsystemConnectionDataTypes";
import { ConnectionPropertyType } from "../../../../../../../../models/subsystems/ConnectionPropertyType";
import { ConnectionPropertiesData } from "../../../../../../../../models/subsystems/SubsystemDto";
// Utils
import { compassOptional } from "../../../../../../../../utils/Validations";

interface ConnectionPropertyIntegerProps {
  dataCr: string;
  connectionPropertyInteger: ConnectionPropertyTemplateDto;
  readOnlyMode: boolean;
  control: Control<ConnectionPropertiesData, any>;
  errors?: FormErrors<ConnectionPropertiesData>;
}

const ConnectionPropertyInteger = ({
  dataCr,
  connectionPropertyInteger,
  readOnlyMode,
  control,
  errors,
}: ConnectionPropertyIntegerProps): JSX.Element => {
  const { t } = useI18n();

  const baseSchema = z.number({
    invalid_type_error: t("subsystems.propertiesFieldsValidation.number", {
      label: connectionPropertyInteger.label,
    }),
  });

  const requiredSchema = baseSchema.int(
    t("subsystems.propertiesFieldsValidation.required", {
      label: connectionPropertyInteger.label,
    })
  );

  const composedSchema = z.object({
    connectionPropertyInteger: compassOptional(
      baseSchema,
      requiredSchema,
      !connectionPropertyInteger.required
    ),
  });

  const buildComplexValue = (value) => ({
    id: connectionPropertyInteger.id,
    propertyType: ConnectionPropertyType.INTEGER,
    connectionPropertyInteger: parseInt(value, 10),
  });

  return (
    <div className="config-subsystem-connection-data__field-wrapper--width">
      <ConnectionPropertyHeader
        dataCr={dataCr}
        label={connectionPropertyInteger.label}
        required={connectionPropertyInteger.required}
      />
      <div id="input-wrapper">
        <Controller
          name={connectionPropertyInteger.label}
          rules={{
            validate: (value) =>
              composedSchema
                .parseAsync(value)
                .then((result) => !!result)
                .catch((e: z.ZodError) => e.issues[0].message),
          }}
          control={control}
          defaultValue={buildComplexValue(
            connectionPropertyInteger.connectionPropertyData.data
          )}
          render={({ field: { onChange, value, ...field } }) => {
            return (
              <TextField
                {...field}
                data-cr={dataCr}
                type="number"
                id={`property-id-${connectionPropertyInteger.id}`}
                className="field-width"
                size="small"
                disabled={readOnlyMode}
                aria-label={`property-name-${connectionPropertyInteger.label}`}
                value={value.connectionPropertyInteger}
                onChange={(event) => {
                  onChange(buildComplexValue(event.target.value));
                }}
                error={!!errors?.[connectionPropertyInteger.label]}
                helperText={errors?.[connectionPropertyInteger.label]?.message}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

ConnectionPropertyInteger.defaultProps = {
  errors: undefined,
};

export default ConnectionPropertyInteger;
