// General
import React from "react";
import { useI18n } from "compass-commons";
// Components
import { Typography } from "@mui/material";
import { Button } from "dms-lib";
// Models
import IncidentTypeDrawerState from "../../../../../models/incidents/IncidentTypeDrawerState";
import { IncidentTypeDto } from "../../../../../models/incidents/IncidentTypeDto";

export interface DeleteIncidentTypePanelProps {
  onDelete: (incidentTypeId: string) => void;
  selectedIncidentType: IncidentTypeDto;
  configDrawerState: (s: IncidentTypeDrawerState, i?: IncidentTypeDto) => void;
  Icon: any;
  colorIcon:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  message?: JSX.Element;
  subMessage?: JSX.Element;
}

const DeleteIncidentTypePanel = (
  props: DeleteIncidentTypePanelProps
): JSX.Element => {
  const {
    selectedIncidentType,
    onDelete,
    configDrawerState,
    Icon,
    colorIcon,
    subMessage,
    message,
  } = props;
  const { t: translate } = useI18n();

  return (
    <>
      <div className="config-delete-incident-type__panel__header">
        <Button
          className="config-delete-incident-type__icons"
          color={colorIcon}
          variant="contained"
          size="large"
          icon
        >
          {Icon}
        </Button>
        <Typography className="config-delete-incident-type__panel__header__title">
          {message}
          <br />
          {subMessage || ""}
        </Typography>
      </div>

      <div className="config-delete-incident-type__panel__body-bottom">
        <div
          className="config-delete-incident-type__panel__body-bottom__actions"
          data-cr="delete-incident-type-panel-body-bottom-actions"
        >
          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={() => configDrawerState(IncidentTypeDrawerState.List)}
          >
            <span>{translate("cancel", { ns: "Shared" })}</span>
          </Button>
          <Button
            size="medium"
            color="primary"
            variant="text"
            type="submit"
            onClick={() => onDelete(selectedIncidentType.id)}
          >
            <span>{translate("delete", { ns: "Shared" })}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default DeleteIncidentTypePanel;
