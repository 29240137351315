// General
import React from "react";
// Components
import SelectedEdgeDeviceIntegrationsHeader from "./SelectedEdgeDeviceIntegrationsHeader";
import SelectedEdgeDeviceIntegrationsBody from "./SelectedEdgeDeviceIntegrationsBody";
// Models
import { EdgeDeviceDTO } from "../../../../models/edgeDevices/EdgeDeviceDTO";
// Styles
import "./selectedEdgeDeviceIntegrations.module.css";

interface SelectedEdgeDeviceIntegrationsProps {
  edgeDevice: EdgeDeviceDTO;
}

const SelectedEdgeDeviceIntegrations = ({
  edgeDevice,
}: SelectedEdgeDeviceIntegrationsProps): JSX.Element => {
  return (
    <section className="selected-edge-device-integrations-panel">
      <SelectedEdgeDeviceIntegrationsHeader edgeDeviceId={edgeDevice.id} />
      <SelectedEdgeDeviceIntegrationsBody edgeDeviceId={edgeDevice.id} />
    </section>
  );
};

export default SelectedEdgeDeviceIntegrations;
