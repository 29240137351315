import React from "react";
import "./dropdown.module.css";
import Select, { StylesConfig } from "react-select";
import { Options } from "react-select/dist/declarations/src/types";

interface DropdownProps {
  isLoading?: boolean;
  options?: Options<any>;
  handleOptionSelect?: (option) => void;
  selectedOption?: Options<any>;
  overrideStyles?: StylesConfig;
  placeholder?: string;
  isDisabled?: boolean;
  previouslySelectedOption?: string;
}

const CompassDropdown = (props: DropdownProps): JSX.Element => {
  const {
    isLoading,
    options,
    handleOptionSelect,
    selectedOption,
    overrideStyles,
    placeholder,
    isDisabled,
    previouslySelectedOption,
  } = props;

  const getFontWeight = (data) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return previouslySelectedOption &&
      data?.value &&
      data?.value.toString() === previouslySelectedOption.toString()
      ? "bold"
      : "";
  };

  const colourStyles: StylesConfig = {
    control: (styles, { isFocused, menuIsOpen }) => {
      const color = "var(--compass-secondary)";
      const boxShadow = "0 0 0 1px var(--compass-secondary)";
      return {
        ...styles,
        backgroundColor: "white",
        borderColor: menuIsOpen || isFocused ? color : "#ccc",
        boxShadow: menuIsOpen ? boxShadow : "none",
        ":hover": {
          ...styles[":hover"],
          borderColor: color,
        },
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-shadow
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = "var(--compass-secondary)";
      const colorHover = "var(--lightGrey)";

      return {
        ...styles,
        backgroundColor: isSelected ? color : null,
        color: isSelected ? "white" : "black",
        cursor: isDisabled ? "not-allowed" : "default",
        ":active": {
          ...styles[":active"],
          color: isFocused ? "white" : "black",
          backgroundColor: isFocused ? color : null,
        },
        fontWeight: getFontWeight(data),
        ":hover": {
          ...styles[":hover"],
          backgroundColor: !isSelected && colorHover,
        },
      };
    },
  };

  const handleSelect = (option) => {
    handleOptionSelect(option);
  };

  return (
    options?.length > 0 && (
      <div data-cr="compass-dropdown">
        <Select
          value={selectedOption}
          placeholder={placeholder}
          options={options}
          isLoading={isLoading}
          className="compass-dropdown"
          classNamePrefix="floor-plans"
          onChange={handleSelect}
          styles={overrideStyles || colourStyles}
          isDisabled={isDisabled}
        />
      </div>
    )
  );
};

CompassDropdown.defaultProps = {
  isLoading: false,
  options: null,
  selectedOption: null,
  handleOptionSelect: () => null,
  overrideStyles: null,
  placeholder: "Select...",
  isDisabled: false,
  previouslySelectedOption: null,
};

export default CompassDropdown;
