// General
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "compass-commons";
// Styles
import "./behaviourListPanel.module.css";
// Components
import { Button } from "dms-lib";
import CollapsiblePanel from "../../../commons/collapsiblePanel";
// Store
import { selectIncidents, useStoreDispatch } from "../../../../store";
import { incidentsActions } from "../../../../store/incidents";
// Models
import { RuleLightDto } from "../../../../models/incidents/RuleLightDto";
import { RuleDto } from "../../../../models/incidents/RuleDto";
import { NEW_RULE_ID_PLACEHOLDER } from "../../utils/Settings";

interface BehaviourListPanelProps {
  selectedSiteId: string;
  selectedRule: RuleDto;
  filteredRuleList: RuleLightDto[];
}

const BehaviourListPanel = (props: BehaviourListPanelProps): JSX.Element => {
  const { t: translate } = useI18n();
  const { selectedSiteId, selectedRule, filteredRuleList } = props;

  const dispatch = useStoreDispatch();

  // Redux State Management
  const { loadingRulesList, searchTerm } = useSelector(selectIncidents);

  const searchCallback = useCallback(
    (filter: string) => dispatch(incidentsActions.filterTextChanged(filter)),
    []
  );

  useEffect(() => {
    if (selectedSiteId) dispatch(incidentsActions.getAllRules(selectedSiteId));
  }, [selectedSiteId]);

  const selectRulesCallback = (rule: RuleLightDto | undefined) => {
    if (rule?.ruleId !== NEW_RULE_ID_PLACEHOLDER) {
      dispatch(incidentsActions.getRuleById(rule?.ruleId));
    }
  };

  const behaviourListPanelHeader = useCallback(() => {
    return (
      <b
        data-cr="incidents-rules-list-header"
        className="config-incidents__rules-list--header"
      >
        1. {translate("behaviors.behaviours")}
      </b>
    );
  }, []);

  const smallBehaviourListPanelHeader = useCallback(() => {
    return (
      <b
        data-cr="incidents-rules-list-small-header"
        className="config-incidents__rules-list--small-header"
      >
        1.
      </b>
    );
  }, []);

  const LoadingRules = useCallback(() => {
    return (
      <div className="config-incidents__rules-list--loading">
        {translate("loadingContent")}
      </div>
    );
  }, []);

  return (
    <CollapsiblePanel
      dataCr="incidents-rules-list"
      searchBar={{
        searchCallback,
        searchTerm,
        placeholderLabel: translate("behaviors.searchBehavior"),
      }}
      additionalHeader={behaviourListPanelHeader()}
      closedAdditionalHeader={smallBehaviourListPanelHeader()}
    >
      <div
        className="config-incidents__rules-list--in"
        data-cr="incidents-rules-list-items"
      >
        {(loadingRulesList && <LoadingRules />) ||
          (filteredRuleList?.length > 0 ? (
            filteredRuleList?.map((rule) => (
              <Button
                color="primary"
                variant={
                  rule.ruleId === selectedRule?.ruleId ? "contained" : "text"
                }
                dataCr="incidents-rules-list-item"
                key={rule?.ruleId}
                className={`config-incidents__rules-item${
                  rule?.disabled ? " disabled" : ""
                }${rule.ruleId === selectedRule?.ruleId ? " selected" : ""}`}
                onClick={() => selectRulesCallback(rule)}
                title={rule.displayName}
              >
                {rule.displayName}
              </Button>
            ))
          ) : (
            <div className="config-incidents__rules-list--empty">
              {translate("behaviors.noBehavior")}
            </div>
          ))}
      </div>
    </CollapsiblePanel>
  );
};

export default BehaviourListPanel;
