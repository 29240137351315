import React from "react";
import { useI18n } from "compass-commons";
import { TagAddRounded } from "dms-lib";
import { OgBlockTypeDto } from "../../../../../models/ogTaskTypes/OgBlockTypeDto";

interface ContextTagBlockTypeProps {
  block: OgBlockTypeDto;
}

const ContextTagBlockType = (props: ContextTagBlockTypeProps): JSX.Element => {
  const { block } = props;
  const { t } = useI18n();

  return (
    <div
      className="compass-rounded-border task-widget-align-vert-center task-input-text-default"
      id="context-tag-block-type"
      data-cr="context-tag-block-type"
    >
      <TagAddRounded className="task-icon" />
      <span>{block?.name || t("operatorGuide.tagTriggerResource")}</span>
    </div>
  );
};

export default ContextTagBlockType;
