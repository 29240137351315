import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Components
import { RootState } from "..";
// Models
import {
  SubsystemLightConnections,
  SubsystemLightDto,
  SubsystemLightResources,
} from "../../models/subsystems/SubsystemLightDto";
import {
  convertToSubsystemConnections,
  convertToSubsystemResources,
} from "../../models/subsystems/mapper/SubsystemMapper";
// Reducers
import subsystemsExtraReducers from "./extraReducers";
// Actions
import subsystemsExtraActions from "./extraActions";
import { ConnectionPropertyTemplateDto } from "../../models/subsystems/ConnectionPropertyTemplateDto";
import { ModelDto } from "../../models/subsystems/ModelDto";
import { BrandDto } from "../../models/subsystems/BrandDto";
// Utils
// Utils
import { NEW_SUBSYSTEM_ID_PLACEHOLDER } from "../../utils/Constants";
import SubsystemService from "../../services/SubsystemService";

/* eslint-disable no-param-reassign */

type SubsystemErrorType = { label: string; message: string };

export type SubsystemsState = {
  selectedSubsystem?: SubsystemLightConnections;
  selectedSubsystemResources?: SubsystemLightResources;
  disableRefreshingResources: boolean;
  subsystemsList: SubsystemLightConnections[];
  searchTerm: string;
  filteredSubsystemsList: SubsystemLightConnections[];
  loadingSubsystemsList?: boolean;
  subsystemError?: SubsystemErrorType;
  subsystemCrudLoading?: boolean;
  connectionPropertyTemplates: ConnectionPropertyTemplateDto[];
  connectionPropertiesLoading?: boolean;
  models: ModelDto[];
  loadingModels?: boolean;
  loadingModelsRequests: number;
  brands: BrandDto[];
  loadingBrands?: boolean;
};

const SLICE_NAME = "subsystems";

export const initialState: SubsystemsState = {
  selectedSubsystem: undefined, // ID of the current selected Subsystem
  selectedSubsystemResources: undefined,
  disableRefreshingResources: false,
  subsystemsList: [],
  searchTerm: "",
  filteredSubsystemsList: [],
  loadingSubsystemsList: false,
  subsystemError: undefined,
  subsystemCrudLoading: false,
  connectionPropertyTemplates: [],
  connectionPropertiesLoading: false,
  models: [],
  loadingModels: false,
  loadingModelsRequests: 0,
  brands: [],
  loadingBrands: false,
};

export function filterSubsystems(
  state: SubsystemsState
): SubsystemLightConnections[] {
  return state.subsystemsList?.filter((s) => {
    return s?.name
      ?.toLowerCase()
      .includes(state.searchTerm.trim().toLowerCase());
  });
}

/**
 * Slice to manipulate all operations related to subsystems
 */
export const subsystemsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    siteChanged: (state: SubsystemsState) => {
      state.subsystemsList = [];
    },
    filterTextChanged: (
      state: SubsystemsState,
      action: PayloadAction<string>
    ) => {
      state.searchTerm = action.payload;
      state.filteredSubsystemsList = filterSubsystems(state);
    },
    selectSubsystemById: (
      state: SubsystemsState,
      action: PayloadAction<string>
    ) => {
      state.connectionPropertyTemplates = [];
      state.connectionPropertiesLoading = false;
      state.models = [];
      // If we are changing a subsystem then abort model requests
      SubsystemService.abortController?.abort();
      state.loadingModels = false;
      const subsystem = state.subsystemsList.find(
        (s) => s.id === action.payload
      );
      state.selectedSubsystem = convertToSubsystemConnections(subsystem);
      state.selectedSubsystemResources = convertToSubsystemResources(subsystem);
    },
    subsystemSelected: (
      state: SubsystemsState,
      action: PayloadAction<SubsystemLightDto>
    ) => {
      state.connectionPropertyTemplates = [];
      state.connectionPropertiesLoading = false;
      state.models = [];
      // If we are changing a subsystem then abort model requests
      SubsystemService.abortController?.abort();
      state.loadingModels = false;
      state.selectedSubsystem = convertToSubsystemConnections(action.payload);
      state.selectedSubsystemResources = convertToSubsystemResources(
        action.payload
      );
    },
    updateSubsystemRefreshState: (
      state: SubsystemsState,
      action: PayloadAction<SubsystemLightDto>
    ) => {
      if (action.payload.id === state.selectedSubsystem.id)
        state.selectedSubsystemResources = {
          ...state.selectedSubsystemResources,
          updatingResources: action.payload.updatingResources,
        };
    },
    resetSubsystems: (state: SubsystemsState) => {
      state.subsystemsList = [];
      state.filteredSubsystemsList = [];
      state.selectedSubsystem = undefined;
      state.selectedSubsystemResources = undefined;
      state.disableRefreshingResources = false;
      state.searchTerm = "";
      state.subsystemError = undefined;
      state.subsystemCrudLoading = false;
      state.connectionPropertyTemplates = [];
      state.connectionPropertiesLoading = false;
      state.models = [];
      state.loadingModels = false;
      state.loadingModelsRequests = 0;
      state.brands = [];
      state.loadingBrands = false;
    },
  },
  extraReducers: subsystemsExtraReducers,
});

// Action creators are generated for each case reducer function
export const subsystemsActions = {
  ...subsystemsSlice.actions,
  ...subsystemsExtraActions,
};

// Subsystem Selectors
export const selectIsPlaceholderSubsystem = ({
  subsystems,
}: RootState): boolean =>
  subsystems.selectedSubsystem?.id === NEW_SUBSYSTEM_ID_PLACEHOLDER;

export const getSubsystemModel = ({ subsystems }: RootState): ModelDto => {
  const subsystemModel = subsystems.models.find((model) => {
    return model.id === subsystems.selectedSubsystem?.modelId;
  });
  // Important to pass null instead of undefined. In case it doesn't exists
  return subsystemModel || null;
};

export const selectSubsystemCRUDLoading = ({
  subsystems,
}: RootState): boolean => {
  return subsystems.subsystemCrudLoading;
};

export default subsystemsSlice.reducer;
