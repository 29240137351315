// General
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
// Styles
import "./AddEditIncidentType.module.css";
// Components
import AddEditIncidentTypeName from "./components/AddEditIncidentTypeName";
import AddEditIncidentTypeActions from "./components/AddEditIncidentTypeActions";
import AddEditIncidentTypeIcon from "./components/AddEditIncidentTypeIcon";
// Models
import IncidentTypeDrawerState from "../../../../models/incidents/IncidentTypeDrawerState";
import { IncidentTypeDto } from "../../../../models/incidents/IncidentTypeDto";
import { FormIncidentTypeDto } from "../../../../models/incidents/form/FormIncidentTypeDto";
// Store
import { useStoreDispatch } from "../../../../store";
import { incidentsActions } from "../../../../store/incidents";

export interface AddEditIncidentTypesProps {
  configDrawerState: (s: IncidentTypeDrawerState, i?: IncidentTypeDto) => void;
  drawerState: IncidentTypeDrawerState;
  incidentType?: IncidentTypeDto;
}

const getFormDefaultValue = (
  drawerState: IncidentTypeDrawerState,
  incidentTypeDetails: IncidentTypeDto
) => {
  if (drawerState === IncidentTypeDrawerState.Edit) {
    return {
      name: incidentTypeDetails?.name,
      iconPath: incidentTypeDetails?.iconPath,
    };
  }
  return {};
};

const AddEditIncidentType = (props: AddEditIncidentTypesProps): JSX.Element => {
  const { configDrawerState, drawerState, incidentType } = props;
  const dispatch = useStoreDispatch();
  const defaultValues = getFormDefaultValue(drawerState, incidentType);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<FormIncidentTypeDto>({
    defaultValues,
    resolver: zodResolver(FormIncidentTypeDto),
    mode: "onChange",
  });

  const handleSave = useCallback(
    (data) => {
      configDrawerState(IncidentTypeDrawerState.List);
      if (IncidentTypeDrawerState.Add === drawerState) {
        dispatch(incidentsActions.createIncidentType(data));
      } else {
        dispatch(
          incidentsActions.updateIncidentType({
            incidentTypeId: incidentType.id,
            incidentTypeRequestDTO: data,
          })
        );
      }
    },
    [dispatch, drawerState, incidentType]
  );

  const onSave = handleSubmit(handleSave);

  return (
    <div className="config-add-edit-incident-type__panel">
      <AddEditIncidentTypeName control={control} errors={errors} />
      <AddEditIncidentTypeIcon control={control} />
      <AddEditIncidentTypeActions
        onSave={onSave}
        isDisabled={!(isValid && isDirty)}
        configDrawerState={configDrawerState}
      />
    </div>
  );
};

export default AddEditIncidentType;
