import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Components
import { RootState } from "..";
// Models
import {
  SiteTreeNodeDto,
  SiteHierarchy,
  SitesHashMetadata,
} from "../../models/sites/SiteTreeNodeDto";
// Reducers
import sitesExtraReducers from "./extraReducers";
// Actions
import sitesExtraActions from "./extraActions";
import { ConfigModes, readOnlyMode } from "../root";

/* eslint-disable no-param-reassign */

interface GenericState<T> {
  data?: T;
  loading: boolean;
}

type SiteErrorType = { label: string; message: string };

export const SITE_PLACEHOLDER = {
  id: "placeholder_id",
  name: "Untitled site",
  timeZone: "EST5EDT",
  isTimeZoneInherited: true,
  childSites: [],
};

export type SitesState = {
  siteTabActive: boolean;
  selectedSiteId?: string;
  // Saves the all the node structure where the new site lives
  siteLastSnap?: SitesHashMetadata;
  siteCurrentSnap?: SitesHashMetadata;
  siteRoot: GenericState<SiteTreeNodeDto>;
  siteHierarchy: SiteHierarchy;
  siteError?: SiteErrorType;
  siteCrudLoading?: boolean;
};

const SLICE_NAME = "sites";

const initialState: SitesState = {
  siteTabActive: false,
  selectedSiteId: undefined, // ID of the current selected Site
  siteLastSnap: undefined, // Keeps track of the last state before site config actions
  siteCurrentSnap: undefined, // Keeps track of the current state when not in readonly
  siteRoot: {
    loading: false,
    data: undefined,
  },
  siteHierarchy: {}, // Easy access through a node id to the site node data
  siteError: undefined,
  siteCrudLoading: false,
};

/**
 * Slice to manipulate all operations related to sites
 */
export const sitesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateCurrentSnap: (
      state: SitesState,
      action: PayloadAction<SiteTreeNodeDto>
    ) => {
      state.siteCurrentSnap = {
        ...state.siteCurrentSnap,
        metadata: action.payload,
      };
    },
    // Keeps a structure that helps manipulating leaf and search for sites
    // Ex. SiteId: SiteHierarchy - { Ancestors and SiteInfo }
    addToSiteHierarchy: (
      state: SitesState,
      action: PayloadAction<SitesHashMetadata>
    ) => {
      const siteId = action.payload.metadata.id;
      state.siteHierarchy[siteId] = action.payload;
    },
    // When changing the site we want to update the selected site as well as the selectedSiteInfo
    changeSelectedSiteId: (
      state: SitesState,
      action: PayloadAction<string>
    ) => {
      state.selectedSiteId = action.payload;
    },
  },
  extraReducers: sitesExtraReducers,
});

// Action creators are generated for each case reducer function
export const sitesActions = { ...sitesSlice.actions, ...sitesExtraActions };

// Sites Selectors
export const selectIsPlaceholderSite = ({ sites }: RootState): boolean =>
  sites.selectedSiteId === SITE_PLACEHOLDER.id;

export const selectedSiteMetadata = ({ sites }: RootState): SiteTreeNodeDto =>
  sites.siteHierarchy?.[sites.selectedSiteId]?.metadata;
export const selectedSiteAncestors = ({
  sites,
}: RootState): SiteTreeNodeDto[] =>
  sites.siteHierarchy?.[sites.selectedSiteId]?.ancestors;
export const selectedSiteParentId = ({ sites }: RootState): string =>
  sites.siteHierarchy?.[sites.selectedSiteId]?.ancestors.slice(-1)?.[0]?.id;
export const selectedSiteInfo = ({ sites }: RootState): SitesHashMetadata =>
  sites.siteHierarchy?.[sites.selectedSiteId];
export const selectSiteCRUDLoading = ({ sites }: RootState): boolean => {
  return sites.siteCrudLoading;
};
// Selects the new temporary site created under the siteCurrentSnapshot
export const currentSiteSnapAddedChild = ({
  sites,
}: RootState): SiteTreeNodeDto => {
  return sites.siteCurrentSnap?.metadata?.childSites.slice(-1)?.[0];
};

export const selectSiteBeingManipulated = (
  rootState: RootState
): SiteTreeNodeDto | undefined => {
  const siteManipulatedSelector = Object.freeze({
    [ConfigModes.CREATE]: () => currentSiteSnapAddedChild(rootState),
    [ConfigModes.EDIT]: () => rootState.sites.siteCurrentSnap?.metadata,
  });
  return siteManipulatedSelector[rootState.root.configMode]?.();
};

export const siteBeingManipulated = (rootState: RootState): boolean =>
  !readOnlyMode(rootState) &&
  selectSiteBeingManipulated(rootState)?.id === rootState.sites.selectedSiteId;

export default sitesSlice.reducer;
