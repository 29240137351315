// General
import React, { PropsWithChildren, useState } from "react";
// Styles
import "./collapsiblePanelStyles.module.css";
// Components
import { Button } from "dms-lib";
import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import SearchBar from "../searchBar/SearchBar";

export interface SearchBarConfig {
  searchCallback: (searchInput: string) => void;
  searchTerm?: string;
  placeholderLabel?: string;
  // styles and tests
  dataCr?: string;
}

export interface CollapsiblePanelProps {
  dataCr?: string;
  className?: string;
  searchBar?: SearchBarConfig;
  additionalHeader?: JSX.Element;
  closedAdditionalHeader?: JSX.Element;
}
/**
 * Wrapper for the Collapsible menu
 * Has all the logic to handle a collapsible panel
 * @param searchBar - decides weather it should show a search bar
 * if configured than it will show and some parameters are mandatory.
 *  - searchCallback: What to do when search changes
 *  - searchTerm: current input box value
 *  - (optional) placeholderLabel: placeholder value when no input is provided
 * @param dataCr
 * @param className
 * @param children
 * @param additionalHeader
 * @param closedAdditionalHeader
 * @returns JSX.Element
 */
const CollapsiblePanel = ({
  dataCr,
  className,
  searchBar,
  children,
  additionalHeader,
  closedAdditionalHeader,
}: PropsWithChildren<CollapsiblePanelProps>): JSX.Element => {
  const {
    searchCallback,
    searchTerm,
    placeholderLabel,
    dataCr: searchDataCr,
  } = searchBar || {};
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = (forceState?: boolean) => {
    setIsOpen((prevState) => forceState ?? !prevState);
  };

  return (
    <article
      data-cr={`${dataCr || "collapsible-wrapper"}`}
      className={`compass-rounded-corner config-collapsible__wrapper${
        !isOpen ? " config--collapse" : ""
      }${className ? ` ${className}` : ""}`}
    >
      {isOpen ? additionalHeader : closedAdditionalHeader}
      <div data-cr="collapsible-header" className="config-collapsible__header">
        {searchBar && (
          <div
            className={`config-collapsible__search${
              !isOpen ? " config--hidden" : ""
            }`}
          >
            <SearchBar
              searchCallback={searchCallback}
              dataCr={`${searchDataCr || "collapsible-panel-search-bar"}`}
              placeholder={placeholderLabel || ""}
              value={searchTerm}
            />
          </div>
        )}

        <div>
          <Button
            id="panelCollapsible"
            name="panelCollapsible"
            color="primary"
            variant="contained"
            onClick={() => toggleSidebar()}
            icon
          >
            {isOpen ? (
              <KeyboardDoubleArrowLeftRoundedIcon />
            ) : (
              <KeyboardDoubleArrowRightRoundedIcon />
            )}
          </Button>
        </div>
      </div>
      <div
        id="config-collapsible__content"
        className={`config-collapsible__content${
          !isOpen ? " config--hidden" : ""
        }`}
      >
        {isOpen && children}
      </div>
    </article>
  );
};

CollapsiblePanel.defaultProps = {
  additionalHeader: null,
  debounceTime: undefined,
};

export default CollapsiblePanel;
