import { array, boolean, object, string, z, ZodIssueCode } from "zod";
import RuleStringConstraint from "./RuleStringConstraint";
import RuleStringOptional from "./RuleStringOptional";

export const TriggerDtoSchema = object({
  eventTypeId: string().optional().nullable(),
  eventTypeSubsystemId: string().optional().nullable(),
  eventTypeSubsystemName: RuleStringOptional,
  disabled: boolean(),
  commentForTriggerBeingDisabled: RuleStringOptional,
  triggerResourceMappingDTOs: array(
    object({
      id: RuleStringConstraint,
      name: RuleStringConstraint,
    })
  ),
  daysOfWeek: array(string())
    .min(1, "behaviors.propertiesFieldsValidation.weekDays.atLeastOne")
    .refine((arr) => arr.every((txt: string) => txt.trim().length >= 1), {
      message: "behaviors.propertiesFieldsValidation.required",
      path: ["daysOfWeek"],
    }),
  availableForOnDemand: boolean(),
}).superRefine((data, ctx) => {
  if (!data.availableForOnDemand) {
    if (!data.eventTypeId) {
      ctx.addIssue({
        path: ["eventTypeId"],
        message: "behaviors.propertiesFieldsValidation.required",
        code: ZodIssueCode.custom,
      });
    }

    if (!data.eventTypeSubsystemId) {
      ctx.addIssue({
        path: ["eventTypeSubsystemId"],
        message: "behaviors.propertiesFieldsValidation.required",
        code: ZodIssueCode.custom,
      });
    }

    if (!data.eventTypeSubsystemName) {
      ctx.addIssue({
        path: ["eventTypeSubsystemName"],
        message: "behaviors.propertiesFieldsValidation.required",
        code: ZodIssueCode.custom,
      });
    }
  }
});

export type TriggerDto = z.infer<typeof TriggerDtoSchema>;
