// Generic
import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
// Actions
import resourceMappingExtraActions from "../extraActions";
// Models
import { PROP_SEARCH_TERM, ResourceMappingState } from "..";
import { sortByProp } from "../../../utils/Util";
import { ResourceMappingDto } from "../../../models/resourceMapping/ResourceMappingDto";

/* eslint-disable no-param-reassign, @typescript-eslint/no-explicit-any */

const resourceMappingGetAllReducer = (
  builder: ActionReducerMapBuilder<ResourceMappingState>
) => {
  builder
    .addCase(
      resourceMappingExtraActions.getResourceMappings.pending,
      (state: ResourceMappingState) => {
        state.loadingResourceMappingList = true;
        state.resourceMappingError = null;
      }
    )
    .addCase(
      resourceMappingExtraActions.getResourceMappings.fulfilled,
      (
        state: ResourceMappingState,
        action: PayloadAction<ResourceMappingDto[]>
      ) => {
        state.loadingResourceMappingList = false;
        state.resourceMappingList = sortByProp(
          action.payload,
          PROP_SEARCH_TERM
        );
      }
    )
    .addCase(
      resourceMappingExtraActions.getResourceMappings.rejected,
      (state: ResourceMappingState, action) => {
        state.loadingResourceMappingList = false;
        state.resourceMappingError = {
          label: "resourceMapping.resourceMappingsNotFound",
          message: action.error.message,
        };
      }
    );
};

const resourceMappingExtraReducers = (
  builder: ActionReducerMapBuilder<ResourceMappingState>
): any => {
  resourceMappingGetAllReducer(builder);
};

export default resourceMappingExtraReducers;
