// General
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useI18n } from "compass-commons";
// Components
import { AutoComplete, OptionType } from "dms-lib";
// Styles
import "../behaviourTriggerPanel.module.css";
// Models
import { FormErrors } from "../../../../subsystemConfigurator/components/connectionPropertiesPanel/models/SubsystemConnectionDataTypes";
import { SubsystemLightDto } from "../../../../../models/subsystems/SubsystemLightDto";
import { FormRuleDto } from "../../../../../models/incidents/form/FormRuleDto";

interface SubsystemOption extends OptionType {
  name: string;
}

interface BehaviourTriggerSubsystemNameProps {
  ruleId: string;
  readOnlyMode: boolean;
  control: Control<FormRuleDto, any>;
  errors?: FormErrors<FormRuleDto>;
  subsystems: SubsystemLightDto[];
  loading: boolean;
  handleSubsystemSelect: (subsystem: SubsystemLightDto) => void;
}

const BehaviourTriggerSubsystemName = (
  props: BehaviourTriggerSubsystemNameProps
): JSX.Element => {
  const {
    ruleId,
    readOnlyMode,
    control,
    errors,
    loading,
    subsystems,
    handleSubsystemSelect,
  } = props;
  const { t: translate } = useI18n();

  return (
    <div className="config-incidents__trigger-input__section">
      <span>
        {translate("behaviors.triggerFieldsDescription.subsystemName")}
      </span>
      <div className="config-incidents__trigger-input__controller">
        <Controller
          name="trigger.eventTypeSubsystem"
          control={control}
          render={({ field: { onChange, ...field } }) => {
            return (
              <AutoComplete<SubsystemOption>
                {...field}
                value={field.value as SubsystemOption}
                dataCr="incident-trigger-subsystem-name"
                options={subsystems}
                getOptionLabel={(option) => option?.name || ""}
                id={`trigger-subsystem-name-autocomplete-${ruleId}`}
                disabled={readOnlyMode}
                loading={loading}
                placeholder={translate(
                  "behaviors.triggerFieldsDescription.subsystemPlaceholder"
                )}
                onChangeCallback={(data: SubsystemLightDto, _e) => {
                  handleSubsystemSelect(data);
                  onChange(data);
                }}
                error={!!errors.trigger?.eventTypeSubsystem}
                errorMessage={translate(
                  errors.trigger?.eventTypeSubsystemName?.message ||
                    errors.trigger?.eventTypeSubsystem?.message ||
                    errors.trigger?.message,
                  { label: translate("behaviors.fields.subsystemName") }
                )}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

BehaviourTriggerSubsystemName.defaultProps = {
  errors: undefined,
};

export default BehaviourTriggerSubsystemName;
