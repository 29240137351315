// General
import React, { useEffect } from "react";
import { useFeatureFlag, useI18n } from "compass-commons";
import { Control, Controller } from "react-hook-form";
// Styles
import "../behaviourProperties.module.css";
// Components
import { AutoComplete, OptionType, ResourceIcon } from "dms-lib";
import IncidentTypesDrawer from "../../../../incidentTypesDrawer/IncidentTypesDrawer";
// Models
import { FormErrors } from "../../../../subsystemConfigurator/components/connectionPropertiesPanel/models/SubsystemConnectionDataTypes";
import { FormRuleDto } from "../../../../../models/incidents/form/FormRuleDto";
import { IncidentTypeDto } from "../../../../../models/incidents/IncidentTypeDto";
// Utils
import { NEW_RULE_ID_PLACEHOLDER } from "../../../utils/Settings";
import { INCIDENT_TYPES_CONFIGURATION_FEATURE_FLAG } from "../../../../../utils/Constants";

interface BehaviourPropertiesIncidentTypeProps {
  ruleId: string;
  readOnlyMode: boolean;
  control: Control<FormRuleDto, any>;
  errors?: FormErrors<FormRuleDto>;
  incidentTypes: IncidentTypeDto[];
  loading: boolean;
  dataCr?: string;
  handleSelectIncidentType?: (incidentType: IncidentTypeDto) => void;
  resetField: (name: string, options?: any) => void;
}

interface IncidentTypeOption extends OptionType {
  name: string;
  iconPath: string;
}

const BehaviourPropertiesIncidentType = (
  props: BehaviourPropertiesIncidentTypeProps
): JSX.Element => {
  const { t: translate } = useI18n();
  const {
    incidentTypes,
    errors,
    ruleId,
    readOnlyMode,
    control,
    loading,
    dataCr,
    handleSelectIncidentType,
    resetField,
  } = props;
  const { enabled: incidentTypesConfigFeatureFlag } = useFeatureFlag(
    appConfig,
    INCIDENT_TYPES_CONFIGURATION_FEATURE_FLAG
  );

  const getDefaultValue = () => {
    if (ruleId === NEW_RULE_ID_PLACEHOLDER && incidentTypes.length > 0) {
      const val = incidentTypes[0];
      return { id: val.id, name: val.name, iconPath: val.iconPath };
    }
    return null;
  };

  useEffect(() => {
    const value = getDefaultValue();
    if (value) {
      resetField("behaviour.incidentType", { defaultValue: value });
      handleSelectIncidentType(value);
    }
  }, [ruleId, incidentTypes]);

  return (
    <div className="config-incidents__properties__input">
      <span className="config-incidents__properties__dropdown">
        {translate("behaviors.propertyFieldsDescription.incidentType")}
      </span>
      <div className="config-incidents__properties__incident-types">
        <div
          className="config-incidents__properties__input-controller config-incidents__properties__dropdown"
          id="input-wrapper"
        >
          <Controller
            name="behaviour.incidentType"
            control={control}
            render={({ field: { onChange, ...field } }) => {
              return (
                <AutoComplete
                  {...field}
                  value={field.value as IncidentTypeOption}
                  dataCr={dataCr}
                  options={incidentTypes}
                  getOptionLabel={(option) => option?.name}
                  id={`behaviour-property-incident-type-${ruleId}`}
                  disabled={readOnlyMode}
                  loading={loading}
                  placeholder={translate(
                    "behaviors.propertyFieldsDescription.incidentTypePlaceholder"
                  )}
                  onChangeCallback={(data: IncidentTypeDto, _e) => {
                    onChange(data);
                    handleSelectIncidentType(data);
                  }}
                  error={!!errors.behaviour?.incidentType}
                  errorMessage={translate(
                    errors.behaviour?.incidentType?.message ||
                      errors.behaviour?.incidentTypeId?.message ||
                      errors.behaviour?.message,
                    { label: translate("behaviors.fields.incidentType") }
                  )}
                  IconComponent={({ value, className, dataCr: dtCr }) =>
                    value && "iconPath" in value ? (
                      <ResourceIcon
                        data-cr={dtCr}
                        className={className}
                        src={value.iconPath}
                      />
                    ) : undefined
                  }
                />
              );
            }}
          />
        </div>
        {incidentTypesConfigFeatureFlag && (
          <IncidentTypesDrawer readOnlyMode={readOnlyMode} />
        )}
      </div>
    </div>
  );
};

BehaviourPropertiesIncidentType.defaultProps = {
  dataCr: "behaviour-properties-type",
  errors: undefined,
  handleSelectIncidentType: () => undefined,
};

export default React.memo(BehaviourPropertiesIncidentType);
