// General
import React from "react";
import { Control } from "react-hook-form";
import { useI18n } from "compass-commons";
// Styles
import "./subsystemConnectionDataPanel.module.css";
import { CircularProgress } from "@mui/material";
import ConnectionPropertyString from "./components/connectionPropertyString";
import ConnectionPropertyInteger from "./components/connectionPropertyInteger";
import ConnectionPropertyBoolean from "./components/connectionPropertyBoolean";
import ConnectionPropertyPassword from "./components/connectionPropertyPassword";
import ConnectionPropertyListBox from "./components/connectionPorpertyListBox";
import ConnectionPropertyUnknown from "./components/connectionPropertyUnknown";
// Model
import { ConnectionPropertyTemplateDto } from "../../../../../../models/subsystems/ConnectionPropertyTemplateDto";
import { ConnectionPropertyType } from "../../../../../../models/subsystems/ConnectionPropertyType";
import { FormErrors } from "../../models/SubsystemConnectionDataTypes";
import { ConnectionPropertiesData } from "../../../../../../models/subsystems/SubsystemDto";

interface SubsystemConnectionDataPanelProps {
  connectionPropertyTemplates: ConnectionPropertyTemplateDto[];
  readOnlyMode: boolean;
  isLoading: boolean;
  showConnectionProperties: boolean;
  control: Control<ConnectionPropertiesData, any>;
  errors?: FormErrors<ConnectionPropertiesData>;
}

const connectionPropertyToComponent = {
  [ConnectionPropertyType.UNKNOWN]: (
    connectionProperty: ConnectionPropertyTemplateDto,
    _readOnlyMode: boolean,
    _control: Control<ConnectionPropertiesData, any>,
    _errors: FormErrors<ConnectionPropertiesData>
  ) => (
    <ConnectionPropertyUnknown
      dataCr="connection-property-unknown"
      connectionPropertyUnknown={connectionProperty}
    />
  ),
  [ConnectionPropertyType.STRING]: (
    connectionProperty: ConnectionPropertyTemplateDto,
    readOnlyMode: boolean,
    control: Control<ConnectionPropertiesData, any>,
    errors: FormErrors<ConnectionPropertiesData>
  ) => (
    <ConnectionPropertyString
      dataCr="connection-property-string"
      connectionPropertyString={connectionProperty}
      readOnlyMode={readOnlyMode}
      control={control}
      errors={errors}
    />
  ),
  [ConnectionPropertyType.BOOLEAN]: (
    connectionProperty: ConnectionPropertyTemplateDto,
    readOnlyMode: boolean,
    control: Control<ConnectionPropertiesData, any>,
    errors: FormErrors<ConnectionPropertiesData>
  ) => (
    <ConnectionPropertyBoolean
      dataCr="connection-property-boolean"
      connectionPropertyBoolean={connectionProperty}
      readOnlyMode={readOnlyMode}
      control={control}
      errors={errors}
    />
  ),
  [ConnectionPropertyType.INTEGER]: (
    connectionProperty: ConnectionPropertyTemplateDto,
    readOnlyMode: boolean,
    control: Control<ConnectionPropertiesData, any>,
    errors: FormErrors<ConnectionPropertiesData>
  ) => (
    <ConnectionPropertyInteger
      dataCr="connection-property-integer"
      connectionPropertyInteger={connectionProperty}
      readOnlyMode={readOnlyMode}
      control={control}
      errors={errors}
    />
  ),
  [ConnectionPropertyType.PASSWORD]: (
    connectionProperty: ConnectionPropertyTemplateDto,
    readOnlyMode: boolean,
    control: Control<ConnectionPropertiesData, any>,
    errors: FormErrors<ConnectionPropertiesData>
  ) => (
    <ConnectionPropertyPassword
      dataCr="connection-property-password"
      connectionPropertyPassword={connectionProperty}
      readOnlyMode={readOnlyMode}
      control={control}
      errors={errors}
    />
  ),
  [ConnectionPropertyType.LIST_BOX]: (
    connectionProperty: ConnectionPropertyTemplateDto,
    readOnlyMode: boolean,
    control: Control<ConnectionPropertiesData, any>,
    errors: FormErrors<ConnectionPropertiesData>
  ) => (
    <ConnectionPropertyListBox
      dataCr="connection-property-list-box"
      connectionPropertyListBox={connectionProperty}
      readOnlyMode={readOnlyMode}
      control={control}
      errors={errors}
    />
  ),
};

const SubsystemConnectionDataPanel = ({
  connectionPropertyTemplates,
  readOnlyMode,
  isLoading,
  showConnectionProperties,
  control,
  errors,
}: SubsystemConnectionDataPanelProps): JSX.Element => {
  const { t } = useI18n();
  return (
    showConnectionProperties && (
      <form className="config-subsystem-connection-data__content">
        <div className="config-subsystem-connection-data__field-wrapper">
          <h4
            data-cr="connection-data-title"
            className="config-subsystem-connection-data__header"
          >
            {t("subsystems.connectionData.title")}
          </h4>
        </div>
        {(!isLoading &&
          connectionPropertyTemplates.map((connectionProperty) => (
            <div
              key={`connection-property-${connectionProperty.id}`}
              className="config-subsystem-connection-data__field-wrapper config-subsystem-connection-data__field-wrapper-width"
            >
              {connectionPropertyToComponent[connectionProperty.propertyType](
                connectionProperty,
                readOnlyMode,
                control,
                errors
              )}
            </div>
          ))) || (
          <div data-cp="player-spinner" className="config-loading">
            <CircularProgress color="inherit" />
          </div>
        )}
      </form>
    )
  );
};

SubsystemConnectionDataPanel.defaultProps = {
  errors: undefined,
};

export default SubsystemConnectionDataPanel;
