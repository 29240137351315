// General
import React from "react";
import { Control, Controller } from "react-hook-form";
// Styles
import "../AddEditIncidentType.module.css";
// Components
import { TextField, FormLabel } from "@mui/material";
import { useI18n } from "compass-commons";
import { FormIncidentTypeDto } from "../../../../../models/incidents/form/FormIncidentTypeDto";
// Models
import { FormErrors } from "../../../../incidentsConfiguration/models/BehaviourFormDataTypes";

interface AddEditIncidentTypeNameProps {
  control: Control<FormIncidentTypeDto>;
  errors: FormErrors<FormIncidentTypeDto>;
}

// NamePanel component
const AddEditIncidentTypeName = ({
  control,
  errors,
}: AddEditIncidentTypeNameProps): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <div className="config-add-edit-incident-type__panel__name">
      <FormLabel error={!!errors?.name}>
        {translate("incidentTypes.upsert.selectIncidentTypeName")}
      </FormLabel>
      <Controller
        name="name"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            data-cr="add-edit-incident-type-panel-name"
            fullWidth
            size="small"
            onChange={onChange}
            placeholder={translate(
              "incidentTypes.upsert.selectIncidentTypeNamePlaceholder"
            )}
            onBlur={onBlur}
            value={value}
            error={!!errors?.name}
            helperText={
              (errors?.name?.message &&
                translate(errors.name.message, {
                  ns: "Shared",
                  label: translate("name", { ns: "Shared" }),
                })) ||
              ""
            }
          />
        )}
      />
    </div>
  );
};

export default AddEditIncidentTypeName;
