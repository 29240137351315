import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
// Models
import { MenuTabRoute } from "../../models/configMenu/MenuTabRoute";

/* eslint-disable no-param-reassign */

export enum ConfigModes {
  CREATE = "create",
  EDIT = "edit",
  DELETE = "delete",
  READONLY = "readOnly",
}

export enum ConfigEditionModes {
  SAVE = "save",
  CANCEL = "cancel",
  READONLY = "readOnly",
}

export type ConfigRootState = {
  selectedTab: MenuTabRoute;
  configMode: ConfigModes;
  configEditMode: ConfigEditionModes;
};

const SLICE_NAME = "root";
const initialState: ConfigRootState = {
  selectedTab: MenuTabRoute.Generic,
  configMode: ConfigModes.READONLY,
  configEditMode: ConfigEditionModes.READONLY,
};

export const rootSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    changeTab: (
      state: ConfigRootState,
      action: PayloadAction<MenuTabRoute>
    ) => {
      state.selectedTab = action.payload;
    },
    activateSaveMode: (state: ConfigRootState) => {
      state.configEditMode = ConfigEditionModes.SAVE;
    },
    activateCancelMode: (state: ConfigRootState) => {
      state.configEditMode = ConfigEditionModes.CANCEL;
    },
    activateEditReadOnlyMode: (state: ConfigRootState) => {
      state.configEditMode = ConfigEditionModes.READONLY;
    },
    activateCreationMode: (state: ConfigRootState) => {
      state.configMode = ConfigModes.CREATE;
    },
    activateEditionMode: (state: ConfigRootState) => {
      state.configMode = ConfigModes.EDIT;
    },
    activateDeletionMode: (state: ConfigRootState) => {
      state.configMode = ConfigModes.DELETE;
    },
    activateReadOnlyMode: (
      state: ConfigRootState,
      // Used inside sites reducer as extra arg
      // eslint-disable-next-line
      _?: PayloadAction<boolean>
    ) => {
      state.configMode = ConfigModes.READONLY;
      state.configEditMode = ConfigEditionModes.READONLY;
    },
  },
});

// Action creators are generated for each case reducer function
export const rootActions = rootSlice.actions;

// Selectors
export const creationMode = ({ root }: RootState): boolean =>
  root.configMode === ConfigModes.CREATE;
export const editionMode = ({ root }: RootState): boolean =>
  root.configMode === ConfigModes.EDIT;
export const creationOrEditionMode = ({ root }: RootState): boolean =>
  root.configMode === ConfigModes.EDIT ||
  root.configMode === ConfigModes.CREATE;
export const deletionMode = ({ root }: RootState): boolean =>
  root.configMode === ConfigModes.DELETE;
export const readOnlyMode = ({ root }: RootState): boolean =>
  root.configMode === ConfigModes.READONLY;

export default rootSlice.reducer;
