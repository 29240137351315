// General
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useI18n } from "compass-commons";
// Components
import { AutoComplete, OptionType } from "dms-lib";
// Styles
import "../behaviourTriggerPanel.module.css";
// Models
import { RuleDto } from "../../../../../models/incidents/RuleDto";
import { FormErrors } from "../../../../subsystemConfigurator/components/connectionPropertiesPanel/models/SubsystemConnectionDataTypes";
import { ResourceMappingDto } from "../../../../../models/resourceMapping/ResourceMappingDto";
// Utils
import { debounce } from "../../../../../utils/Util";

interface ResourceMappingOption extends OptionType {
  name: string;
}

interface BehaviourTriggerResourceMappingProps {
  ruleId: string;
  readOnlyMode: boolean;
  control: Control<RuleDto, any>;
  errors?: FormErrors<RuleDto>;
  resourceMappings: ResourceMappingDto[];
  loading: boolean;
  onInputChange: (searchText: string, pageIndex?: number) => void;
  onOpen?: () => void;
  onClose?: () => void;
  infiniteScroll?: {
    pageLength: number;
    hasMore: boolean;
    fetchNext: (pageIndex?: number) => void;
    isLoading: boolean;
    loadingComponent?: JSX.Element;
  };
}

const BehaviourTriggerResourceMapping = (
  props: BehaviourTriggerResourceMappingProps
): JSX.Element => {
  const {
    ruleId,
    readOnlyMode,
    control,
    resourceMappings,
    loading,
    errors,
    onInputChange,
    onOpen,
    onClose,
    infiniteScroll,
  } = props;
  const { t: translate } = useI18n();

  return (
    <div className="config-incidents__trigger-input__section">
      <span>
        {translate("behaviors.triggerFieldsDescription.resourceMappings")}
      </span>
      <div className="config-incidents__trigger-input__controller">
        <Controller
          name="trigger.triggerResourceMappingDTOs"
          control={control}
          render={({ field: { onChange, ...field } }) => {
            return (
              <AutoComplete<ResourceMappingOption>
                {...field}
                value={field.value as ResourceMappingOption[]}
                multiple
                dataCr="incident-trigger-source-type"
                getOptionLabel={(option) => option?.name || ""}
                options={resourceMappings as ResourceMappingOption[]}
                id={`trigger-source-type-autocomplete-${ruleId}`}
                disabled={readOnlyMode}
                loading={loading}
                placeholder={translate(
                  "behaviors.triggerFieldsDescription.resourceMappingsPlaceholder"
                )}
                onChangeCallback={(data, _e) => onChange(data)}
                {...({ onOpen } || {})}
                {...({ onClose } || {})}
                onInputChange={debounce(
                  (
                    _event: React.SyntheticEvent,
                    value: string,
                    reason: string
                  ) => {
                    if (reason !== "input") return;
                    onInputChange(value);
                  }
                )}
                infiniteScroll={infiniteScroll}
                error={!!errors?.trigger?.triggerResourceMappingDTOs}
                errorMessage={translate(
                  errors?.trigger?.triggerResourceMappingDTOs?.message ||
                    errors?.trigger?.message,
                  { label: translate("behaviors.fields.resourceMappings") }
                )}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

BehaviourTriggerResourceMapping.defaultProps = {
  errors: undefined,
  infiniteScroll: undefined,
  onOpen: undefined,
  onClose: undefined,
};

export default BehaviourTriggerResourceMapping;
