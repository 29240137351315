import React from "react";
import { OgBlockTypeDto } from "../../../../../models/ogTaskTypes/OgBlockTypeDto";

interface ConfirmationBlockTypeProps {
  block: OgBlockTypeDto;
}

const ConfirmationBlockType = (
  props: ConfirmationBlockTypeProps
): JSX.Element => {
  const { block } = props;
  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: "4px" }}
      id="confirmation-block-type"
      data-cr="confirmation-block-type"
    >
      <div className="task-widget-multi-options-horiz">
        <div className="compass-rounded-border task-widget-multi-options-single task-input-text-default">
          {block?.positiveAnswer?.text || "Yes"}
        </div>
        <div className="compass-rounded-border task-widget-multi-options-single task-input-text-default">
          {block?.negativeAnswer?.text || "No"}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationBlockType;
