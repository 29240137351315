import React from "react";
import "./searchBar.module.css";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { InputBase } from "@mui/material";

interface SearchBarProps {
  searchCallback: (searchInput: string) => void;
  dataCr?: string;
  placeholder?: string;
  value?: string;
}

const SearchBar = (props: SearchBarProps): JSX.Element => {
  const { searchCallback, dataCr, placeholder, value } = props;
  return (
    <div className="config-search-bar-main" data-cr={dataCr}>
      <InputBase
        sx={{ width: "100%", fontSize: "14px" }}
        placeholder={placeholder}
        {...(value !== undefined ? { value } : {})}
        data-cr={`${dataCr}-input`}
        onChange={(e) => searchCallback(e?.target?.value || "")}
      />
      <SearchRoundedIcon />
    </div>
  );
};

SearchBar.defaultProps = {
  dataCr: "search-bar",
  placeholder: "Search...",
  value: undefined,
};

export default SearchBar;
