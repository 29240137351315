// General
import React from "react";
import { useI18n } from "compass-commons";
// Style
import "../AddEditIncidentType.module.css";
// Components
import { Button } from "dms-lib";
// Models
import IncidentTypeDrawerState from "../../../../../models/incidents/IncidentTypeDrawerState";
import { IncidentTypeDto } from "../../../../../models/incidents/IncidentTypeDto";

interface AddEditIncidentTypeActionsProps {
  configDrawerState: (s: IncidentTypeDrawerState, i?: IncidentTypeDto) => void;
  onSave: () => void;
  isDisabled: boolean;
}

const AddEditIncidentTypeActions = ({
  configDrawerState,
  onSave,
  isDisabled,
}: AddEditIncidentTypeActionsProps): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <div
      className="config-add-edit-incident-type__panel__actions"
      data-cr="add-edit-incident-type-panel-actions"
    >
      <Button
        size="medium"
        color="primary"
        variant="text"
        onClick={() => configDrawerState(IncidentTypeDrawerState.List)}
      >
        <span>{translate("cancel", { ns: "Shared" })}</span>
      </Button>
      <Button
        size="medium"
        color="primary"
        variant="contained"
        type="submit"
        disabled={isDisabled}
        onClick={onSave}
      >
        <span>{translate("save", { ns: "Shared" })}</span>
      </Button>
    </div>
  );
};

export default AddEditIncidentTypeActions;
