// General
import { HttpClient } from "compass-shared-services";
import { getUrl } from "compass-commons";
// Models
import { SiteDto } from "../models/sites/SiteDto";
import { CreateSiteDto } from "../models/sites/CreateSiteDto";
import { SiteTreeNodeDto } from "../models/sites/SiteTreeNodeDto";
import { UpdateSiteDto } from "../models/sites/UpdateSiteDto";

const { SITE_MANAGER_PATH } = appConfig;
const URL_PATHS = {
  SM_ALL_SITES: `${SITE_MANAGER_PATH}/site-configuration/site-tree`,
  SM_CREATE_SITE: `${SITE_MANAGER_PATH}/site-configuration/sites`,
  SM_EDIT_DELETE_SITE: `${SITE_MANAGER_PATH}/site-configuration/sites/(siteId)`,
};

const httpClient = new HttpClient(appConfig);

export const searchNodeOnTree = (
  siteRoot: SiteTreeNodeDto,
  ancestors: SiteTreeNodeDto[]
): SiteTreeNodeDto => {
  const ancestorsCopy = [...ancestors];
  let siteDtoTraverse = siteRoot;
  // We only need to find starting on the first position
  // the first one it is always the root
  for (let index = 1; index < ancestorsCopy.length; index++) {
    siteDtoTraverse = siteDtoTraverse.childSites.find(
      (cs: SiteTreeNodeDto) => cs.id === ancestorsCopy[index].id
    );
  }
  return siteDtoTraverse;
};

export default class SitesService {
  static async getAllSites(): Promise<SiteTreeNodeDto> {
    const urlPath = `${URL_PATHS.SM_ALL_SITES}`;
    return httpClient
      .get<SiteTreeNodeDto>({ url: urlPath })
      .then((response) => response)
      .catch((error) => {
        throw Error(error?.data?.message || error);
      });
  }

  static async createSite(shortSiteDto: CreateSiteDto): Promise<SiteDto> {
    const urlPath = `${URL_PATHS.SM_CREATE_SITE}`;
    return httpClient
      .post<SiteDto>({ url: urlPath, payload: shortSiteDto })
      .then((response) => response)
      .catch(async (error) => {
        throw Error(error?.data?.errorMessage || error);
      });
  }

  static async updateSite(
    siteId: string,
    updateSiteDto: UpdateSiteDto
  ): Promise<SiteDto> {
    const opts = {
      // headers: {
      //   "Content-type": "application/xml; charset=utf-16",
      //   Accept: "application/xml; charset=utf-16",
      // },
    };
    const urlPath = getUrl(URL_PATHS.SM_EDIT_DELETE_SITE, { siteId });
    return httpClient
      .put<SiteDto>({ url: urlPath, payload: updateSiteDto, config: opts })
      .then((response) => response)
      .catch(async (error) => {
        throw Error(error?.data?.errorMessage || error);
      });
  }

  static async deleteSite(siteId: string): Promise<SiteDto> {
    const urlPath = getUrl(URL_PATHS.SM_EDIT_DELETE_SITE, { siteId });
    return httpClient
      .delete<SiteDto>({ url: urlPath })
      .then((response) => response)
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
